import React, { useEffect, useState } from "react";
import { headers } from "../../token";
import config from '../../config';
import Swal from "sweetalert2";
import axios from "axios";

const EditStage = ({ stageOBJ, handleCloseEditStage, showCategory, stageByCategory }) => {
    const server_backend = config.API_URL;
    const [name, setName] = useState(stageOBJ.title);
    const [category, setCategory] = useState(stageOBJ.category_id);
    const [description, setDescription] = useState(stageOBJ.description);
    const [loading, setLoading] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);

    useEffect(() => {
        const getUsers = async () => {
            try {
                const response = await axios.post(`${server_backend}/get-users-assigned`, {
                    TaskID: stageOBJ.id
                }, headers);

                if (response.data.UserAsiggned && response.data.UserNoAsiggned) {
                    const usersWithAssignment = response.data.UserAsiggned.map(user => ({ ...user, assigned: true }));
                    const usersWithoutAssignment = response.data.UserNoAsiggned.map(user => ({ ...user, assigned: false }));
                    setAllUsers([...usersWithAssignment, ...usersWithoutAssignment]);
                } else {
                    console.error("Error: User data not received from the server.");
                }
            } catch (error) {
                console.error(error);
            }
        }
        getUsers();
    }, [stageOBJ.id]);

    const handleUserSelection = (userId) => {
        const updatedUsers = allUsers.map(user =>
            user.id === userId ? { ...user, assigned: !user.assigned } : user
        );
        setSelectedUsers(updatedUsers.filter(user => user.assigned));
        setAllUsers(updatedUsers);
    };

    const UpdateStage = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(`${server_backend}/update-stage-data`, {
                taskID: stageOBJ.id,
                TaskName: name,
                category: category,
                selectedUsers: allUsers.filter(user => user.assigned).map(user => user.id)
            }, headers);

            if (response.data === 100) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: "the stage has been updated",
                    showConfirmButton: false,
                    timer: 1000
                }).then(() => {
                        handleCloseEditStage();
                        stageByCategory(stageOBJ.category_id);
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: "An unexpected error has occurred, if it persists contact your systems administrator",
                    showConfirmButton: false,
                    timer: 2000
                });
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className="modal fade bs-example-modal-sm show azoomOutDown" tabIndex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel3" aria-modal="true" style={{ display: "block", backgroundColor: "#2e659385" }}>
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content border-0">
                    <div className="modal-header p-3 bg-soft-info">
                        <h5 className="modal-title" id="exampleModalLabel">Update Stage</h5>
                        <button type="button" className="btn-close" onClick={handleCloseEditStage}></button>
                    </div>
                    <form>
                        <div className="modal-body">
                            <input type="hidden" id="tasksId" />
                            <div className="row g-3">
                                <div className="col-lg-12">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <label htmlFor="projectName-field" className="form-label">Stage Name</label>
                                            <input
                                                type="text"
                                                id="projectName-field"
                                                className="form-control"
                                                placeholder="Stage name"
                                                name="name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                required
                                            />
                                        </div>
                                        <div className="col-lg-6">
                                            <label htmlFor="projectName-field" className="form-label">Category</label>
                                            <select
                                                className="form-control"
                                                data-choices
                                                data-choices-search-false
                                                id="ticket-status"
                                                value={category}
                                                onChange={(e) => setCategory(e.target.value)}
                                            >
                                                <option>Select a category if applicable</option>
                                                {showCategory.map((categoryData_R) => (
                                                    <option value={categoryData_R.id}>{categoryData_R.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <label htmlFor="tasksTitle-field" className="form-label">Description</label>
                                    <input
                                        type="text"
                                        id="tasksTitle-field"
                                        className="form-control"
                                        placeholder="Description"
                                        name="description"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="col-lg-12">
                                    <label className="form-label">Assigned To</label>
                                    <div data-simplebar style={{ height: '95px' }}>
                                        <ul className="list-unstyled vstack gap-2 mb-0">
                                            {allUsers.map((user) => (
                                                <li key={user.id}>
                                                    <div className="form-check d-flex align-items-center">
                                                        <input
                                                            className="form-check-input me-3"
                                                            type="checkbox"
                                                            checked={user.assigned}
                                                            onChange={() => handleUserSelection(user.id)}
                                                            id={user.UserName} />
                                                        <label className="form-check-label d-flex align-items-center" for={user.UserName}>
                                                            <span className="flex-shrink-0">
                                                                <img src="assets/images/users/avatar-1.jpg" alt="" className="avatar-xxs rounded-circle" />
                                                            </span>
                                                            <span className="flex-grow-1 ms-2">{user.UserName}</span>
                                                        </label>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="hstack gap-2 justify-content-end">
                                <button type="button" className="btn btn-light" onClick={handleCloseEditStage}>
                                    Close
                                </button>
                                <button type="submit" className="btn btn-success" onClick={UpdateStage} id="add-btn">
                                    Add Stage
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default EditStage;


