import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import { headers } from "../../token";
import 'filepond/dist/filepond.min.css';
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";


const ContainerW = ({ stageID, containerNum, containerName, username, handleDragStart, convertSize, chageFileFace, FileIcon, deleteFileAndFolder, handleCopyAndPaste, updateCurrentFolderAndRoute, updateProgressToTransf }) => {
    const server_backend = config.API_URL;
    const [containersFile, setcontainersFile] = useState([]);
    const [containersFolder, setcontainersFolder] = useState([]);
    const [files, setFiles] = useState([]);
    const [draggingFromPC, setDraggingFromPC] = useState(true);
    const [transferFile, setTransferFile] = useState(false);
    const [isDragginContainer, setIsDragginContainer] = useState(false);
    const [currentFileTo, setCurrentFileTo] = useState(null);
    const [loading, setLoading] = useState(false); // New state to control loading
    const [parentfolder, setParentfolder] = useState([]);
    const [isGlobalFilesEnabled, setIsGlobalFilesEnabled] = useState(true);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        const GetFiles = async () => {
            try {
                const response = await axios.post(
                    `${server_backend}/get-files-container`,
                    {
                        stageID: stageID,
                        containerNum: containerNum,
                    },
                    headers
                );
                setcontainersFile(response.data.files);
                setcontainersFolder(response.data.folder)
            } catch (error) {
                console.log(error);
            }
        };

        GetFiles();
    }, [0]);

    useEffect(() => {
        const handleCurrentFileToUpdated = (event) => {
            setCurrentFileTo(event.detail);
        };

        window.addEventListener('currentFileToUpdated', handleCurrentFileToUpdated);

        return () => {
            window.removeEventListener('currentFileToUpdated', handleCurrentFileToUpdated);
        };
    }, [0]);

    const GlobalFiles = async () => {
        try {
            const response = await axios.post(
                `${server_backend}/get-files-container`,
                {
                    stageID: stageID,
                    containerNum: containerNum,
                },
                headers
            );
            setcontainersFile(response.data.files);
            setcontainersFolder(response.data.folder)
        } catch (error) {
            console.log(error);
        }
    };

    const handleFileChange = (event) => {
        const selectedFilesArray = Array.from(event.target.files);
        setFiles(selectedFilesArray);
    };


    const containerInsertFile = async () => {
        setLoading(true); // Mark as loading at start of load

        const formData = new FormData();
        formData.append("stageID", stageID);
        formData.append("containerNum", containerNum);
        formData.append("stageName", containerName);
        formData.append("username", username);

        for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
        }

        try {
            const response = await axios.post(
                `${server_backend}/insert-files-container`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        ...headers,
                    },
                }
            );

            if (response.data.StatusCode === 100) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: response.data.StatusMessage,
                    timer: 1000,
                    showConfirmButton: false,
                }).then(() => {
                    setIsDragginContainer(false);
                    setLoading(false); // Mark as not loading after success
                });
                GlobalFiles();
            }
        } catch (error) {
            console.error("Error uploading files:", error);
            setLoading(false); // Mark as not loading on error
        }
    };

    const handleDragOver = (event) => {
        if (!transferFile && draggingFromPC) {
            setIsDragginContainer(true);
        }

        event.preventDefault();
        event.stopPropagation();
    };


    const handleDragLeave = (event) => {
        event.preventDefault();
        const rect = event.currentTarget.getBoundingClientRect();
        const x = event.clientX;
        const y = event.clientY;

        if (x < rect.left || x >= rect.right || y < rect.top || y >= rect.bottom) {
            setDraggingFromPC(false);
        }
    };

    const defineNumContainer = (NumContainer) => {

        if (NumContainer === 1) {
            return 5
        } else if (NumContainer === 2) {
            return 6
        } else if (NumContainer === 3) {
            return 7
        } else if (NumContainer === 4) {
            return 8
        } else if (NumContainer === 5) {
            return 9
        } else if (NumContainer === 6) {
            return 10
        } else if (NumContainer === 7) {
            return 11
        } else if (NumContainer === 8) {
            return 12
        }

    }

    // Listen to the custom event
    const handleCustomEvent = () => {
        if (isGlobalFilesEnabled) {
            GlobalFiles();
            setIsGlobalFilesEnabled(false);
            setTimeout(() => {
                setIsGlobalFilesEnabled(true);
            }, 2000);
            setSelectedFiles([]);
        }
    };

    useEffect(() => {

        window.addEventListener('changeFileFaceComplete', handleCustomEvent);


        return () => {
            window.removeEventListener('changeFileFaceComplete', handleCustomEvent);
        };
    }, []);

    window.addEventListener('fileToFace', () => {
        setTransferFile(true);
    });

    const showFilesInFolder = async (folderID, faceSource, isGoingBack = false) => {
        setLoading(true)
        try {

            const response = await axios.post(`${server_backend}/show-files-in-folder`, {
                folderID: folderID,
                facesource: faceSource,
                taskID: stageID
            }, headers);

            setcontainersFile(response.data.result.files);
            //console.log(response.data.result.folder);
            if (folderID === 4) {
                setcontainersFolder(response.data.result.folder);

                setParentfolder([]);
            } else {
                setcontainersFolder(response.data.result.folder.slice(1));
                setParentfolder(response.data.result.folder[0]);
                updateCurrentFolderAndRoute(faceSource, response.data.result.folder[0], isGoingBack);
            }

            //setcontainersFile(response.data.result.files);
            //setcontainersFolder(response.data.result.folder); 

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    };

    const handleCheckboxChange = (item, isChecked) => {
        setSelectedFiles(prevItems => {
            const itemIndex = prevItems.findIndex(i => i.id === item.id);
            if (isChecked && itemIndex === -1) {
                return [...prevItems, item];
            } else if (!isChecked && itemIndex !== -1) {
                const updatedItems = [...prevItems];
                updatedItems.splice(itemIndex, 1);
                return updatedItems;
            }
            return prevItems;
        });
    };


    const handleRowClick = (e, item) => {
        if (e.target.type !== 'checkbox') {
            const checkbox = document.getElementById(`checkbox-file-${item.id}`);
            if (checkbox) {
                checkbox.checked = !checkbox.checked;
                handleCheckboxChange(item, checkbox.checked);
            }
        }
    };

    const handleFiles = (event) => {
        event.stopPropagation();
        event.preventDefault();

        const newFiles = [];

        const readFileEntries = (entry) => {
            return new Promise((resolve, reject) => {
                if (entry.isFile) {
                    entry.file(file => {
                        newFiles.push(file);
                        resolve();
                    });
                } else if (entry.isDirectory) {
                    const reader = entry.createReader();
                    reader.readEntries(entries => {
                        const promises = entries.map(readFileEntries);
                        Promise.all(promises).then(resolve).catch(reject);
                    });
                }
            });
        };

        const handleInputFiles = (fileList) => {
            for (const file of fileList) {
                newFiles.push(file);
            }
        };

        if (event.target && event.target.files) {
            handleInputFiles(event.target.files);
        } else if (event.dataTransfer && event.dataTransfer.items) {
            for (const item of event.dataTransfer.items) {
                const entry = item.webkitGetAsEntry();
                if (entry) {
                    readFileEntries(entry);
                }
            }
        }

        // Delay the state update to ensure all files are processed
        setTimeout(() => {
            setFiles(prevFiles => [...prevFiles, ...newFiles]);
        }, 1000);
    };


    const handleSelectAllChange = (e) => {
        const isChecked = e.target.checked;
        setSelectAll(isChecked);
        const allFiles = isChecked ? containersFile.map((item) => item) : [];
        setSelectedFiles(allFiles);
    };

    useEffect(() => {
        containersFile.forEach((item) => {
            const checkbox = document.getElementById(`checkbox-file-${item.id}`);
            if (checkbox) {
                checkbox.checked = selectAll;
            }
        });
    }, [selectAll, containersFile]);

    const changeDirectory = async (event, fileObjs, folderObj, FaceNumber) => {
        //console.log(folderObj)
        event.preventDefault();
        event.stopPropagation();
        //setLoadingFace(true)
        const faceSource = currentFileTo.facesource;
        try {
            const totalFiles = fileObjs.length; // Total number of files to process
            let filesProcessed = 0; // Counter for processed files
            console.log(fileObjs)
            for (const fileObj of fileObjs) {
                const folderToFolder = fileObj.folder_name;

                if (folderToFolder === undefined) {
                    try {
                        const response = await axios.post(`${server_backend}/change-directory-file`, {
                            fileObj,
                            folderObj: folderObj,
                            FaceNumber: FaceNumber,
                            faceSource: faceSource,
                            TaskID: stageID,
                            stageName: stageID,
                        }, headers);

                        if (response.data.statusCode === 100) {
                            //showFiles(inStage.TaskID);
                            //const folderID = fileObj.folder_id;
                            //showFilesInFolder(folderID, FaceNumber);
                        }

                    } catch (error) {
                        console.log(error);
                    } finally {
                        // Update progress
                        filesProcessed++;
                        const percentCompleted = Math.round((filesProcessed / totalFiles) * 100);
                        updateProgressToTransf(percentCompleted);
                    }

                } else {
                    try {
                        const foldersource = fileObj.id;
                        const folderdestination = folderObj.id;
                        const task_idP = folderObj.task_id;

                        if (foldersource !== folderdestination) { // to avoid folder conflict
                            const response = await axios.post(`${server_backend}/move-folder-with-files`, {
                                fileObj,
                                folderObj: folderObj,
                                FaceNumber: FaceNumber,
                                faceSource: faceSource,
                                taskID: stageID ? stageID : task_idP,
                            }, headers);

                            if (response.data.statusCode == 100) {
                                //console.log("HelloWold")
                            }
                        }

                    } catch (error) {
                        console.log(error);
                    } finally {
                        // Update progress
                        filesProcessed++;
                        const percentCompleted = Math.round((filesProcessed / totalFiles) * 100);
                        updateProgressToTransf(percentCompleted);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setSelectedFiles([]);
            GlobalFiles();
            // Ensure the progress reaches 100% at the end
            updateProgressToTransf(100);
        }


    }

    return (
        <div>
            <head>
                <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
                <link
                    href="./assets/libs/jsvectormap/css/jsvectormap.min.css"
                    rel="stylesheet"
                    type="text/css"
                />
                <link
                    href="./assets/libs/swiper/swiper-bundle.min.css"
                    rel="stylesheet"
                    type="text/css"
                />
                <script src="./assets/js/layout.js"></script>
                <link
                    href="./assets/css/bootstrap.min.css"
                    id="bootstrap-style"
                    rel="stylesheet"
                    type="text/css"
                />
                <link href="./assets/css/icons.min.css" rel="stylesheet" type="text/css" />
                <link href="./assets/css/filepond.min.css" rel="stylesheet" type="text/css" />
                <link href="./assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
                <link href="./assets/css/custom.min.css" id="app-style" rel="stylesheet" type="text/css" />
                <link href="./assets/css/extra-css.css" id="app-style" rel="stylesheet" type="text/css" />
            </head>
            <div className="page-content" style={{ height: "85vh", overflow: "auto" }} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={transferFile === true ? (event) => chageFileFace(event, currentFileTo, defineNumContainer(containerNum)) : undefined}>
                <div className="container-fluid">
                    <center>
                        <h1>{containerName}</h1>
                    </center>
                    <center>
                        <h5>BENCH NUM: {containerNum}</h5>
                    </center>
                    <button className="btn btn-primary" onClick={handleDragOver}>
                        Upload File
                    </button> | {selectedFiles.length > 0 && (
                        <button className="btn btn-primary" onClick={() => {
                            handleCopyAndPaste(selectedFiles, defineNumContainer(containerNum));
                            //hideMenu();
                        }}>
                            <i className="ri-delete-bin-5-line"></i> Copy selected files
                        </button>
                    )} |
                    {selectedFiles.length > 0 && (
                        <button className="btn btn-danger" onClick={(event) => {
                            event.preventDefault();
                            deleteFileAndFolder(selectedFiles, defineNumContainer(containerNum), showFilesInFolder, username);

                        }}>
                            <i className="ri-delete-bin-5-line"></i> Delete selected files
                        </button>
                    )}


                    {!isDragginContainer ? (
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="checkAll"
                                                checked={selectAll}
                                                onChange={handleSelectAllChange}
                                            />
                                            <label htmlFor="checkAll" className="form-check-label"></label>
                                        </div>
                                    </th>
                                    <th scope="col"></th>
                                    <th scope="col">File Name</th>
                                    <th scope="col">Size</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {parentfolder && parentfolder.folder_name && (
                                    <tr
                                        onDoubleClick={() => {
                                            showFilesInFolder(parentfolder.parent_folder_id, defineNumContainer(containerNum), true)
                                        }}>
                                        <td>
                                            <div className="flex-shrink-0 ">
                                                <div>
                                                    <div className="rounded fs-36">
                                                        <FileIcon fileName={parentfolder.folder_name} />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>[..]</td>
                                    </tr>
                                )}

                                {containersFolder.map((folder, index) => (
                                    <tr key={index} draggable
                                        onDragStart={(e) => {
                                            setTransferFile(true);
                                            setDraggingFromPC(false);
                                            handleDragStart(e, selectedFiles.length === 0 ? [folder] : selectedFiles, defineNumContainer(containerNum));
                                        }}
                                        onDragEnd={() => {
                                            setDraggingFromPC(true);
                                            setTransferFile(false);
                                            GlobalFiles();
                                        }}

                                        onDoubleClick={() => {
                                            showFilesInFolder(folder.id, defineNumContainer(containerNum))
                                        }}

                                        onDrop={(event) => changeDirectory(event, selectedFiles, folder, defineNumContainer(containerNum))}
                                    >
                                        <td>
                                            <div className="form-check">
                                                <input
                                                    id={`checkbox-folder-${folder.id}`}
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="chk_folder"
                                                    onChange={(e) => handleCheckboxChange(folder, e.target.checked)}
                                                />
                                                <label htmlFor={`checkbox-folder-${folder.id}`} className="form-check-label"></label>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex-shrink-0">
                                                <div>
                                                    <div className="rounded fs-36">
                                                        <FileIcon fileName={folder.folder_name} />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="Zbutton2">{folder.folder_name}</td>
                                        <td>{convertSize(folder.folder_size ? folder.folder_size : 0)}</td>
                                        <td>{folder.created_at}</td>
                                        <td>
                                            <button className="btn btn-primary" type="button">
                                                <i className="ri-download-2-line"></i>
                                            </button>

                                            {/*  <button button type="button" className="btn btn-danger btn-icon waves-effect waves-light"
                                                onClick={() => {

                                                    deleteFileAndFolder(folder, defineNumContainer(containerNum), showFiles(folder.task_id), showFilesInFolder, username)
                                                }
                                                }>
                                                <i className="ri-delete-bin-5-line"></i>
                                            </button> */}
                                        </td>
                                    </tr>
                                ))}

                                {containersFile.map((file, index) => (
                                    <tr
                                        key={index}
                                        draggable
                                        onDragStart={(e) => {
                                            setTransferFile(true);
                                            setDraggingFromPC(false);
                                            handleDragStart(e, selectedFiles.length === 0 ? [file] : selectedFiles, defineNumContainer(containerNum));
                                            if (selectedFiles.length === 0) {
                                                const checkbox = document.getElementById(`checkbox-file-${file.id}`);
                                                if (checkbox) {
                                                    checkbox.checked = true;
                                                    handleCheckboxChange(file, true);
                                                }
                                            }
                                        }}
                                        onDragEnd={() => {
                                            setDraggingFromPC(true);
                                            setTransferFile(false);
                                            GlobalFiles();
                                        }}
                                        onClick={(e) => handleRowClick(e, file)}
                                    >
                                        <td>
                                            <div className="form-check">
                                                <input
                                                    id={`checkbox-file-${file.id}`}
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    name="chk_file"
                                                    checked={selectedFiles.includes(file)}
                                                    onChange={(e) => handleCheckboxChange(file, e.target.checked)}
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex-shrink-0">
                                                <div>
                                                    <div className="rounded fs-36">
                                                        <FileIcon fileName={file.file_name} />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="Zbutton2">{file.file_name}</td>
                                        <td>{convertSize(file.size)}</td>
                                        <td>{file.created_at}</td>
                                        <td>
                                            <button
                                                onClick={() => {
                                                    const name = file.file_name;
                                                    const name1 = name.replace(/\s/g, '');
                                                    saveAs(`${server_backend}/${file.file_path}`, name1);
                                                }}
                                                className="btn btn-primary"
                                                type="button"
                                            >
                                                <i className="ri-download-2-line"></i>
                                            </button>
                                            {/* Uncomment if needed */}
                                            {/* <button type="button" className="btn btn-danger btn-icon waves-effect waves-light" onClick={() => {
                                    deleteFileAndFolder(file, defineNumContainer(containerNum), showFiles(file.task_id), showFilesInFolder, username);
                                }}>
                                    <i className="ri-delete-bin-5-line"></i>
                                </button> */}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                    ) : (
                        <div className="card">
                            <div className="card-body">
                                <div
                                    style={{ maxHeight: "100%", minHeight: "100%", borderRadius: "10px" }}
                                    onDragOver={handleDragOver}
                                >
                                    <h5>Drag the file here</h5>
                                    <input
                                        className="form-control"
                                        type="file"
                                        multiple
                                        webkitdirectory="true"
                                        directory="true"
                                        onChange={handleFiles}
                                        style={{ height: "200px" }}
                                    />
                                    <center>
                                        <button className="btn btn-danger" onClick={() => setIsDragginContainer(false)}>
                                            Close
                                        </button>{" "}|
                                        {loading ? (
                                            <button className="btn btn-primary w-50">
                                                Loading...
                                            </button>
                                        ) : (
                                            <button className="btn btn-success w-50" onClick={containerInsertFile}>
                                                Upload files
                                            </button>
                                        )}
                                    </center>
                                    <div>
                                        <h6>Selected Files:</h6>
                                        <ul>
                                            {files.map((file, index) => (
                                                <li key={index}>{file.name}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div >

    );
};

export default ContainerW;
