import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Sidebar2({ logout, username, userRole }) {
    const user2 = JSON.parse(username);
    const [select, setSelect] = useState("");
    const [isSidebarHidden, setIsSidebarHidden] = useState(localStorage.getItem('sidebarHidden') === 'true');

    function changeTheme() {
        const htmlElement = document.querySelector('html');
        if (htmlElement.getAttribute('data-layout-mode') === 'light') {
            htmlElement.setAttribute('data-layout-mode', 'dark');
            localStorage.setItem('DarkMode', "dark");
        } else {
            htmlElement.setAttribute('data-layout-mode', 'light');
            localStorage.setItem('DarkMode', "light");
        }
    }
    const toggleSidebar = () => {
        const isSidebarHidden = localStorage.getItem('sidebarHidden') === 'true';
        const newSidebarHiddenState = !isSidebarHidden;
        setIsSidebarHidden(newSidebarHiddenState)
        localStorage.setItem('sidebarHidden', newSidebarHiddenState);
        const event = new CustomEvent('sidebarVisibilityChanged', { detail: newSidebarHiddenState });
        window.dispatchEvent(event);
      };

    return (
        <div className="app-menu navbar-menu" style={{ marginTop: "0px", backgroundColor: "#435590" }}>
            <div className='col-md-12'>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className='text-center' style={{ marginTop: "1em" }}>
                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <Link to="/main-panel" className="d-inline-block auth-logo">
                                            <img src="assets/images/increqimg.png" alt="increqimg" width="130" />
                                        </Link>
                                    </div>
                                    <div className='col-6'>
                                        <button type="button" className="btn btn-primary" id="topnav-hamburger-icon" style={{ marginRight: "43%" }} onClick={toggleSidebar}>
                                            <span className={isSidebarHidden ? "hamburger-icon open" : "hamburger-icon"}>
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-7'>
                        <div id="scrollbar">
                            <div>
                                <div id="two-column-menu"></div>
                                <ul className="navbar-nav" id="navbar-nav">
                                    <div className="nav-item dropdown ms-sm-3 header-item topbar-user">
                                        <Link
                                            className={select === "/main-panel" ? "nav-link menu-link Zbutton selectsidebar" : "nav-link menu-link Zbutton"}
                                            to="/main-panel"
                                            style={{ color: "white" }}
                                            onClick={() => setSelect("/main-panel")}>
                                            <i className="ri-home-4-line"></i> <span data-key="t-layouts">Main</span>
                                        </Link>
                                    </div>

                                    {userRole === 1 ? (
                                        <>
                                            <div className="nav-item dropdown ms-sm-3 header-item topbar-user">
                                                <Link
                                                    className={select === "/task-list" ? "nav-link menu-link Zbutton selectsidebar" : "nav-link menu-link Zbutton"}
                                                    to="/task-list"
                                                    style={{ color: "white" }}
                                                    onClick={() => setSelect("/task-list")} >
                                                    <i class="ri-newspaper-line"></i><span data-key="t-apps">Stage</span>
                                                </Link>
                                            </div>

                                            <div className="nav-item dropdown ms-sm-3 header-item topbar-user">
                                                <Link
                                                    className={select === "/show-category" ? "nav-link menu-link Zbutton selectsidebar" : "nav-link menu-link Zbutton"}
                                                    to="/show-category"
                                                    style={{ color: "white" }}
                                                    onClick={() => setSelect("/show-category")}>
                                                    <i class="ri-group-2-fill"></i> <span data-key="t-layouts">Platform</span>
                                                </Link>
                                            </div>

                                            <div className="nav-item dropdown ms-sm-3 header-item topbar-user">
                                                <Link
                                                    className={select === "users" ? "nav-link menu-link Zbutton selectsidebar" : "nav-link menu-link Zbutton"}
                                                    to="/users"
                                                    style={{ color: "white" }}
                                                    onClick={() => setSelect("/users")}  >
                                                    <i class="ri-user-2-fill"></i> <span data-key="t-layouts">Users</span>
                                                </Link>
                                            </div>
                                        </>
                                    ) : null}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-2'>
                        <div className="dropdown ms-sm-3 header-item topbar-user">
                            <button type="button" className="btn shadow-none" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span className="d-flex align-items-center">
                                    <img className="rounded-circle header-profile-user" src="assets/images/users/user-dummy-img.jpg" alt="Header Avatar" />
                                    <span className="text-start ms-xl-2">
                                        <h6 className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{user2}</h6>
                                        <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{userRole === 1 ? "Admin" : "Agent"}</span>
                                    </span>
                                </span>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                                <h6 className="dropdown-header">Welcome {user2}!</h6>
                                <button className="dropdown-item " onClick={changeTheme}><i className='bx bx-moon fs-22'></i> <span className="align-middle" data-key="t-logout">Dark Mode</span></button>
                                <a className="dropdown-item" href="pages-profile.html"><i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i> <span className="align-middle">Profile</span></a>
                                <button className="dropdown-item" onClick={() => {
                                    logout()
                                }}><i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span className="align-middle" data-key="t-logout">Logout</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar2;
