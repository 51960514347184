import axios from "axios";
import React, { useEffect, useState } from "react";
import config from '../../config'
import { headers } from '../../token'
import { Link } from "react-router-dom";
import EditUser from "./UpdateUser";
import DeleteUser from "./DeleteUser";

const UserModule = () => {
    const server_backend = config.API_URL;
    const [user, setUser] = useState([]);
    const [showModal, setShowModal] = useState('');
    const [iduser, setIduser] = useState(0);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    useEffect(() => {

        const FetchData = async () => {

            try {
                const response = await axios.post(`${server_backend}/show-all-user`, {}, headers);
                setUser(response.data);

            } catch (error) {
                console.log(error);
            }
        }
        FetchData()
    }, [0])

    
    const showUsers = async () => {

        try {
            const response = await axios.post(`${server_backend}/show-all-user`, {}, headers);
            setUser(response.data);

        } catch (error) {
            console.log(error);
        }
    }



    return (
        <div className="page-content" data-simplebar-track="secondary" data-simplebar="init" style={{ height: "85vh", overflow: "auto" }}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <h4 className="card-title mb-0">Add, Edit & Remove</h4>
                            </div>
                            <div className="card-body">
                                <div id="customerList">
                                    <div className="row g-4 mb-3">
                                        <div className="col-sm-auto">
                                            <div>
                                                <Link to="/add-users" type="button" className="btn btn-info add-btn">
                                                    <i className="ri-add-line align-bottom me-1"></i> Add User
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="col-sm">
                                            <div className="d-flex justify-content-sm-end">
                                                <div className="search-box ms-2">
                                                    <input type="text" className="form-control search" placeholder="Search..." />
                                                    <i className="ri-search-line search-icon"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="table-responsive table-card mt-3 mb-1">
                                        <table className="table align-middle table-nowrap" id="customerTable">
                                            <thead className="table-light">
                                                <tr>
                                                    <th scope="col" style={{ width: '50px' }}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                        </div>
                                                    </th>
                                                    <th className="sort" data-sort="customer_name">
                                                        Username
                                                    </th>
                                                    <th className="sort" data-sort="date">
                                                        Name
                                                    </th>
                                                    <th className="sort" data-sort="status">
                                                        Role
                                                    </th>
                                                    <th className="sort" data-sort="action">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                                {user.map(userD => (
                                                    <tr>
                                                        <th scope="row">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                                                            </div>
                                                        </th>
                                                        <td className="id" style={{ display: 'none' }}>
                                                            <a href="javascript:void(0);" className="fw-medium link-primary">
                                                                #VZ2101
                                                            </a>
                                                        </td>
                                                        <td className="customer_name">{userD.username}</td>
                                                        <td className="email">{userD.name}</td>
                                                        <td className="status">
                                                            {userD.Role_name === "Admin" ? (
                                                                <span className="badge badge-soft-success text-uppercase">{userD.Role_name}</span>
                                                            ) : (

                                                                <span className="badge badge-soft-warning text-uppercase">{userD.Role_name}</span>
                                                            )}
                                                        </td>
                                                        <td>
                                                            <div className="d-flex gap-2">
                                                                <div className="edit">
                                                                    <a type="button" className="link-success fs-15" onClick={() => {
                                                                        handleOpenModal();
                                                                        setIduser(userD.id)
                                                                    }}>
                                                                        <i class="ri-edit-2-line"></i>
                                                                    </a>
                                                                </div>
                                                                <div className="remove">
                                                                    <Link className="link-danger fs-15" onClick={() => {
                                                                        DeleteUser(userD.id, setUser)
                                                                    }}>
                                                                        <i class="ri-delete-bin-line"></i>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                        <div className="noresult" style={{ display: 'none' }}>
                                            <div className="text-center">
                                                <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#121331,secondary:#08a88a" style={{ width: '75px', height: '75px' }}></lord-icon>
                                                <h5 className="mt-2">Sorry! No Result Found</h5>
                                                <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any orders for your search.</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <div className="pagination-wrap hstack gap-2">
                                            <a className="page-item pagination-prev disabled" href="#">
                                                Previous
                                            </a>
                                            <ul className="pagination listjs-pagination mb-0">
                                                <li className="active">
                                                    <a className="page" href="#" data-i="1" data-page="8">
                                                        1
                                                    </a>
                                                </li>
                                            </ul>
                                            <a className="page-item pagination-next" href="#">
                                                Next
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <EditUser onClose={handleCloseModal} iduser={iduser} setUser={setUser} showUsers={showUsers} />
            )}
        </div>
    )
}

export default UserModule;