import React, { useState, useRef, useEffect } from "react";
import { headers } from "../../token";
import config from '../../config';
import Swal from "sweetalert2";
import axios from "axios";

const EditFile = ({ FileObject, closeModalFile, stageType, showFilesInFolder, setGFilesBranch4 }) => {
    const server_backend = config.API_URL;
    const [name, setName] = useState(FileObject.file_name);
    const [loading, setLoading] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        //Automatically select the text before the dot when the page loads
        const dotIndex = name.lastIndexOf(".");
        inputRef.current.setSelectionRange(0, dotIndex);
        inputRef.current.focus();
    }, [1]);

    const UpdateFilename = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(`${server_backend}/update-file-name`, {
                idfile: FileObject.id,
                taskID: FileObject.task_id,
                filename: name,
                stageType: stageType
            }, headers);

            if (response.data === 100) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: response.data.StatusMessage,
                    showConfirmButton: false,
                    timer: 1000
                }).then(() => {
                    showFilesInFolder(FileObject.folder_id, stageType);
                    setGFilesBranch4((prevData) => {
                        const newData = [...prevData];
                        const updatedData = newData.map((dataItem) => {
                            if (dataItem.id === FileObject.id) {
                                return { ...dataItem, file_name: name };
                            }
                            return dataItem;
                        });
                        return updatedData;
                    });
                    closeModalFile()
                });

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.data.StatusMessage,
                    showConfirmButton: false,
                    timer: 1000
                });
            }

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="modal fade bs-example-modal-xl show azoomOutDown" tabIndex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel2" aria-modal="true" style={{ display: "block", backgroundColor: "#2e659385" }}>
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header bg-soft-info p-3">
                        <h5 className="modal-title" id="exampleModalLabel"></h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" onClick={closeModalFile}></button>
                    </div>
                    <div className="modal-body">
                        <div className="tab-pane fade show active" id="pills-bill-info" role="tabpanel" aria-labelledby="pills-bill-info-tab">
                            <form>
                                <div>
                                    <p className="text-muted mb-4">Please enter the new name of the file you want to add</p>
                                </div>
                                <div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="text-center">
                                                <div className="position-relative d-inline-block">
                                                    <div className="avatar-lg p-1">
                                                        <div className="avatar-title bg-light rounded-circle">
                                                            <i className="ri-quill-pen-fill"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <h5 className="fs-13 mt-3">Rename file</h5>
                                            </div>
                                            <div className="mb-3">
                                                <label htmlFor="username" className="form-label">
                                                    Name
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="username"
                                                    name="username"
                                                    placeholder={`Enter Name (e.g., ${FileObject.file_extension})`}
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                    ref={inputRef}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger fw-medium" onClick={closeModalFile}><i className="ri-close-line me-1 align-middle"></i> Close</button>
                        <button type="button" className="btn btn-primary" onClick={UpdateFilename} disabled={loading}>
                            {loading ? 'Saving...' : 'Save changes'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditFile;
