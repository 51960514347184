//Server node js configuration

/* http://localhost:4000 */
/* https://depoly-work-file.onrender.com */

const production = 'https://serverbk1.azurewebsites.net';
const dev = 'http://localhost:4000';

module.exports = {

    API_URL: production
    
}