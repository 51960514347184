import { FilePond, registerPlugin } from "react-filepond";
import 'filepond/dist/filepond.min.css';
import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import { headers } from "../../token";
import Swal from "sweetalert2";
import { saveAs } from 'file-saver';
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ContextMenu, ContextMenuTrigger, hideMenu } from 'react-contextmenu';
import ReactDOM from "react-dom";
import { Flat, Heat, Nested } from '@alptugidin/react-circular-progress-bar'
import {
    FaFile, FaFileArchive, FaFileImage, FaFolder, FaFilePdf, FaFileWord
} from 'react-icons/fa';


import EditFile from "./EditFile";
import ContainerW from "../containers/Containers";
import DeleteFile from "../trash/DeletedFile";
import EditFoldername from "./UpdateFolder";

import JSZip from 'jszip';
import { useFolderRoutes } from "../../BaseRoute";


const MainPanel = ({ username, userRole }) => {
    const server_backend = config.API_URL;
    const user2 = JSON.parse(username);
    const [files, setFiles] = useState([]);
    const [categories, setCategories] = useState([])
    const [name, setName] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [loading, setLoading] = useState(false);
    const [loadingface, setLoadingFace] = useState(false);
    const [stage, setStage] = useState([]);
    const [colorstatus, setColorstatus] = useState("");
    const [file_1, setFile_1] = useState([]);
    const [file_2, setFile_2] = useState([]);
    const [file_3, setFile_3] = useState([]);
    const [file_4, setFile_4] = useState([]);
    const [folder_1, setfolder_1] = useState([]);
    const [folder_2, setfolder_2] = useState([]);
    const [folder_3, setfolder_3] = useState([]);
    const [folder_4, setfolder_4] = useState([]);
    const [parentfolder1, setParentfolder1] = useState([]);
    const [parentfolder2, setParentfolder2] = useState([]);
    const [parentfolder3, setParentfolder3] = useState([]);
    const [parentfolder4, setParentfolder4] = useState([]);
    const [inStage, setInStage] = useState([]);
    const [onDraggin1, setIsDragging1] = useState(false);
    const [onDraggin2, setIsDragging2] = useState(false);
    const [onDraggin3, setIsDragging3] = useState(false);
    const [onDraggin4, setIsDragging4] = useState(false);
    const [updatting, setUpdatting] = useState(false);
    const [FileObject, setFileObject] = useState([]);
    const [stageType, setStageType] = useState(0);
    const [nextStage, setnextStage] = useState(0);
    const [nextBeforeStg, setNextBeforeStg] = useState({});
    const [showLinkModal, setShowlinkmodal] = useState(false);
    const [isEditingLink, setIsEditingLink] = useState(false); //is editing link
    const [currentLink, setCurrentLink] = useState([]);
    const [showFolderModal, setshowFolderModal] = useState(false);
    const [folderName, setfolderName] = useState("");
    const [folderFace, setFolderFace] = useState(0);
    const [folderOBJ, setFolderOBJ] = useState([]);
    const [updatingFolder, setUpdattingFolder] = useState(false);

    //Logics to implement file dragging
    const [fileOnface, setFileOnface] = useState(0); //It will tell us which card the file is on when it is dragged

    const [transferFile, setTransferFile] = useState(false);
    const [draggingFromPC, setDraggingFromPC] = useState(true);//It turns off only if I drag a file from the page

    const [currentFileTo, setCurrentFileTo] = useState([]); //If I drag a file it will enter this object

    const [loadingMapping, setLoadingMapping] = useState(false);
    const [onBranch_M, setOnBranch_M] = useState(3);
    const [lastFolderBranch_M, setLastFolderBranch_M] = useState({})
    const [currentStage_M, setCurrentStage_M] = useState(0); //Helps navigate between stages correctly
    const [currentCategory, setCurrentCategory] = useState(0);
    const [GFolderBranch1, setGFolderBranch1] = useState([]);
    const [GFolderBranch2, setGFolderBranch2] = useState([]);
    const [GFolderBranch3, setGFolderBranch3] = useState([]);
    const [GFolderBranch4, setGFolderBranch4] = useState([]);
    const [GFilesBranch4, setGFilesBranch4] = useState([]);

    const [ModalGlobalFile, setModalGlobalFile] = useState(false);
    const [GlobalFiles, setGlobalFiles] = useState([]);
    const [loadingGlobF, SetloadingGlobF] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [assignedTask, setAssignedTask] = useState([]);
    const [supportLinkToUser, setSupportLinkToUser] = useState([]);
    const [onDropFolder, setOndropFolder] = useState(4);
    const [DownloadModalFile, setDownloadModalFile] = useState(false);
    const [isSidebarHidden, setIsSidebarHidden] = useState(localStorage.getItem('sidebarHidden') === 'true');
    const [selectedItems, setSelectedItems] = useState([]);
    const {
        Face1_currentFolder,
        Face2_currentFolder,
        Face3_currentFolder,
        Face4_currentFolder,
        Face5_currentFolder,
        Face6_currentFolder,
        Face7_currentFolder,
        Face8_currentFolder,
        Face9_currentFolder,
        Face10_currentFolder,
        Face11_currentFolder,
        Face12_currentFolder,
        setFace1_currentFolder,
        setFace2_currentFolder,
        setFace3_currentFolder,
        setFace4_currentFolder,
        setFace5_currentFolder,
        setFace6_currentFolder,
        setFace7_currentFolder,
        setFace8_currentFolder,
        setFace9_currentFolder,
        setFace10_currentFolder,
        setFace11_currentFolder,
        setFace12_currentFolder
    } = useFolderRoutes(inStage); //Here we are going to import the routes and send the object of the current container to form the route
    const [isMinimized, setIsMinimized] = useState(false);
    const [loadingFilesInServer, setLoadingFilesInServer] = useState(false);

    //Here notification
    const notify = (message) => {
        toast.success(message, {
            position: 'bottom-center',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            theme: "light",
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "backgroundColor: blue"
        });
    };

    const notifyError = (message) => {
        toast.error(message, {
            position: 'bottom-center',
            autoClose: 6000,
            hideProgressBar: false,
            theme: "light",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "backgroundColor: blue"
        });
    };

    //Here we can get categories

    useEffect(() => {

        const Categ_List = async () => {

            try {

                const response = await axios.post(`${server_backend}/show-category-list`, {}, headers);
                console.log(response.data);
                if (userRole === 1) {
                    setCategories(response.data);
                } else if (userRole === 2) {
                    setCategories(response.data.Category);
                    setAssignedTask(response.data.Task);
                }

            } catch (error) {
                console.log(error);
            }
        }

        Categ_List()

    }, [0]);

    useEffect(() => {

        const getFirstStage = async () => {
            setLoadingFace(true);
            try {

                const response = await axios.post(`${server_backend}/get-first-stage`, {}, headers);

                if (response.data.statusCode == 100) {
                    setInStage(response.data.result);
                    showFiles(response.data.result.TaskID);
                    setCurrentCategory(response.data.result.category_id);
                    BefAndNxtStage(response.data.result.TaskID);
                    setCurrentStage_M(response.data.result.TaskID);
                    getSupportLinkUser(response.data.result.TaskID);

                }

            } catch (error) {

                console.log(error);

            } finally {
                setLoadingFace(false);
            }

        }

        getFirstStage()

    }, [0]);

    //Custom event to show and hide the left bar of the main panel
    useEffect(() => {
        const handleSidebarVisibilityChange = (event) => {
            setIsSidebarHidden(event.detail);
        };
        window.addEventListener('sidebarVisibilityChanged', handleSidebarVisibilityChange);

        return () => {
            window.removeEventListener('sidebarVisibilityChanged', handleSidebarVisibilityChange);
        };
    }, []);

    const handleOpenModal = () => {
        setShowModal(true);
        setOnBranch_M(3);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const showStages = async (idcategory) => {

        try {

            const response = await axios.post(`${server_backend}/show-stages`, {
                idcategory: idcategory
            }, headers);
            setStage(response.data);
        } catch (error) {
            console.log(error)
        }


    }

    const BefAndNxtStage = async (stageID) => {

        try {

            const response = await axios.post(`${server_backend}/before-and-next-stage-show`, {
                stageID: stageID
            }, headers)
            setNextBeforeStg(response.data);
        } catch (error) {
            console.log(error)
        }
    }

    const showFiles = async (idstage) => {

        setLoadingFace(true)

        try {
            const response = await axios.post(`${server_backend}/show-file-stages`, {
                idstage: idstage
            }, headers);
            //console.log(response.data)
            setFile_1(response.data.files.AttachmentFilePath);
            setFile_2(response.data.files.ProgressFilePath);
            setFile_3(response.data.files.ObstacleFilePath);
            setFile_4(response.data.files.CompletionFilePath);

            //here to show folders
            setfolder_1(response.data.folder.folderAttachment);
            setfolder_2(response.data.folder.folderProgress);
            setfolder_3(response.data.folder.folderTaskObstacle);
            setfolder_4(response.data.folder.folderTaskCompletion);

        } catch (error) {
            console.log(error)
        } finally {
            setLoadingFace(false)
        }

    }

    /*     const updateProgress = (percent) => {
            // Show progress bar with percentage completed
            if (!toast.isActive('progressBar')) {
                toast(`Uploading: ${percent}% Completed`, {
                    position: 'bottom-center',
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    toastId: 'progressBar',
                    className: 'custom-toast',
                    progress: percent / 100,
    
                });
            } else {
                // If there is already an active toast, update its content
                toast.update('progressBar', {
                    render: `Uploading: ${percent}% Completed`,
                    progress: percent / 100,
                    hideProgressBar: false
                });
            }
        }; */

    const CHUNK_SIZE = 5 * 1024 * 1024;

    const updateProgress = (overallProgress, filename) => {
        setFiles((prevFiles) =>
            prevFiles.map((file) =>
                file.name === filename ? { ...file, progress: overallProgress } : file
            )
        );
    };

    const TaskInsertFile = async (event, stageData, stageType, ParentFolder) => {
        event.preventDefault();
        setIsMinimized(false);
        setDownloadModalFile(true);
        setLoadingFilesInServer(false);
        if (stageType === 1) {
            setIsDragging1(false);
        } else if (stageType === 2) {
            setIsDragging2(false);
        } else if (stageType === 3) {
            setIsDragging3(false);
        } else if (stageType === 4) {
            setIsDragging4(false);
        }
        setLoadingFilesInServer(true)
        const uploadFile = async (file) => {
            const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

            for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
                const start = chunkIndex * CHUNK_SIZE;
                const end = Math.min(start + CHUNK_SIZE, file.size);
                const chunk = file.slice(start, end);

                const formData = new FormData();
                formData.append('chunk', chunk);
                formData.append('chunkIndex', chunkIndex);
                formData.append('totalChunks', totalChunks);
                formData.append('filename', file.name);
                formData.append('stagetype', stageType);
                formData.append('parentFolderID', ParentFolder);
                formData.append('stageID', stageData.TaskID);
                formData.append('stageName', stageData.TaskName);
                formData.append('username', user2);

                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        ...headers,
                    },
                    onUploadProgress: function (progressEvent) {
                        const currentProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        const overallProgress = Math.round(((chunkIndex + currentProgress / 100) * 100) / totalChunks);
                        updateProgress(overallProgress, file.name);
                    },
                };

                try {

                    await axios.post(`${server_backend}/insert-task-file`, formData, config);
                } catch (error) {
                    console.error(`Error uploading chunk ${chunkIndex}:`, error);
                    throw error;
                }
            }
        };

        try {
            for (let i = 0; i < selectedFiles.length; i++) {
                setFiles((prevFiles) => [
                    ...prevFiles,
                    { name: selectedFiles[i].name, progress: 0 },
                ]);
                await uploadFile(selectedFiles[i]);
            }

        } catch (error) {
            console.error('Error uploading files:', error);
        } finally {
            showFiles(stageData.TaskID);
            //setFiles([]);
            setLoadingFilesInServer(false);
            setDownloadModalFile(true);
            setSelectedFiles([]);
            //document.getElementById('fileInput').value = '';
            notify("Files uploaded successfully");
        }
    };


    const handleDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const items = event.dataTransfer.items;
        const files = [];

        const traverseFileTree = (item, path = "") => {
            return new Promise((resolve, reject) => {
                if (item.isFile) {
                    item.file((file) => {
                        file.fullPath = path + file.name;
                        files.push(file);
                        resolve();
                    });
                } else if (item.isDirectory) {
                    const dirReader = item.createReader();
                    dirReader.readEntries((entries) => {
                        const promises = entries.map((entry) => traverseFileTree(entry, path + item.name + "/"));
                        Promise.all(promises).then(resolve).catch(reject);
                    });
                }
            });
        };

        const promises = [];
        for (let i = 0; i < items.length; i++) {
            const item = items[i].webkitGetAsEntry();
            if (item) {
                promises.push(traverseFileTree(item));
            }
        }

        Promise.all(promises).then(() => {
            setSelectedFiles([...selectedFiles, ...files]);
        }).catch((error) => {
            console.error(error);
        });
    };

    const closeModalFile = () => {
        setUpdatting(false)
    }

    const closeModalFolder = () => {
        setUpdattingFolder(false)
    }

    const turnOffBorder = () => {
        const cardFace1 = document.getElementById('card-face1');
        if (cardFace1) {
            cardFace1.style.border = "";
        }
        const cardFace2 = document.getElementById('card-face2');
        if (cardFace2) {
            cardFace2.style.border = "";
        }
        const cardFace3 = document.getElementById('card-face3');
        if (cardFace3) {
            cardFace3.style.border = "";
        }
        const cardFace4 = document.getElementById('card-face4');
        if (cardFace4) {
            cardFace4.style.border = "";
        }
    }

    const handleDragOver1 = (event) => {
        event.preventDefault();
        event.stopPropagation();
        //This function detects if the file comes from the PC or from the same page
        if (transferFile === false && draggingFromPC === true) {

            setIsDragging1(true);

        } else {
            setFileOnface(1)
            setnextStage(inStage.TaskID);
            const cardFace1 = document.getElementById('card-face1');
            if (cardFace1) {
                cardFace1.style.border = '1px solid blue';
            }
        }


    };

    const handleDragLeave1 = (event) => {
        event.preventDefault();
        const rect = event.currentTarget.getBoundingClientRect();
        const x = event.clientX;
        const y = event.clientY;

        // Check if the dragLeave event occurs outside the boundaries of the card
        if (x < rect.left || x >= rect.right || y < rect.top || y >= rect.bottom) {
            setIsDragging1(false);
            setDraggingFromPC(true);
            const cardFace1 = document.getElementById('card-face1');
            if (cardFace1) {
                cardFace1.style.border = "";
            }
        }
    };


    const handleDragOver2 = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (transferFile === false && draggingFromPC === true) {
            setIsDragging2(true);

        } else {

            const cardFace2 = document.getElementById('card-face2');
            if (cardFace2) {
                cardFace2.style.border = '1px solid blue';
            }

            setFileOnface(2)
            setnextStage(inStage.TaskID)
        }

    };

    const handleDragLeave2 = (event) => {

        event.preventDefault();
        const rect = event.currentTarget.getBoundingClientRect();
        const x = event.clientX;
        const y = event.clientY;

        // Check if the dragLeave event occurs outside the boundaries of the card
        if (x < rect.left || x >= rect.right || y < rect.top || y >= rect.bottom) {

            const cardFace2 = document.getElementById('card-face2');
            if (cardFace2) {
                cardFace2.style.border = "";
            }

            setIsDragging2(false);
            setDraggingFromPC(true);
            setFileOnface(0)
        }


    };

    const handleDragOver3 = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (transferFile === false && draggingFromPC === true) {


            setIsDragging3(true);

        } else {

            const cardFace3 = document.getElementById('card-face3');
            if (cardFace3) {
                cardFace3.style.border = '1px solid blue';
            }

            setFileOnface(3)
            setnextStage(inStage.TaskID)
        }


    };

    const handleDragLeave3 = (event) => {

        event.preventDefault();
        const rect = event.currentTarget.getBoundingClientRect();
        const x = event.clientX;
        const y = event.clientY;

        // Check if the dragLeave event occurs outside the boundaries of the card
        if (x < rect.left || x >= rect.right || y < rect.top || y >= rect.bottom) {

            const cardFace3 = document.getElementById('card-face3');
            if (cardFace3) {
                cardFace3.style.border = "";
            }
            setIsDragging3(false);
            setDraggingFromPC(true);
        }

    };

    const handleDragOver4 = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (transferFile === false && draggingFromPC === true) {

            setIsDragging4(true);

        } else {
            const cardFace4 = document.getElementById('card-face4');
            if (cardFace4) {
                cardFace4.style.border = '1px solid blue';
            }
            setFileOnface(4)
            setnextStage(inStage.TaskID) //allows transferring the file to another stage in phase 4
        }
    };

    const handleDragLeave4 = (event) => {

        event.preventDefault();
        const rect = event.currentTarget.getBoundingClientRect();
        const x = event.clientX;
        const y = event.clientY;

        // Check if the dragLeave event occurs outside the boundaries of the card
        if (x < rect.left || x >= rect.right || y < rect.top || y >= rect.bottom) {
            const cardFace4 = document.getElementById('card-face4');
            if (cardFace4) {
                cardFace4.style.border = "";
            }
            setIsDragging4(false);
            setDraggingFromPC(true);
            setnextStage(inStage.TaskID);
        }

    };

    const fileToFace = () => {
        //We are going to use a custom event to refresh containers C1 - C8
        window.dispatchEvent(new Event('fileToFace'));
    };

    const updateCurrentFileTo = (newCurrentFileTo) => {
        const event = new CustomEvent('currentFileToUpdated', { detail: newCurrentFileTo });
        window.dispatchEvent(event);
    };

    const handleDragStart = (event, file, faceSource) => {
        //console.log(file)
        setTransferFile(true);
        setDraggingFromPC(false);
        event.dataTransfer.setData('text/plain', JSON.stringify(file));
        fileToFace();
        const updatedFileTo = {
            fileObj: file,
            facesource: faceSource,
        };

        setCurrentFileTo(updatedFileTo);
        updateCurrentFileTo(updatedFileTo);
    };

    const handleCopyAndPaste = (file, faceSource) => {

        setTransferFile(true);
        setDraggingFromPC(false);

        fileToFace();
        const updatedFileTo = {
            fileObj: file,
            facesource: faceSource,
        };
        console.log(updatedFileTo);
        setCurrentFileTo(updatedFileTo);
        updateCurrentFileTo(updatedFileTo);

    }

    const handleActionComplete = () => {
        //We are going to use a custom event to refresh containers C1 - C8
        window.dispatchEvent(new Event('changeFileFaceComplete'));
    };

    const updateProgressToTransf = (percent) => {
        if (percent === 100) {
            // Close the progress bar and show "Transfer complete" message
            toast.update('progressBar', {
                render: 'Transfer complete',
                position: "bottom-center",
                progress: 1,
                type: 'success',
                autoClose: 4000, // Close after 3 seconds
                className: 'custom-toast',
            });
        } else {
            // Show progress bar with percentage completed
            if (!toast.isActive('progressBar')) {
                toast(`Moving: ${percent}% Completed`, {
                    position: 'bottom-center',
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: percent / 100,
                    toastId: 'progressBar',
                    className: 'custom-toast',
                });
            } else {
                // If there is already an active toast, update its content
                toast.update('progressBar', {
                    render: `Moving: ${percent}% Completed`,
                    progress: percent / 100,
                    position: 'bottom-center',
                });
            }
        }
    };

    const getCurrentFolderObject = (faceSource) => {
        switch (faceSource) {
            case 1:
                return Face1_currentFolder;
            case 2:
                return Face2_currentFolder;
            case 3:
                return Face3_currentFolder;
            case 4:
                return Face4_currentFolder;
            case 5:
                return Face5_currentFolder;
            case 6:
                return Face6_currentFolder;
            case 7:
                return Face7_currentFolder;
            case 8:
                return Face8_currentFolder;
            case 9:
                return Face9_currentFolder;
            case 10:
                return Face10_currentFolder;
            case 11:
                return Face11_currentFolder;
            case 12:
                return Face12_currentFolder;
            default: return undefined;
        }
    };


    const chageFileFace = async (event, fileObjs, FaceNumber) => {
        event.preventDefault();
        //setLoadingFace(true);
        const totalFiles = fileObjs.fileObj.length;

        let filesProcessed = 0;
        const DestinationRoute = getCurrentFolderObject(FaceNumber);

        const AlternateRoute = DestinationRoute.currentRoute;
        const DestinationRoute2 = DestinationRoute.currentFolder.id;

        const DestinationRouteID = DestinationRoute2 ? DestinationRoute2 : onDropFolder;


        try {
            for (const fileObj of fileObjs.fileObj) {
                const folderToFolder = fileObj.folder_name;

                try {
                    if (currentFileTo.facesource === 4 && FaceNumber === 1 || currentFileTo.facesource === FaceNumber) {
                        notifyError("You can't transfer files that are already in another stage in the first container, if you want to return it transfer it first in work in progress");
                        continue;
                    }

                    if (currentFileTo.facesource !== FaceNumber) {
                        if (folderToFolder === undefined) {
                            const response = await axios.post(`${server_backend}/change-face-file`, {
                                fileObj: fileObj,
                                sourceFace: fileObjs.facesource,
                                destinationFace: FaceNumber,
                                onDropFolder: DestinationRouteID,
                                AlternateRoute: AlternateRoute,
                                stageName: inStage.TaskName,
                                lastStageID: inStage.TaskID, //to revert the file to last task
                                username: user2
                            }, headers);

                            if (response.data === 100) {
                                filesProcessed++;
                                const percentCompleted = Math.round((filesProcessed / totalFiles) * 100);
                                updateProgressToTransf(percentCompleted);

                                //handleActionComplete();  //We are going to use a custom event to refresh containers C1 - C8
                                //notify("files have been moved successfully");
                            } else if (response.data === 200) {
                                toast.error("An unexpected error has occurred or there is a file with the same name in the next stage. It is recommended to change the name", {
                                    position: 'top-right',
                                    autoClose: 3000, // time in milliseconds that the toast will be visible
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            } else if (response.data === 201) {
                                Swal.fire({
                                    icon: 'warning',
                                    title: 'File Transfer Issue',
                                    text: 'The file could not be transferred because there is no available successor container. To proceed, please create a new container on this platform to correctly direct the files. If the problem persists, please contact the administrator.',
                                    confirmButtonText: 'OK'
                                });
                                return;
                            }

                        } else {
                            const currentFolder = fileObj;
                            const folderTaskID = fileObj.task_id;
                            const faceSource = fileObjs.facesource;
                            const faceDestination = FaceNumber;

                            const response = await axios.post(`${server_backend}/change-face-folder`, {
                                currentFolder: currentFolder,
                                faceSource: faceSource,
                                faceDestination: faceDestination,
                                taskID: folderTaskID,
                                onDropFolder: DestinationRouteID,
                                stageName: inStage.TaskName,
                                lastStageID: inStage.TaskID // to revert the file to last task
                            }, headers);

                            if (response.data.statusCode === 100) {
                                filesProcessed++;
                                const percentCompleted = Math.round((filesProcessed / totalFiles) * 100);
                                updateProgressToTransf(percentCompleted);
                                //turnOffBorder();
                                //setSelectedFiles([]);
                                //setOndropFolder(4);
                                //showFiles(inStage.TaskID);

                                //notify("files have been moved successfully");
                            }
                        }
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        } finally {
            setSelectedFiles([]);
            setOndropFolder(4);
            turnOffBorder();
            setLoadingFace(false);
            const FaceSourceToRefresh2 = getCurrentFolderObject(fileObjs.facesource);
            const FolderSourceID = FaceSourceToRefresh2.currentFolder.id;
            showFilesInFolderRefresh(FolderSourceID ? FolderSourceID : onDropFolder, fileObjs.facesource); // update container source path
            showFilesInFolderRefresh(DestinationRouteID ? DestinationRouteID : onDropFolder, FaceNumber); //update container destination route
            handleActionComplete();
            // Ensure the progress reaches 100% at the end
            updateProgressToTransf(100);
        }
    };


    //Here we can get containers
    const getFileContainer = (stageID, containerNum, containerName) => {
        const new_Windows = window.open("", "_blank", "width=600,height=400");

        ReactDOM.render(
            <ContainerW
                stageID={stageID}
                containerNum={containerNum}
                containerName={containerName}
                username={user2}
                handleDragStart={handleDragStart}
                setTransferFile={setTransferFile}
                transferFile={transferFile}
                setDraggingFromPC={setDraggingFromPC}
                draggingFromPC={draggingFromPC}
                convertSize={convertSize}
                chageFileFace={chageFileFace}
                currentFileTo={currentFileTo}
                FileIcon={FileIcon}
                deleteFolder={deleteFolder}
                handleCopyAndPaste={handleCopyAndPaste}
                deleteFileAndFolder={deleteFileAndFolder}
                showFiles={showFiles}
                updateCurrentFolderAndRoute={updateCurrentFolderAndRoute}
                updateProgressToTransf={updateProgressToTransf}

            />, new_Windows.document.body);


    }

    function convertSize(sizeInBytes) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];

        if (sizeInBytes == 0) return '0 Byte';

        const i = parseInt(Math.floor(Math.log(sizeInBytes) / Math.log(1024)));
        return Math.round(sizeInBytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    const OpenLinkModal = (LinkNum) => {
        setShowlinkmodal(true);
        getLinks(LinkNum)
    }

    const CloseLinkModal = () => {
        setShowlinkmodal(false)
    }

    const handleEditLink_Open = () => {
        setIsEditingLink(true);
    };

    const handleEditLink_Close = () => {
        setIsEditingLink(false);
    };

    const getLinks = async (LinkNum) => {

        try {

            const response = await axios.post(`${server_backend}/get-links`, {
                stageID: inStage.TaskID,
                LinkNum: LinkNum
            }, headers);

            setCurrentLink(response.data.result ? response.data.result : { linksupport_id: LinkNum })

        } catch (error) {
            console.log(error)
        }
    }

    const insertLink = async (LinkNum) => {

        try {

            const response = await axios.post(`${server_backend}/insert-link-in-stage`, {
                LinkNum: LinkNum,
                stageID: inStage.TaskID,
                link: currentLink.link
            }, headers);
            if (response.data.statusCode === 100) {
                notify("The link has been added successfully");
                setIsEditingLink(false);
            } else {

                toast.error("An unexpected error has occurred, if it persists, contact the system administrator", {
                    position: 'top-right',
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });

            }

        } catch (error) {
            console.log(error)
        }
    }
    const handleLinkChange = (event) => {
        // Update the state when the link value changes
        setCurrentLink({
            ...currentLink,
            link: event.target.value
        });
    };

    const FileIcon = ({ fileName }) => {
        const getFileExtension = (fileName) => {
            return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
        };

        const getFileIcon = (fileExtension) => {
            switch (fileExtension.toLowerCase()) {
                case 'rar':
                case 'zip':
                    return <FaFileArchive style={{ color: "#e9b266" }} />;
                case 'img':
                    return <FaFileImage />;
                case 'txt':
                    return <FaFile />
                        ;
                case 'jpeg':
                    return <FaFileImage />
                        ;
                case 'pdf':
                    return <FaFilePdf style={{ color: "#bd3200" }} />;
                case 'doc':
                case 'docx':
                    return <FaFileWord />;
                case '':
                    return <FaFolder style={{ color: "#ebc83a" }} />;
                default:
                    return <FaFile />;
            }
        };

        const fileExtension = getFileExtension(fileName);
        const fileIcon = getFileIcon(fileExtension);

        return <div>{fileIcon}</div>;
    };

    const updateCurrentFolderAndRoute = (faceSource, folder, isGoingBack) => {
        const baseRoutes = {
            1: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/completed_files/`,
            2: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/work_in_progress/`,
            3: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/docs/`,
            4: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/completed_files/`,
            5: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/1/`,
            6: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/2/`,
            7: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/3/`,
            8: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/4/`,
            9: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/5/`,
            10: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/6/`,
            11: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/7/`,
            12: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/8/`
        }; //This part here will help us establish the base paths once the user goes back through the folders.

        const updateRoute = (prevRoute, folderName, parentFolderID) => {
            if (isGoingBack) {
                if (parentFolderID === 4) {
                    // Return to base route
                    return baseRoutes[faceSource];
                } else {
                    // Remove the last segment from the route
                    const routeParts = prevRoute.split('/').filter(part => part !== '');
                    routeParts.pop();
                    return routeParts.length > 0 ? '/' + routeParts.join('/') + '/' : '/';
                }
            } else {
                if (prevRoute.endsWith(`${folderName}/`)) {
                    // Avoid duplicating folder names in the route
                    return prevRoute;
                }
                // Add the folder name to the route
                return `${prevRoute}${folderName}/`;
            }
        };

        switch (faceSource) {
            case 1:
                setFace1_currentFolder(prevState => ({
                    currentFolder: folder,
                    currentRoute: updateRoute(prevState.currentRoute, folder.folder_name, folder.parent_folder_id)
                }));
                break;
            case 2:
                setFace2_currentFolder(prevState => ({
                    currentFolder: folder,
                    currentRoute: updateRoute(prevState.currentRoute, folder.folder_name, folder.parent_folder_id)
                }));
                break;
            case 3:
                setFace3_currentFolder(prevState => ({
                    currentFolder: folder,
                    currentRoute: updateRoute(prevState.currentRoute, folder.folder_name, folder.parent_folder_id)
                }));
                break;
            case 4:
                setFace4_currentFolder(prevState => ({
                    currentFolder: folder,
                    currentRoute: updateRoute(prevState.currentRoute, folder.folder_name, folder.parent_folder_id)
                }));
                break;
            case 5:
                setFace5_currentFolder(prevState => ({
                    currentFolder: folder,
                    currentRoute: updateRoute(prevState.currentRoute, folder.folder_name, folder.parent_folder_id)
                }));
                break;

            case 6:
                setFace6_currentFolder(prevState => ({
                    currentFolder: folder,
                    currentRoute: updateRoute(prevState.currentRoute, folder.folder_name, folder.parent_folder_id)
                }));
                break;

            case 7:
                setFace7_currentFolder(prevState => ({
                    currentFolder: folder,
                    currentRoute: updateRoute(prevState.currentRoute, folder.folder_name, folder.parent_folder_id)
                }));
                break;

            case 8:
                setFace8_currentFolder(prevState => ({
                    currentFolder: folder,
                    currentRoute: updateRoute(prevState.currentRoute, folder.folder_name, folder.parent_folder_id)
                }));
                break;

            case 9:
                setFace9_currentFolder(prevState => ({
                    currentFolder: folder,
                    currentRoute: updateRoute(prevState.currentRoute, folder.folder_name, folder.parent_folder_id)
                }));
                break;

            case 10:
                setFace10_currentFolder(prevState => ({
                    currentFolder: folder,
                    currentRoute: updateRoute(prevState.currentRoute, folder.folder_name, folder.parent_folder_id)
                }));
                break;

            case 11:
                setFace11_currentFolder(prevState => ({
                    currentFolder: folder,
                    currentRoute: updateRoute(prevState.currentRoute, folder.folder_name, folder.parent_folder_id)
                }));
                break;

            case 12:
                setFace12_currentFolder(prevState => ({
                    currentFolder: folder,
                    currentRoute: updateRoute(prevState.currentRoute, folder.folder_name, folder.parent_folder_id)
                }));
                break;
            default:
                console.error('Invalid face source specified');
        }
    };

    const showFilesInFolder = async (folderID, faceSource, isGoingBack = false) => {
        setLoadingFace(true);

        try {
            const response = await axios.post(`${server_backend}/show-files-in-folder`, {
                folderID: folderID,
                facesource: faceSource,
                taskID: inStage.TaskID
            }, headers);

            if (faceSource === 1) {
                setFile_1(response.data.result.files);
                if (folderID === 4) {
                    setfolder_1(response.data.result.folder);
                    setParentfolder1([]);
                    setFace1_currentFolder({
                        currentFolder: {},
                        currentRoute: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/completed_files/`
                    });
                } else {
                    setfolder_1(response.data.result.folder.slice(1));
                    setParentfolder1(response.data.result.folder[0]);
                    updateCurrentFolderAndRoute(faceSource, response.data.result.folder[0], isGoingBack);

                }
            } else if (faceSource === 2) {
                setFile_2(response.data.result.files);
                if (folderID === 4) {
                    setfolder_2(response.data.result.folder);
                    setParentfolder2([]);
                    setFace2_currentFolder({
                        currentFolder: {},
                        currentRoute: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/work_in_progress/`
                    });
                } else {
                    setfolder_2(response.data.result.folder.slice(1));
                    setParentfolder2(response.data.result.folder[0]);
                    updateCurrentFolderAndRoute(faceSource, response.data.result.folder[0], isGoingBack);
                    //alert(Face2_currentFolder.currentRoute);
                }
            } else if (faceSource === 3) {
                setFile_3(response.data.result.files);
                if (folderID === 4) {
                    setfolder_3(response.data.result.folder);
                    setParentfolder3([]);
                    setFace3_currentFolder({
                        currentFolder: {},
                        currentRoute: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/Testing/docs/`
                    });
                } else {
                    setfolder_3(response.data.result.folder.slice(1));
                    setParentfolder3(response.data.result.folder[0]);
                    updateCurrentFolderAndRoute(faceSource, response.data.result.folder[0], isGoingBack);
                    //alert(Face3_currentFolder.currentRoute);
                }
            } else if (faceSource === 4) {
                setFile_4(response.data.result.files);
                if (folderID === 4) {
                    setfolder_4(response.data.result.folder);
                    setParentfolder4([]);
                    setFace4_currentFolder({
                        currentFolder: {},
                        currentRoute: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/completed_files/`
                    });
                } else {
                    setfolder_4(response.data.result.folder.slice(1));
                    setParentfolder4(response.data.result.folder[0]);
                    updateCurrentFolderAndRoute(faceSource, response.data.result.folder[0], isGoingBack);
                    //alert(Face4_currentFolder.currentRoute);
                }
            }

        } catch (error) {
            console.log(error);
        } finally {
            setLoadingFace(false);
            setOndropFolder(4); //to maintain integrity when transferring files
        }
    };

    const showFilesInFolderRefresh = async (folderID, faceSource) => {
        setLoadingFace(true);
        setOndropFolder(4); //to maintain integrity when transferring files
        try {

            const response = await axios.post(`${server_backend}/show-files-in-folder`, {
                folderID: folderID,
                facesource: faceSource,
                taskID: inStage.TaskID
            }, headers);


            if (faceSource === 1) {

                setFile_1(response.data.result.files);
                if (folderID === 4) {
                    setfolder_1(response.data.result.folder);
                    setParentfolder1([])
                } else {
                    if (Object.keys(Face1_currentFolder.currentFolder).length === 0) {
                        setfolder_1(response.data.result.folder.slice(0));
                    } else {
                        setfolder_1(response.data.result.folder.slice(1));
                    }
                }


            } else if (faceSource === 2) {
                setFile_2(response.data.result.files);
                if (folderID === 4) {
                    setfolder_2(response.data.result.folder);
                    setParentfolder2([])
                } else {
                    if (Object.keys(Face2_currentFolder.currentFolder).length === 0) {
                        setfolder_2(response.data.result.folder.slice(0));
                    } else {
                        setfolder_2(response.data.result.folder.slice(1));
                    }
                }
            }
            else if (faceSource === 3) {
                setFile_3(response.data.result.files);
                if (folderID === 4) {
                    setfolder_3(response.data.result.folder);
                    setParentfolder3([])
                } else {
                    if (Object.keys(Face3_currentFolder.currentFolder).length === 0) {
                        setfolder_3(response.data.result.folder.slice(0));
                    } else {
                        setfolder_3(response.data.result.folder.slice(1));
                    }
                }
            }
            else if (faceSource === 4) {
                setFile_4(response.data.result.files);
                if (folderID === 4) {
                    setfolder_4(response.data.result.folder);
                    setParentfolder4([]);
                } else {
                    if (Object.keys(Face4_currentFolder.currentFolder).length === 0) {
                        setfolder_4(response.data.result.folder.slice(0));
                    } else {
                        setfolder_4(response.data.result.folder.slice(1));
                    }
                }
            }


        } catch (error) {
            console.log(error)
        } finally {
            setLoadingFace(false);
            setSelectedFiles([]);
            setOndropFolder(4); //to maintain integrity when transferring files
        }
    };

    const changeDirectory = async (event, fileObjs, folderObj, FaceNumber) => {

        event.preventDefault();
        event.stopPropagation();
        //setLoadingFace(true)
        const faceSource = currentFileTo.facesource;

        try {
            const totalFiles = fileObjs.length; // Total number of files to process
            let filesProcessed = 0; // Counter for processed files

            for (const fileObj of fileObjs) {
                const folderToFolder = fileObj.folder_name;

                if (folderToFolder === undefined) {
                    try {
                        const response = await axios.post(`${server_backend}/change-directory-file`, {
                            fileObj,
                            folderObj: folderObj,
                            FaceNumber: FaceNumber,
                            faceSource: faceSource,
                            TaskID: inStage.TaskID,
                            stageName: inStage.TaskName,
                        }, headers);

                        if (response.data.statusCode === 100) {
                            //showFiles(inStage.TaskID);
                            //const folderID = fileObj.folder_id;
                            //showFilesInFolder(folderID, FaceNumber);
                        }

                    } catch (error) {
                        console.log(error);
                    } finally {
                        setLoadingFace(false);
                        // Update progress
                        filesProcessed++;
                        const percentCompleted = Math.round((filesProcessed / totalFiles) * 100);
                        updateProgressToTransf(percentCompleted);
                    }

                } else {
                    try {
                        const foldersource = fileObj.id;
                        const folderdestination = folderObj.id;
                        const task_idP = folderObj.task_id;

                        if (foldersource !== folderdestination) { // to avoid folder conflict
                            const response = await axios.post(`${server_backend}/move-folder-with-files`, {
                                fileObj,
                                folderObj: folderObj,
                                FaceNumber: FaceNumber,
                                faceSource: faceSource,
                                taskID: inStage.TaskID ? inStage.TaskID : task_idP,
                            }, headers);

                            if (response.data.statusCode == 100) {
                                //console.log("HelloWold")
                            }
                        }

                    } catch (error) {
                        console.log(error);
                    } finally {
                        setLoadingFace(false);
                        // Update progress
                        filesProcessed++;
                        const percentCompleted = Math.round((filesProcessed / totalFiles) * 100);
                        updateProgressToTransf(percentCompleted);
                    }
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            notify("Files have been moved successfully");
            setSelectedFiles([]);
            //showFiles(inStage.TaskID);
            const folderToFolder = fileObjs[0].folder_name;
            if (folderToFolder === undefined) {
                showFilesInFolderRefresh(fileObjs[0].folder_id, FaceNumber);
                showFilesInFolderRefresh(fileObjs[0].folder_id, faceSource);
            } else {
                showFilesInFolderRefresh(fileObjs[0].parent_folder_id ? fileObjs[0].parent_folder_id : fileObjs[0].id, FaceNumber);
                showFilesInFolderRefresh(fileObjs[0].parent_folder_id, faceSource);
            }
            // Ensure the progress reaches 100% at the end
            updateProgressToTransf(100);
        }




    }

    const createFolder = async () => {

        try {

            const response = await axios.post(`${server_backend}/create-folder-in-face`, {
                taskID: inStage.TaskID,
                folderName: folderName,
                folderFace: folderFace
            }, headers);

            if (response.data.statusCode === 100) {

                showFiles(inStage.TaskID)
                notify("files have been moved successfully");
                setshowFolderModal(false);
                setfolderName("");
            }

        } catch (error) {
            console.log(error)
        }

    }

    const Mapping_S = async (taskID, branch, category) => {
        setLoadingMapping(true);
        setGFolderBranch4([]);
        setGFilesBranch4([]);
        try {
            const response = await axios.post(`${server_backend}/show-mapping-files`, {
                taskID: taskID,
                branch: branch,
                category: category
            }, headers);
            if (response.data.statusCode === 100) {
                if (branch === 3) {
                    setGFolderBranch3(response.data.result);
                } else if (branch === 2) {
                    setGFolderBranch2(response.data.result);
                } else if (branch === 1 || branch < 0) {
                    setGFolderBranch1(response.data.result);
                }

            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoadingMapping(false);
        }
    };

    const showFilesMapping = async (folderID, t_code, taskID, Lbranch) => {
        setLoadingMapping(true);
        //setOnBranch_M(4);
        //alert(onBranch_M);
        setCurrentStage_M(taskID);
        try {

            const response = await axios.post(`${server_backend}/show-files-and-folder-mapping`, {
                folderID: folderID,
                t_code: t_code,
                taskID: taskID
            }, headers);

            if (response.data.statusCode === 100) {

                //setGFolderBranch3([]);

                if (Lbranch === 4) {
                    setGFolderBranch4(response.data.result.folders);
                    setGFilesBranch4(response.data.result.files);
                } else if (Lbranch > 4) {
                    setGFolderBranch4(response.data.result.folders.slice(1));
                    setGFilesBranch4(response.data.result.files);
                }


                const parentFolder = response.data.result.folders[0].parent_folder_id;
                const tableCode = response.data.result.folders[0].table_code;
                const taskID = response.data.result.folders[0].task_id;

                setLastFolderBranch_M({
                    parentFolder: parentFolder,
                    table_code: tableCode,
                    taskID: taskID
                });
            }



        } catch (error) {
            console.log(error)
        } finally {
            setLoadingMapping(false);
        }
    };

    const OpenGlobalFileModal = () => {
        setModalGlobalFile(true);
    }

    const CloseGlobalFileModal = () => {
        setModalGlobalFile(false);
        setGlobalFiles([]);
    }

    const showGlobalFiles = async (filename) => {
        SetloadingGlobF(true);
        try {

            const response = await axios.post(`${server_backend}/find-gobal-files`, {
                filename: filename
            }, headers);

            if (response.data.statusCode === 100) {
                setGlobalFiles(response.data.result);

            }

        } catch (error) {
            console.log(error)
        } finally {
            SetloadingGlobF(false);
        }

    }

    function handleFileSelect(event) {
        setSelectedFiles(Array.from(event.target.files));
    }

    const deleteFolder = async (folder, stageType, taskID) => {

        try {

            const response = await axios.post(`${server_backend}/delete-folder`, {
                folderID: folder.id,
                stageType: stageType,
                taskID: taskID,
                username: user2
            }, headers);


            if (response.data.statusCode === 100) {
                //showFilesInFolder(folder.parent_folder_id, stageType)
            }

        } catch (error) {
            console.log(error)
        }


    }

    const deleteFileAndFolder = (objects, faceSource, showFiles, showFilesInFolder, user2) => {
        Swal.fire({
            icon: 'warning',
            title: 'Alert',
            html: 'Are you sure you want to delete the selected items? This action cannot be undone.',
            showCancelButton: true,
            confirmButtonText: 'Accept',
            cancelButtonText: 'Cancel'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const totalObjects = objects.length;
                let objectsProcessed = 0;
    
                for (const object of objects) {
                    try {
                        const isFolder = object.folder_name !== undefined;
    
                        if (isFolder) {
                            await deleteFolder(object, faceSource, object.task_id, showFilesInFolder, user2);
                        } else {
                            await DeleteFile(null, object, faceSource, user2, showFiles, notify, notifyError);
                        }
                    } catch (error) {
                        console.log(error);
                        notifyError(`An error occurred while deleting ${object.folder_name || object.file_name}.`);
                    } finally {
                        objectsProcessed++;
                        const percentCompleted = Math.round((objectsProcessed / totalObjects) * 100);
                        updateProgressToTransf(percentCompleted);
                    }
                }
    
                Swal.fire({
                    icon: 'success',
                    title: 'Completed',
                    text: 'All selected items have been processed.',
                    confirmButtonText: 'Accept'
                });
                
                setSelectedFiles([]);
                showFiles(objects[0].task_id);
                handleActionComplete();
                updateProgressToTransf(100); // Ensure the progress reaches 100% at the end
                notify("Files processed successfully");
            }
        });
    };
    
    const downloadFolder = async (folderID, stageType, taskID) => {
        try {
            const response = await axios.post(`${server_backend}/download-folder`, {
                folderID: folderID,
                stageType: stageType,
                taskID: taskID
            }, headers);

            if (response.data.Status === 100) {
                const zip = new JSZip();

                for (const file of response.data.files) {
                    const filePath = file.file_path;
                    const fileContent = await fetch(filePath).then(res => res.blob());
                    zip.file(file.file_name, fileContent);
                }

                // Generate the zip file and download it
                const zipBlob = await zip.generateAsync({ type: 'blob' });
                const url = window.URL.createObjectURL(zipBlob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'folder_download.zip');
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                link.remove();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const getSupportLinkUser = async (TaskID) => {

        try {

            const response = await axios.post(`${server_backend}/get-support-link-to-user`, {
                TaskID: TaskID
            }, headers);
            setSupportLinkToUser(response.data);

        } catch (error) {
            console.log(error);
        }
    }

    const formatLink = (link) => {
        // Check if the link already contains the protocol
        if (!/^https?:\/\//i.test(link)) {
            return 'http://' + link;
        }
        return link;
    };

    const receiveCopiedFile = async (event, fileObjs, FaceNumber) => {
        event.preventDefault();
        //console.log(fileObjs);
        const DestinationRoute = getCurrentFolderObject(FaceNumber);

        const AlternateRoute = DestinationRoute.currentRoute;
        const DestinationRoute2 = DestinationRoute.currentFolder.id;

        const DestinationRouteID = DestinationRoute2 ? DestinationRoute2 : onDropFolder;

        try {

            for (const fileObj of fileObjs.fileObj) {
                //console.log(fileObj);

                if (currentFileTo.facesource !== FaceNumber) {
                    const folderToFolder = fileObj.folder_name;
                    if (folderToFolder === undefined) {
                        try {

                            const response = await axios.post(`${server_backend}/recieve-copied-file`, {
                                fileObj: fileObj,
                                sourceFace: fileObjs.facesource,
                                destinationFace: FaceNumber,
                                stageName: inStage.TaskName,
                                DestinationRouteID: DestinationRouteID,
                                AlternateRoute: AlternateRoute,
                                lastStageID: inStage.TaskID, //to revert the file to last task
                                username: user2
                            }, headers);

                            if (response.data === 100) {

                            } else if (response.data === 200) {
                                toast.error("An unexpected error has occurred or there is a file with the same name in the next stage. it is recommended to change the name", {
                                    position: 'top-right',
                                    autoClose: 3000, // time in milliseconds that the toast will be visible
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                            }

                        } catch (error) {
                            console.log(error)
                        }
                    } else {

                        try {

                            const currentFolder = fileObj;
                            const TaskID = fileObj.task_id;
                            const faceSource = fileObjs.facesource
                            const faceDestination = FaceNumber;
                            const response = await axios.post(`${server_backend}/recieve-copied-folder`, {
                                currentFolder: currentFolder,
                                faceSource: faceSource,
                                TaskID: TaskID,
                                TaskName: inStage.TaskName,
                                faceDestination: faceDestination,
                                lastStageID: nextStage //to revert the file to last task,

                            }, headers)

                            if (response.data.statusCode === 100) {
                                turnOffBorder()
                                showFiles(inStage.TaskID)
                                notify("files have been moved successfully")
                            }
                        } catch (error) {
                            console.log(error)
                        }
                    }

                }
            }

        } catch (error) {
            console.log(error)
        } finally {
            turnOffBorder();
            showFilesInFolderRefresh(DestinationRouteID ? DestinationRouteID : onDropFolder, FaceNumber); //update container destination route
            handleActionComplete();  //We are going to use a custom event to refresh containers C1 - C8
            notify("files have been moved successfully")
        }


    };

    const handleCheckboxChange = (item, isChecked) => {
        setSelectedFiles(prevItems => {
            const itemIndex = prevItems.findIndex(i => i.id === item.id);
            if (isChecked && itemIndex === -1) {
                return [...prevItems, item];
            } else if (!isChecked && itemIndex !== -1) {
                const updatedItems = [...prevItems];
                updatedItems.splice(itemIndex, 1);
                return updatedItems;
            }
            return prevItems;
        });
    };

    return (

        <div className="page-content" data-simplebar-track="secondary" data-simplebar="init" style={{ marginTop: "-11px", height: "100vh", overflow: "auto" }}>
            <ToastContainer
                position="bottom-center"
                autoClose={true}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                theme="light"
                pauseOnFocusLoss
                draggable
                pauseOnHover
                style={{ zIndex: 9999 }}
            />
            <div>
                <div className="row">
                    <div className="row col-md-3 position-fixed">
                        {isSidebarHidden === false ? (
                            <div className={`chat-leftsidebar ${isSidebarHidden ? 'hidden' : 'visible'}`} style={{ minHeight: "100vh", backgroundColor: "rgb(67, 85, 144)", minWidth: "0px", maxWidth: "79%" }}>
                                <div className="px-4 pt-4 mb-4">
                                    {userRole === 1 && (
                                        <div className="d-flex align-items-start" style={{ paddingBottom: "10px" }}>
                                            <button type="button" className="btn btn-outline-success btn-sm" onClick={() => {
                                                Mapping_S(inStage.TaskID, onBranch_M, inStage.category_id);
                                                handleOpenModal()
                                            }} style={{ width: "100%", height: "41px" }}>
                                                <h5 className="mb-4 text-white">Mapping
                                                    <i class="bx bxs-edit-location" style={{ fontSize: "25px", paddingLeft: "27%" }}></i>
                                                </h5>
                                            </button>
                                        </div>
                                    )}

                                    {userRole === 1 && (
                                        <div className="search-box">
                                            <input type="text" className="form-control text-white" onClick={OpenGlobalFileModal} placeholder="Search here..." style={{ backgroundColor: "rgb(67, 85, 144)" }} />
                                            <i className="ri-search-2-line search-icon" style={{ color: "white" }}></i>
                                        </div>
                                    )}
                                </div>

                                <div className="chat-room-list" data-simplebar style={{ minHeight: "345px", maxHeight: "345px" }}>
                                    <div className="chat-message-list">
                                        <ul className="list-unstyled chat-list chat-user-list" id="userList">
                                            <li className="active">
                                                <div className="live-preview">
                                                    <div className="accordion" id="default-accordion-example">
                                                        {categories.map((categoriesR) => (
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header" id="headingOne">
                                                                    <button
                                                                        style={{ backgroundColor: "rgb(67, 85, 144)", color: "white" }}
                                                                        className="accordion-button collapsed" type="button"
                                                                        data-bs-toggle="collapse"
                                                                        data-bs-target={`#collapseOne-${categoriesR.id}`} aria-expanded="false" aria-controls="collapseOne"
                                                                        onClick={() => {
                                                                            if (userRole === 1) {
                                                                                showStages(categoriesR.id)
                                                                            } else if (userRole === 2) {
                                                                                const tasksForCategory = assignedTask.filter(task => task.category_id === categoriesR.id);
                                                                                setStage(tasksForCategory);
                                                                            }
                                                                        }}>
                                                                        {categoriesR.name}
                                                                    </button>
                                                                </h2>
                                                                <div id={`collapseOne-${categoriesR.id}`} className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#default-accordion-example">
                                                                    <div className="accordion-body" style={{ backgroundColor: "rgb(67, 85, 144)" }}>
                                                                        <ul className="list-unstyled chat-list chat-user-list">
                                                                            {stage.map((stageR) => (
                                                                                <li key={stageR.TaskName} style={{ padding: "2px" }}>
                                                                                    <button
                                                                                        style={colorstatus === stageR.TaskName ? { backgroundColor: "rgb(29 56 87)" } : { backgroundColor: "#4771a3" }}
                                                                                        className={colorstatus === stageR.TaskName ? "btn btn-primary w-100 Zbutton-stage fw-semibold text-white" : "btn btn-light w-100 Zbutton-stage fw-semibold text-white"}
                                                                                        onClick={() => {
                                                                                            setColorstatus(stageR.TaskName);
                                                                                            showFiles(stageR.TaskID)
                                                                                            setInStage(stageR)
                                                                                            BefAndNxtStage(stageR.TaskID);
                                                                                            getSupportLinkUser(stageR.TaskID);
                                                                                            setParentfolder1([])
                                                                                            setParentfolder2([])
                                                                                            setParentfolder3([])
                                                                                            setParentfolder4([])
                                                                                        }}
                                                                                    >
                                                                                        {stageR.TaskName}
                                                                                    </button>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="chat-message-list">
                                        <ul className="list-unstyled chat-list chat-user-list mb-0" id="channelList">
                                        </ul>
                                    </div>
                                </div>

                                {userRole === 1 && (
                                    <div className="search-box" style={{ padding: "15px" }}>
                                        <Link className="btn btn-outline-danger w-100" to="/trash"><i className="bx bxs-trash"></i>Trash</Link>
                                    </div>
                                )}

                            </div>
                        ) : (
                            <div className={`chat-leftsidebar ${isSidebarHidden ? 'hidden' : 'visible'}`} style={{ minHeight: "100vh", backgroundColor: "rgb(67, 85, 144)", minWidth: "0px", maxWidth: "30%" }}>
                                <div className="pt-4 mb-4">
                                    <div className="d-flex align-items-start" style={{ paddingBottom: "10px" }}>
                                        <button type="button" className="btn btn-outline-success btn-sm" style={{ width: "100%", height: "41px" }}>
                                            <h6 className="mb-4 text-white">
                                                M
                                            </h6>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                    <div className="row col-md-9" style={{ marginLeft: isSidebarHidden === true ? "6.5%" : "18.5%", width: isSidebarHidden === true ? "93.5%" : "81.2%", padding: "6px" }}>
                        <div className="card col-12" style={{ marginBottom: "0.2em" }}>
                            {inStage == false ? (
                                <div className="card col-12 text-center" style={{ height: "10%" }}>
                                    <div className="card-header">
                                        <h4 className="text-primary">Stage Selection Required</h4>
                                        <div className="fc-toolbar-chunk">
                                            You must select a stage to view the support link. If you do not have an assigned container, you will not be able to see it.
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                userRole === 1 ? (
                                    <div>
                                        <div className="row justify-content-between">
                                            <div className="col-12 col-md-4">
                                                <div className="row mt-1">
                                                    {inStage && (
                                                        <div className="d-flex flex-wrap">
                                                            {[1, 2, 3, 4, 5, 6, 7, 8].map((num) => (
                                                                <div key={num} className="p-1">
                                                                    <button
                                                                        className="btn btn-light btn-sm"
                                                                        data-bs-toggle="tooltip"
                                                                        data-bs-placement="top"
                                                                        title={currentLink.link || "empty"}
                                                                        onMouseOver={() => getLinks(num)}
                                                                        onClick={() => OpenLinkModal(num)}
                                                                    >
                                                                        <span className="d-flex flex-column align-items-center">
                                                                            <img
                                                                                src="assets/images/iconword.png"
                                                                                alt="Header icon"
                                                                                className="icon-img"
                                                                                style={{ width: "2.5vh" }}
                                                                            />
                                                                            <span>{num}</span>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-4 text-center mt-2">
                                                <h1 className="m-2 nav-link fw-semibold fs-22">
                                                    {inStage ? inStage.TaskName : "SELECT A STAGE"}
                                                </h1>
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <div className="row mt-1">
                                                    {inStage && (
                                                        <div className="d-flex flex-wrap">
                                                            {[9, 10, 11, 12, 13, 14, 15, 16].map((num) => (
                                                                <div key={num} className="p-1">
                                                                    <button
                                                                        className="btn btn-light btn-sm"
                                                                        data-bs-toggle="tooltip"
                                                                        data-bs-placement="top"
                                                                        title={currentLink.link || "empty"}
                                                                        onMouseOver={() => getLinks(num)}
                                                                        onClick={() => OpenLinkModal(num)}
                                                                    >
                                                                        <span className="d-flex flex-column align-items-center">
                                                                            <img
                                                                                src="assets/images/iconword.png"
                                                                                alt="Header icon"
                                                                                className="icon-img"
                                                                                style={{ width: "2.5vh" }}
                                                                            />
                                                                            <span>{num}</span>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="row" style={{ top: "10px" }}>
                                                {inStage && (
                                                    <div className="d-flex">
                                                        {[...Array(8).keys()].map((i) => (
                                                            <div style={{ paddingLeft: "2px" }} key={i}>
                                                                <button
                                                                    className="btn btn-light btn-sm"
                                                                    onClick={() => {
                                                                        const url = formatLink(supportLinkToUser[i]?.link);
                                                                        if (supportLinkToUser[i]?.link) {
                                                                            const width = 600;
                                                                            const height = 400;
                                                                            const left = (window.innerWidth / 2) - (width / 2);
                                                                            const top = (window.innerHeight / 2) - (height / 2);
                                                                            window.open(url, 'popup', `width=${width},height=${height},top=${top},left=${left}`);
                                                                        } else {
                                                                            notifyError("There is no link assigned to this button");
                                                                        }
                                                                    }}
                                                                    onMouseOver={() => getLinks(i + 1)}
                                                                    style={{ marginBlock: "20px" }}
                                                                >
                                                                    <span>
                                                                        <img src="assets/images/iconword.png" alt="Header icon" style={{ width: "2em", top: "15px", paddingLeft: 0, paddingRight: 0 }} />
                                                                        {i + 1}
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <center>
                                                <h1 className="m-2 nav-link fw-semibold fs-22">{inStage ? inStage.TaskName : "SELECT A STAGE"}</h1>
                                            </center>
                                        </div>
                                        <div className="col-4">
                                            <div className="row">
                                                {inStage && (
                                                    <div className="d-flex" style={{ paddingLeft: "0" }}>
                                                        {[...Array(8).keys()].map((i) => (
                                                            <div style={{ paddingLeft: "2px" }} key={i + 8}>
                                                                <button
                                                                    className="btn btn-light btn-sm"
                                                                    onClick={() => {
                                                                        const url = formatLink(supportLinkToUser[i + 8]?.link);
                                                                        if (supportLinkToUser[i + 8]?.link) {
                                                                            const width = 600;
                                                                            const height = 400;
                                                                            const left = (window.innerWidth / 2) - (width / 2);
                                                                            const top = (window.innerHeight / 2) - (height / 2);
                                                                            window.open(url, 'popup', `width=${width},height=${height},top=${top},left=${left}`);
                                                                        } else {
                                                                            notifyError("There is no link assigned to this button");
                                                                        }
                                                                    }}
                                                                    onMouseOver={() => getLinks(i + 9)}
                                                                    style={{ marginBlock: "20px" }}
                                                                >
                                                                    <span>
                                                                        <img src="assets/images/iconword.png" alt="Header icon" style={{ width: "2em", top: "15px", paddingLeft: 0, paddingRight: 0 }} />
                                                                        {i + 9}
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </div>


                        <div className="col-md-6" style={{ padding: "0", paddingRight: "5px" }}>
                            <ContextMenuTrigger id="face1" holdToDisplay={-1}>
                                <div
                                    onDragOver={handleDragOver1}
                                    onDragLeave={handleDragLeave1}
                                    onDrop={transferFile === true ? (event) => chageFileFace(event, currentFileTo, fileOnface) : undefined}>
                                    {!onDraggin1 ? (
                                        <div className="card" id="card-face1">
                                            {!loadingface ? (
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <h5 className="fw-semibold">
                                                                {nextBeforeStg.beforeStage == null ? (inStage.TaskName ? inStage.TaskName : "no stage selected") : nextBeforeStg.beforeStage}
                                                            </h5>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="d-flex gap-1 align-items-center">
                                                                <div className="dropdown">
                                                                    <button className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                        <i class="bx bx-dots-horizontal-rounded"></i>
                                                                    </button>

                                                                    <div className="dropdown-menu dropdown-menu-end">
                                                                        <Link className="dropdown-item" onClick={() => {
                                                                            setIsDragging1(true)
                                                                        }}><i className="ri-eye-fill align-bottom me-2 text-muted"></i> Upload Files</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-1">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="checkAll"
                                                                value="option"
                                                                onChange={(e) => {
                                                                    const isChecked = e.target.checked;

                                                                    file_1.map((item) => {
                                                                        const checkbox = document.getElementById(`checkbox-${item.id}`);
                                                                        if (checkbox) {
                                                                            checkbox.checked = isChecked;
                                                                            handleCheckboxChange(item, isChecked);
                                                                        }
                                                                    });

                                                                    folder_1.map((item) => {
                                                                        const checkbox = document.getElementById(`checkbox-${item.id}`);
                                                                        if (checkbox) {
                                                                            checkbox.checked = isChecked;
                                                                            handleCheckboxChange(item, isChecked);
                                                                        }
                                                                    });



                                                                    if (!isChecked) {
                                                                        e.target.checked = false;
                                                                        setSelectedFiles([]);
                                                                    }
                                                                }}
                                                                style={{ marginLeft: "9px" }} />
                                                        </div>
                                                        <div className="col-5 text-primary" style={{ padding: "0", paddingLeft: "11.6%" }} >
                                                            Name
                                                        </div>
                                                        <div className="col-2 text-primary" style={{ padding: "0", paddingLeft: "4.5%" }}>
                                                            Date
                                                        </div>
                                                        <div className="col-2 text-primary" style={{ padding: "0", paddingLeft: "12%" }}>
                                                            Size
                                                        </div>
                                                        <div className="col-1">
                                                        </div>
                                                    </div>
                                                    <div className="custom-scrollbar-wrapper" style={{ height: "85vh", borderRadius: "10px" }}>
                                                        <ul className="nav justify-content-center flex-column">

                                                            {parentfolder1 && parentfolder1.folder_name && (
                                                                <div>
                                                                    <div
                                                                        className="d-flex align-items-center border border-dashed p-2 rounded mt-2 file-item" style={{ height: "7vh" }}
                                                                        onDoubleClick={() => {
                                                                            showFilesInFolder(parentfolder1.parent_folder_id, 1, true);
                                                                        }}
                                                                        onDrop={(event) => changeDirectory(event, selectedFiles, { id: parentfolder1.parent_folder_id }, 1)}>
                                                                        <div className="flex-shrink-0 me-3">
                                                                            <div>
                                                                                <div className="rounded fs-24">
                                                                                    <FileIcon fileName={parentfolder1.folder_name} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="mb-1">
                                                                                <Link>
                                                                                    [..]
                                                                                </Link>
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {folder_1.map((folder_1R) => (
                                                                <React.Fragment key={folder_1R.id}>
                                                                    <ContextMenuTrigger id={`contextmenu-${folder_1R.id}`} holdToDisplay={-1}>
                                                                        <div
                                                                            className="d-flex align-items-center border border-dashed p-2 rounded mt-2 file-item"
                                                                            style={{ height: "7vh" }}
                                                                            onDoubleClick={() => showFilesInFolder(folder_1R.id, 1)}
                                                                            onDrop={(event) => changeDirectory(event, selectedFiles, folder_1R, 1)}
                                                                            onDragOver={() => setOndropFolder(folder_1R.id)}
                                                                            onDragLeave={() => setOndropFolder(4)}
                                                                            draggable
                                                                            onDragStart={(e) => {
                                                                                handleDragStart(e, selectedFiles.length === 0 ? [folder_1R] : selectedFiles, 1);

                                                                                if (selectedFiles.length === 0) {
                                                                                    // Automatically check the checkbox when dragging starts if selectedFiles is empty
                                                                                    const checkbox = document.getElementById(`checkbox-${folder_1R.id}`);
                                                                                    checkbox.checked = true;
                                                                                    handleCheckboxChange(folder_1R, true);

                                                                                }
                                                                            }}
                                                                            onDragEnd={() => {
                                                                                setDraggingFromPC(true);
                                                                                setTransferFile(false);
                                                                            }}
                                                                            onClick={(e) => {
                                                                                if (e.target.type !== 'checkbox') {
                                                                                    const checkbox = document.getElementById(`checkbox-${folder_1R.id}`);
                                                                                    checkbox.checked = !checkbox.checked;
                                                                                    handleCheckboxChange(folder_1R, checkbox.checked);
                                                                                }
                                                                            }}
                                                                            onContextMenu={(e) => {

                                                                                if (e.target.type !== 'checkbox' && selectedFiles.length < 2) {
                                                                                    const checkbox = document.getElementById(`checkbox-${folder_1R.id}`);
                                                                                    checkbox.checked = !checkbox.checked;
                                                                                    handleCheckboxChange(folder_1R, checkbox.checked);

                                                                                }

                                                                            }}
                                                                        >
                                                                            <div className="row w-100">
                                                                                <div className="col-1">
                                                                                    <div className="form-check" style={{ top: "8px" }}>
                                                                                        <input
                                                                                            id={`checkbox-${folder_1R.id}`}
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            onChange={(e) => handleCheckboxChange(folder_1R, e.target.checked)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-1">
                                                                                    <div className="rounded fs-24">
                                                                                        <FileIcon fileName={folder_1R.folder_name} />
                                                                                    </div>
                                                                                </div>

                                                                                <div className="col-5" style={{ padding: "0", paddingBlock: "8px" }}>
                                                                                    <div className="flex-grow-1 ms-3">
                                                                                        <h6 className="mb-1">
                                                                                            <a href="#!">
                                                                                                {folder_1R.folder_name}
                                                                                            </a>
                                                                                        </h6>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-3" style={{ padding: "0", paddingBlock: "8px" }}>
                                                                                    <small className="text-muted">
                                                                                        <p className="mb-0 ctext-content">{folder_1R.created_at}</p>
                                                                                    </small>
                                                                                </div>
                                                                                <div className="col-2" style={{ paddingBlock: "6px" }}>
                                                                                    <small className="text-muted">{convertSize(folder_1R.size)}</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ContextMenuTrigger>

                                                                    <ContextMenu id={`contextmenu-${folder_1R.id}`} style={{ zIndex: "50" }}>
                                                                        <div className="context-menu-container">
                                                                            <div className="context-menu">
                                                                                <a className="menu-item" href="#!" onClick={() => showFilesInFolder(folder_1R.id, 1)}>
                                                                                    <i className="mdi mdi-arrow-top-right-bold-box-outline"></i>
                                                                                    <span className="mail-list-link p-2">Open</span>
                                                                                </a>

                                                                                <button className="menu-item" onClick={() => {
                                                                                    handleCopyAndPaste(selectedFiles, 1);
                                                                                    hideMenu();
                                                                                }}>
                                                                                    <i className="ri-edit-2-fill me-2 align-middle fw-medium"></i>
                                                                                    <span className="mail-list-link">Copy</span>
                                                                                </button>

                                                                                <a className="menu-item" href="#!" onClick={() => {
                                                                                    setFolderOBJ(folder_1R);
                                                                                    setStageType(1);
                                                                                    setUpdattingFolder(true);
                                                                                }}>
                                                                                    <i className="ri-edit-2-fill me-2 align-middle fw-medium"></i>
                                                                                    <span className="mail-list-link">Rename Folder</span>
                                                                                </a>
                                                                                {selectedFiles.length > 0 ? (
                                                                                    <a className="menu-item" href="#!" onClick={() => deleteFileAndFolder(selectedFiles, 1, showFiles, showFilesInFolder, user2)}>
                                                                                        <i className="ri-delete-bin-4-fill" style={{ paddingRight: "10px", color: "red" }}></i>
                                                                                        <span className="mail-list-link text-danger">Delete selected files</span>
                                                                                    </a>
                                                                                ) : null}

                                                                                <a className="menu-item" href="#!" onClick={() => downloadFolder(folder_1R.id, 1, folder_1R.task_id)}>
                                                                                    <i className="ri-download-2-line" style={{ paddingRight: "10px" }}></i>
                                                                                    <span className="mail-list-link">Download</span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </ContextMenu>
                                                                </React.Fragment>
                                                            ))}

                                                            {file_1.map((file_1R) => (
                                                                <React.Fragment key={file_1R.id}>
                                                                    <ContextMenuTrigger id={`contextmenu-${file_1R.file_name}`} holdToDisplay={-1}>
                                                                        <div
                                                                            className="d-flex align-items-center border border-dashed p-2 rounded mt-2 file-item"
                                                                            draggable
                                                                            onDragStart={(e) => {

                                                                                handleDragStart(e, selectedFiles.length === 0 ? [file_1R] : selectedFiles, 1);

                                                                                if (selectedFiles.length === 0) {
                                                                                    const checkbox = document.getElementById(`checkbox-${file_1R.id}`);
                                                                                    checkbox.checked = true;
                                                                                    handleCheckboxChange(file_1R, true);
                                                                                }
                                                                            }}
                                                                            onDragEnd={() => {
                                                                                setDraggingFromPC(true);
                                                                                setTransferFile(false);
                                                                            }}
                                                                            style={{ height: "7vh" }}
                                                                            onDoubleClick={() => {
                                                                                const backendFilePath = `${server_backend}/${file_1R.file_path}`;
                                                                                window.open(backendFilePath, '_blank');
                                                                            }}

                                                                            onClick={(e) => {
                                                                                if (e.target.type !== 'checkbox') {
                                                                                    const checkbox = document.getElementById(`checkbox-${file_1R.id}`);
                                                                                    checkbox.checked = !checkbox.checked;
                                                                                    handleCheckboxChange(file_1R, checkbox.checked);
                                                                                }
                                                                            }}
                                                                            onContextMenu={(e) => {

                                                                                if (e.target.type !== 'checkbox' && selectedFiles.length < 2) {
                                                                                    const checkbox = document.getElementById(`checkbox-${file_1R.id}`);
                                                                                    checkbox.checked = !checkbox.checked;
                                                                                    handleCheckboxChange(file_1R, checkbox.checked);

                                                                                }
                                                                            }}
                                                                        >
                                                                            <div className="row w-100">
                                                                                <div className="col-1">
                                                                                    <div className="form-check" style={{ top: "12px" }}>
                                                                                        <input
                                                                                            id={`checkbox-${file_1R.id}`}
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            onChange={(e) => handleCheckboxChange(file_1R, e.target.checked)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-1">
                                                                                    <div className="flex-shrink-0 me-3">
                                                                                        <div className="avatar-sm" style={{ width: "2em", height: "2em" }}>
                                                                                            <div className="avatar-title bg-light text-secondary rounded fs-24">
                                                                                                <FileIcon fileName={file_1R.file_name} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-5" style={{ padding: "0" }}>
                                                                                    <div className="flex-grow-1 ms-3">
                                                                                        <p className="mb-1">
                                                                                            <a href="#!" onClick={(e) => {
                                                                                                e.stopPropagation(); // Prevent the outer div's onClick from being triggered
                                                                                                const backendFilePath = `${server_backend}/${file_1R.file_path}`;
                                                                                                const fileNameWithoutSpaces = file_1R.file_name.replace(/\s/g, '');
                                                                                                saveAs(backendFilePath, fileNameWithoutSpaces);
                                                                                            }}>
                                                                                                {file_1R.file_name}
                                                                                            </a>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-3" style={{ padding: "0", paddingBlock: "2px" }}>
                                                                                    <small className="text-muted">
                                                                                        <p className="mb-0 ctext-content">{file_1R.created_at}</p>
                                                                                    </small>
                                                                                </div>
                                                                                <div className="col-2">
                                                                                    <small className="text-muted">{convertSize(file_1R.size)}</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ContextMenuTrigger>

                                                                    <ContextMenu id={`contextmenu-${file_1R.file_name}`} style={{ zIndex: "50" }}>
                                                                        <div className="context-menu-container">
                                                                            <div className="context-menu">
                                                                                <a className="menu-item" href="#!" onClick={() => window.open(file_1R.file_path, '_blank')}>
                                                                                    <i className="mdi mdi-arrow-top-right-bold-box-outline"></i>
                                                                                    <span className="mail-list-link p-2">Open</span>
                                                                                </a>

                                                                                <button className="menu-item" onClick={() => {
                                                                                    handleCopyAndPaste(selectedFiles, 1);
                                                                                    hideMenu();
                                                                                }}>
                                                                                    <i className="ri-edit-2-fill me-2 align-middle fw-medium"></i>
                                                                                    <span className="mail-list-link">Copy</span>
                                                                                </button>

                                                                                <a className="menu-item" href="#!" onClick={() => {
                                                                                    setUpdatting(true);
                                                                                    setFileObject(file_1R, closeModalFile);
                                                                                    setStageType(1);
                                                                                }}>
                                                                                    <i className="ri-edit-2-fill me-2 align-middle fw-medium"></i>
                                                                                    <span className="mail-list-link">Rename</span>
                                                                                </a>
                                                                                <a className="menu-item" href="#!" onClick={() => {
                                                                                    const name = file_1R.file_name;
                                                                                    const name1 = name.replace(/\s/g, '');
                                                                                    saveAs(file_1R.file_path, name1);

                                                                                    const backendFilePath = `${server_backend}/${file_1R.file_path}`;
                                                                                    window.open(backendFilePath, '_blank');
                                                                                }}>
                                                                                    <i className="ri-download-2-line" style={{ paddingRight: "10px" }}></i>
                                                                                    <span className="mail-list-link">Download</span>
                                                                                </a>

                                                                                {selectedFiles.length > 0 ? (
                                                                                    <a className="menu-item" href="#!" onClick={(event) => {
                                                                                        event.preventDefault();
                                                                                        deleteFileAndFolder(selectedFiles, 1, showFiles, showFilesInFolder, user2);
                                                                                    }}>
                                                                                        <i className="ri-delete-bin-4-fill" style={{ paddingRight: "10px", color: "red" }}></i>
                                                                                        <span className="mail-list-link text-danger">{selectedFiles.length > 1 ? "Delete selected files" : "Delete"}</span>
                                                                                    </a>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                    </ContextMenu>
                                                                </React.Fragment>
                                                            ))}

                                                        </ul>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={{ maxHeight: "279px", minHeight: "279px", borderRadius: "10px" }}>
                                                    <div className="cardbody">
                                                        <h5 className="card-title placeholder-glow p-2">
                                                            <span className="placeholder col-6"></span>
                                                        </h5>
                                                    </div>
                                                    <div className="p-2">
                                                        <p className="card-text placeholder-glow">
                                                            <span className="placeholder col-7"></span>
                                                            <span className="placeholder col-4"></span>
                                                            <span className="placeholder col-4"></span>
                                                            <span className="placeholder col-6"></span>
                                                        </p>
                                                    </div>
                                                </div>

                                            )}

                                        </div>
                                    ) : (
                                        <div className="card">
                                            <div className="card-body">
                                                <h5>Upload Files</h5>
                                                <div className="custom-scrollbar-wrapper" style={{ height: "85vh", borderRadius: "10px" }} onDragOver={handleDragOver1} onDrop={handleDrop}>
                                                    <div className="custom-file-input">
                                                        <input id="fileInput" type="file" multiple webkitdirectory="true" onChange={handleFileSelect} />
                                                        <h1 className=" fw-bold d-flex justify-content-center align-items-center">Drag the file here</h1>
                                                    </div>
                                                    <ul className="list-unstyled chat-list chat-user-list" id="userList">
                                                        {selectedFiles.map((file, index) => (
                                                            <li key={index}>
                                                                <Link>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                                                            <div className="avatar-xxs">
                                                                                <FileIcon fileName={file.name} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 overflow-hidden">
                                                                            <p className="text-truncate mb-0">{file.name}</p>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <center>
                                                    <button className="btn btn-danger" onClick={() => { setIsDragging1(false) }}>Close</button> |
                                                    <button className="btn btn-success w-50" onClick={(event) => {
                                                        TaskInsertFile(event, inStage, 1, parentfolder1.id);
                                                        setSelectedFiles([]);
                                                    }}>Upload files</button>
                                                </center>
                                            </div>
                                        </div>

                                    )}
                                </div>
                            </ContextMenuTrigger>
                            <ContextMenu id="face1" style={{ zIndex: "50" }}>
                                <div className="context-menu-container">
                                    <div className="context-menu">
                                        <button className="menu-item" onClick={() => {
                                            setshowFolderModal(true);
                                            setFolderFace(1)
                                        }}>
                                            <i className="mdi mdi-arrow-top-right-bold-box-outline"></i>
                                            <span className="mail-list-link p-2">Create Folder</span>
                                        </button>

                                        {transferFile && (
                                            <button className="menu-item" onClick={(event) => {
                                                receiveCopiedFile(event, currentFileTo, 1);
                                                hideMenu();
                                            }}>
                                                <i className="mdi mdi-arrow-top-right-bold-box-outline"></i>
                                                <span className="mail-list-link p-2">Paste</span>
                                            </button>
                                        )}

                                        <Link className="menu-item">
                                            <i className="ri-edit-2-fill me-2 align-middle fw-medium"></i>
                                            <span className="mail-list-link">Upload Files</span>
                                        </Link>
                                    </div>
                                </div>
                            </ContextMenu>
                        </div>
                        <div className="col-md-6" style={{ padding: "0" }}>
                            <ContextMenuTrigger id="face2" holdToDisplay={-1}>
                                <div
                                    onDragOver={handleDragOver2}
                                    onDragLeave={handleDragLeave2}
                                    onDrop={transferFile === true ? (event) => chageFileFace(event, currentFileTo, fileOnface) : undefined}>
                                    {!onDraggin2 ? (
                                        <div className="card" id="card-face2">
                                            {!loadingface ? (
                                                <div className="card-body">

                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <h5 className="fw-semibold">
                                                                Work in Progress
                                                            </h5>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="d-flex gap-1 align-items-center">
                                                                <div className="dropdown">
                                                                    <button className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                        <i class="bx bx-dots-horizontal-rounded"></i>
                                                                    </button>

                                                                    <div className="dropdown-menu dropdown-menu-end">
                                                                        <Link className="dropdown-item"
                                                                            onClick={() => {
                                                                                setIsDragging2(true)
                                                                            }}><i className="ri-eye-fill align-bottom me-2 text-muted"></i> Upload Files</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-1">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="checkAll"
                                                                value="option"
                                                                onChange={(e) => {
                                                                    const isChecked = e.target.checked;

                                                                    file_2.map((item) => {
                                                                        const checkbox = document.getElementById(`checkbox-${item.id}`);
                                                                        if (checkbox) {
                                                                            checkbox.checked = isChecked;
                                                                            handleCheckboxChange(item, isChecked);
                                                                        }
                                                                    });

                                                                    folder_2.map((item) => {
                                                                        const checkbox = document.getElementById(`checkbox-${item.id}`);
                                                                        if (checkbox) {
                                                                            checkbox.checked = isChecked;
                                                                            handleCheckboxChange(item, isChecked);
                                                                        }
                                                                    });



                                                                    if (!isChecked) {
                                                                        e.target.checked = false;
                                                                        setSelectedFiles([]);
                                                                    }
                                                                }}
                                                                style={{ marginLeft: "9px" }} />
                                                        </div>
                                                        <div className="col-5 text-primary" style={{ padding: "0", paddingLeft: "11.6%" }} >
                                                            Name
                                                        </div>
                                                        <div className="col-2 text-primary" style={{ padding: "0", paddingLeft: "4.5%" }}>
                                                            Date
                                                        </div>
                                                        <div className="col-2 text-primary" style={{ padding: "0", paddingLeft: "12%" }}>
                                                            Size
                                                        </div>
                                                        <div className="col-1">
                                                        </div>
                                                    </div>
                                                    <div className="custom-scrollbar-wrapper" style={{ height: "85vh", borderRadius: "10px" }}>
                                                        <ul className="nav justify-content-center flex-column">

                                                            {parentfolder2 && parentfolder2.folder_name && (
                                                                <div>
                                                                    <div
                                                                        className="d-flex align-items-center border border-dashed p-2 rounded mt-2 file-item" style={{ height: "7vh" }}
                                                                        onDoubleClick={() => {
                                                                            showFilesInFolder(parentfolder2.parent_folder_id, 2, true);
                                                                        }}
                                                                        onDrop={(event) => changeDirectory(event, selectedFiles, { id: parentfolder2.parent_folder_id }, 2)}>
                                                                        <div className="flex-shrink-0 me-3">
                                                                            <div>
                                                                                <div className="rounded fs-24">
                                                                                    <FileIcon fileName={parentfolder2.folder_name} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="mb-1">
                                                                                <Link>
                                                                                    [..]
                                                                                </Link>
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {folder_2.map((folder_2R) => (
                                                                <React.Fragment key={folder_2R.id}>
                                                                    <ContextMenuTrigger id={`contextmenu-${folder_2R.id}`} holdToDisplay={-1}>
                                                                        <div
                                                                            className="d-flex align-items-center border border-dashed p-2 rounded mt-2 file-item"
                                                                            style={{ height: "7vh" }}
                                                                            onDoubleClick={() => showFilesInFolder(folder_2R.id, 2)}
                                                                            onDrop={(event) => changeDirectory(event, selectedFiles, folder_2R, 2)}
                                                                            onDragOver={() => setOndropFolder(folder_2R.id)}
                                                                            onDragLeave={() => setOndropFolder(4)}
                                                                            draggable
                                                                            onDragStart={(e) => {
                                                                                handleDragStart(e, selectedFiles.length === 0 ? [folder_2R] : selectedFiles, 2);

                                                                                if (selectedFiles.length === 0) {
                                                                                    // Automatically check the checkbox when dragging starts if selectedFiles is empty
                                                                                    const checkbox = document.getElementById(`checkbox-${folder_2R.id}`);
                                                                                    checkbox.checked = true;
                                                                                    handleCheckboxChange(folder_2R, true);
                                                                                }
                                                                            }}
                                                                            onDragEnd={() => {
                                                                                setDraggingFromPC(true);
                                                                                setTransferFile(false);
                                                                            }}
                                                                            onClick={(e) => {
                                                                                if (e.target.type !== 'checkbox') {
                                                                                    const checkbox = document.getElementById(`checkbox-${folder_2R.id}`);
                                                                                    checkbox.checked = !checkbox.checked;
                                                                                    handleCheckboxChange(folder_2R, checkbox.checked);
                                                                                }
                                                                            }}
                                                                            onContextMenu={(e) => {
                                                                                if (e.target.type !== 'checkbox' && selectedFiles.length < 2) {
                                                                                    const checkbox = document.getElementById(`checkbox-${folder_2R.id}`);
                                                                                    checkbox.checked = !checkbox.checked;
                                                                                    handleCheckboxChange(folder_2R, checkbox.checked);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div className="row w-100">
                                                                                <div className="col-1">
                                                                                    <div className="form-check" style={{ top: "8px" }}>
                                                                                        <input
                                                                                            id={`checkbox-${folder_2R.id}`}
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            onChange={(e) => handleCheckboxChange(folder_2R, e.target.checked)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-1">
                                                                                    <div className="flex-shrink-0 me-3">
                                                                                        <div>
                                                                                            <div className="rounded fs-24">
                                                                                                <FileIcon fileName={folder_2R.folder_name} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-5" style={{ padding: "0", paddingBlock: "8px" }}>
                                                                                    <div className="flex-grow-1 ms-3">
                                                                                        <h6 className="mb-1">
                                                                                            <Link>
                                                                                                {folder_2R.folder_name}
                                                                                            </Link>
                                                                                        </h6>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-3" style={{ padding: "0", paddingBlock: "8px" }}>
                                                                                    <small className="text-muted">
                                                                                        <p className="mb-0 ctext-content">{folder_2R.created_at}</p>
                                                                                    </small>
                                                                                </div>
                                                                                <div className="col-2" style={{ paddingBlock: "6px" }}>
                                                                                    <small className="text-muted">{convertSize(folder_2R.size)}</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ContextMenuTrigger>

                                                                    <ContextMenu id={`contextmenu-${folder_2R.id}`} style={{ zIndex: "50" }}>
                                                                        <div className="context-menu-container">
                                                                            <div className="context-menu">
                                                                                <Link className="menu-item" onClick={() => showFilesInFolder(folder_2R.id, 2)}>
                                                                                    <i className="mdi mdi-arrow-top-right-bold-box-outline"></i>
                                                                                    <span className="mail-list-link p-2">Open</span>
                                                                                </Link>

                                                                                <Link className="menu-item" onClick={() => {
                                                                                    setFolderOBJ(folder_2R);
                                                                                    setStageType(2);
                                                                                    setUpdattingFolder(true);
                                                                                }}>
                                                                                    <i className="ri-edit-2-fill me-2 align-middle fw-medium"></i>
                                                                                    <span className="mail-list-link">Rename Folder</span>
                                                                                </Link>

                                                                                {selectedFiles.length > 0 ? (
                                                                                    <a className="menu-item" href="#!" onClick={() => deleteFileAndFolder(selectedFiles, 2, showFiles, showFilesInFolder, user2)}>
                                                                                        <i className="ri-delete-bin-4-fill" style={{ paddingRight: "10px", color: "red" }}></i>
                                                                                        <span className="mail-list-link text-danger">Delete selected files</span>
                                                                                    </a>
                                                                                ) : null}

                                                                                <Link className="menu-item" onClick={() => downloadFolder(folder_2R.id, 2, folder_2R.task_id)}>
                                                                                    <i className="ri-download-2-line" style={{ paddingRight: "10px" }}></i>
                                                                                    <span className="mail-list-link">Download</span>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </ContextMenu>
                                                                </React.Fragment>
                                                            ))}

                                                            {file_2.map((file_2R) => (
                                                                <React.Fragment key={file_2R.id}>
                                                                    <ContextMenuTrigger id={`contextmenu2-${file_2R.file_name}`} holdToDisplay={-1}>
                                                                        <div
                                                                            className="d-flex align-items-center border border-dashed p-2 rounded mt-2 file-item"
                                                                            draggable
                                                                            onDragStart={(e) => {
                                                                                handleDragStart(e, selectedFiles.length === 0 ? [file_2R] : selectedFiles, 2);

                                                                                if (selectedFiles.length === 0) {
                                                                                    // Automatically check the checkbox when dragging starts if selectedFiles is empty
                                                                                    const checkbox = document.getElementById(`checkbox-${file_2R.id}`);
                                                                                    checkbox.checked = true;
                                                                                    handleCheckboxChange(file_2R, true);
                                                                                }
                                                                            }}
                                                                            onDragEnd={() => {
                                                                                setDraggingFromPC(true);
                                                                                setTransferFile(false);
                                                                            }}
                                                                            style={{ height: "7vh" }}
                                                                            onDoubleClick={() => {
                                                                                const backendFilePath = `${server_backend}/${file_2R.file_path}`;
                                                                                window.open(backendFilePath, '_blank');
                                                                            }}
                                                                            onClick={(e) => {
                                                                                if (e.target.type !== 'checkbox') {
                                                                                    const checkbox = document.getElementById(`checkbox-${file_2R.id}`);
                                                                                    checkbox.checked = !checkbox.checked;
                                                                                    handleCheckboxChange(file_2R, checkbox.checked);
                                                                                }
                                                                            }}
                                                                            onContextMenu={(e) => {
                                                                                if (e.target.type !== 'checkbox' && selectedFiles.length < 2) {
                                                                                    const checkbox = document.getElementById(`checkbox-${file_2R.id}`);
                                                                                    checkbox.checked = !checkbox.checked;
                                                                                    handleCheckboxChange(file_2R, checkbox.checked);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div className="row w-100">
                                                                                <div className="col-1">
                                                                                    <div className="form-check" style={{ top: "12px" }}>
                                                                                        <input
                                                                                            id={`checkbox-${file_2R.id}`}
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            onChange={(e) => handleCheckboxChange(file_2R, e.target.checked)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-1">
                                                                                    <div className="flex-shrink-0 me-3">
                                                                                        <div className="avatar-sm" style={{ width: "2em", height: "2em" }}>
                                                                                            <div className="avatar-title bg-light text-secondary rounded fs-24">
                                                                                                <FileIcon fileName={file_2R.file_name} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-5" style={{ padding: "0" }}>
                                                                                    <div className="flex-grow-1 ms-3">
                                                                                        <p className="mb-1">
                                                                                            <Link
                                                                                                onClick={() => {
                                                                                                    const backendFilePath = `${server_backend}/${file_2R.file_path}`;
                                                                                                    const fileNameWithoutSpaces = file_2R.file_name.replace(/\s/g, '');
                                                                                                    saveAs(backendFilePath, fileNameWithoutSpaces);
                                                                                                }}
                                                                                                draggable="false"
                                                                                            >
                                                                                                {file_2R.file_name}
                                                                                            </Link>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-3" style={{ padding: "0", paddingBlock: "2px" }}>
                                                                                    <small className="text-muted">
                                                                                        <p className="mb-0 ctext-content">{file_2R.created_at}</p>
                                                                                    </small>
                                                                                </div>
                                                                                <div className="col-2">
                                                                                    <small className="text-muted">{convertSize(file_2R.size)}</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ContextMenuTrigger>

                                                                    <ContextMenu id={`contextmenu2-${file_2R.file_name}`} style={{ zIndex: "50" }}>
                                                                        <div className="context-menu-container">
                                                                            <div className="context-menu">
                                                                                <Link className="menu-item" onClick={() => {
                                                                                    window.open(file_2R.file_path, '_blank');
                                                                                }}>
                                                                                    <i className="mdi mdi-arrow-top-right-bold-box-outline"></i>
                                                                                    <span className="mail-list-link p-2">Open</span>
                                                                                </Link>

                                                                                <button className="menu-item" onClick={() => {
                                                                                    handleCopyAndPaste(selectedFiles, 2);
                                                                                    hideMenu();
                                                                                }}>
                                                                                    <i className="ri-edit-2-fill me-2 align-middle fw-medium"></i>
                                                                                    <span className="mail-list-link">Copy</span>
                                                                                </button>

                                                                                <Link className="menu-item" onClick={() => {
                                                                                    setUpdatting(true);
                                                                                    setFileObject(file_2R, closeModalFile);
                                                                                    setStageType(2);
                                                                                }}>
                                                                                    <i className="ri-edit-2-fill me-2 align-middle fw-medium"></i>
                                                                                    <span className="mail-list-link">Rename</span>
                                                                                </Link>

                                                                                <Link className="menu-item" onClick={() => {
                                                                                    const name = file_2R.file_name;
                                                                                    const name1 = name.replace(/\s/g, '');
                                                                                    saveAs(file_2R.file_path, name1);
                                                                                }}>
                                                                                    <i className="ri-download-2-line" style={{ paddingRight: "10px" }}></i>
                                                                                    <span className="mail-list-link">Download</span>
                                                                                </Link>

                                                                                {selectedFiles.length > 0 ? (
                                                                                    <a className="menu-item" href="#!" onClick={(event) => {
                                                                                        event.preventDefault();
                                                                                        deleteFileAndFolder(selectedFiles, 2, showFiles, showFilesInFolder, user2);
                                                                                    }}>
                                                                                        <i className="ri-delete-bin-4-fill" style={{ paddingRight: "10px", color: "red" }}></i>
                                                                                        <span className="mail-list-link text-danger">{selectedFiles.length > 1 ? "Delete selected files" : "Delete"}</span>
                                                                                    </a>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                    </ContextMenu>
                                                                </React.Fragment>
                                                            ))}

                                                        </ul>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={{ maxHeight: "279px", minHeight: "279px", borderRadius: "10px" }}>
                                                    <div className="cardbody">
                                                        <h5 className="card-title placeholder-glow p-2">
                                                            <span className="placeholder col-6"></span>
                                                        </h5>
                                                    </div>
                                                    <div className="p-2">
                                                        <p className="card-text placeholder-glow">
                                                            <span className="placeholder col-7"></span>
                                                            <span className="placeholder col-4"></span>
                                                            <span className="placeholder col-4"></span>
                                                            <span className="placeholder col-6"></span>
                                                        </p>
                                                    </div>
                                                </div>
                                            )}

                                        </div>

                                    ) : (
                                        <div className="card">
                                            <div className="card-body">
                                                <h5>Upload Files</h5>
                                                <div className="custom-scrollbar-wrapper" style={{ height: "85vh", borderRadius: "10px" }} onDragOver={handleDragOver2} onDrop={handleDrop}>
                                                    <div className="custom-file-input">
                                                        <input id="fileInput" type="file" multiple webkitdirectory="true" onChange={handleFileSelect} />
                                                        <h1 className=" fw-bold d-flex justify-content-center align-items-center">Drag the file here</h1>
                                                    </div>
                                                    <ul class="list-unstyled chat-list chat-user-list" id="userList">
                                                        {selectedFiles.map((file, index) => (
                                                            <li key={index}>
                                                                <Link>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                                                            <div className="avatar-xxs">
                                                                                <FileIcon fileName={file.name} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 overflow-hidden">
                                                                            <p className="text-truncate mb-0">{file.name}</p>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <center>
                                                    <button className="btn btn-danger" onClick={() => {
                                                        setIsDragging2(false)
                                                    }}>Close </button> | <button className="btn btn-success w-50" onClick={(event) => {
                                                        TaskInsertFile(event, inStage, 2, parentfolder2.id);
                                                        setSelectedFiles([]);
                                                    }}>Upload files</button>
                                                </center>
                                            </div>

                                        </div>
                                    )}

                                </div>
                            </ContextMenuTrigger>
                            <ContextMenu id="face2" style={{ zIndex: "50" }}>
                                <div className="context-menu-container">
                                    <div className="context-menu">
                                        <button className="menu-item" onClick={() => {
                                            setshowFolderModal(true);
                                            setFolderFace(2)
                                        }}>
                                            <i className="mdi mdi-arrow-top-right-bold-box-outline"></i>
                                            <span className="mail-list-link p-2">Create Folder</span>
                                        </button>

                                        {transferFile && (
                                            <button className="menu-item" onClick={(event) => {
                                                receiveCopiedFile(event, currentFileTo, 2);
                                                hideMenu();
                                            }}>
                                                <i className="mdi mdi-arrow-top-right-bold-box-outline"></i>
                                                <span className="mail-list-link p-2">Paste</span>
                                            </button>
                                        )}

                                        <Link className="menu-item">
                                            <i className="ri-edit-2-fill me-2 align-middle fw-medium"></i>
                                            <span className="mail-list-link">Upload Files</span>
                                        </Link>
                                    </div>
                                </div>
                            </ContextMenu>
                        </div>
                        <div className="col-md-6" style={{ padding: "0", paddingRight: "5px" }}>
                            <ContextMenuTrigger id="face3" holdToDisplay={-1}>
                                <div
                                    onDragOver={handleDragOver3}
                                    onDragLeave={handleDragLeave3}
                                    onDrop={transferFile === true ? (event) => chageFileFace(event, currentFileTo, fileOnface) : undefined}
                                >
                                    {!onDraggin3 ? (
                                        <div className="card" id="card-face3">
                                            {!loadingface ? (
                                                <div className="card-body">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <h5 className="fw-semibold">
                                                                Doc's
                                                            </h5>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="d-flex gap-1 align-items-center">
                                                                <div className="dropdown">
                                                                    <button className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                        <i class="bx bx-dots-horizontal-rounded"></i>
                                                                    </button>

                                                                    <div className="dropdown-menu dropdown-menu-end">
                                                                        <Link className="dropdown-item"
                                                                            onClick={() => {
                                                                                setIsDragging3(true)
                                                                            }}><i className="ri-eye-fill align-bottom me-2 text-muted"></i> Upload Files</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-1">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="checkAll"
                                                                value="option"
                                                                onChange={(e) => {
                                                                    const isChecked = e.target.checked;

                                                                    file_3.map((item) => {
                                                                        const checkbox = document.getElementById(`checkbox-${item.id}`);
                                                                        if (checkbox) {
                                                                            checkbox.checked = isChecked;
                                                                            handleCheckboxChange(item, isChecked);
                                                                        }
                                                                    });

                                                                    folder_3.map((item) => {
                                                                        const checkbox = document.getElementById(`checkbox-${item.id}`);
                                                                        if (checkbox) {
                                                                            checkbox.checked = isChecked;
                                                                            handleCheckboxChange(item, isChecked);
                                                                        }
                                                                    });

                                                                    if (!isChecked) {
                                                                        e.target.checked = false;
                                                                        setSelectedFiles([]);
                                                                    }
                                                                }}
                                                                style={{ marginLeft: "9px" }} />
                                                        </div>
                                                        <div className="col-5 text-primary" style={{ padding: "0", paddingLeft: "11.6%" }} >
                                                            Name
                                                        </div>
                                                        <div className="col-2 text-primary" style={{ padding: "0", paddingLeft: "4.5%" }}>
                                                            Date
                                                        </div>
                                                        <div className="col-2 text-primary" style={{ padding: "0", paddingLeft: "12%" }}>
                                                            Size
                                                        </div>
                                                        <div className="col-1">
                                                        </div>
                                                    </div>
                                                    <div className="custom-scrollbar-wrapper" style={{ height: "85vh", borderRadius: "10px" }}>
                                                        <ul className="nav justify-content-center flex-column">


                                                            {parentfolder3 && parentfolder3.folder_name && (
                                                                <div>
                                                                    <div
                                                                        className="d-flex align-items-center border border-dashed p-2 rounded mt-2 file-item" style={{ height: "7vh" }}
                                                                        onDoubleClick={() => {
                                                                            showFilesInFolder(parentfolder3.parent_folder_id, 3, true);
                                                                        }}
                                                                        onDrop={(event) => changeDirectory(event, selectedFiles, { id: parentfolder3.parent_folder_id }, 3)}>
                                                                        <div className="flex-shrink-0 me-3">
                                                                            <div>
                                                                                <div className="rounded fs-24">
                                                                                    <FileIcon fileName={parentfolder3.folder_name} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="mb-1">
                                                                                <Link>
                                                                                    [..]
                                                                                </Link>
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}


                                                            {folder_3.map((folder_3R) => (
                                                                <React.Fragment key={folder_3R.id}>
                                                                    <ContextMenuTrigger
                                                                        id={`contextmenu-${folder_3R.id}`} holdToDisplay={-1}
                                                                    >
                                                                        <div
                                                                            className="d-flex align-items-center border border-dashed p-2 rounded mt-2 file-item" style={{ height: "7vh" }}
                                                                            onDoubleClick={() => {
                                                                                showFilesInFolder(folder_3R.id, 3);
                                                                            }}
                                                                            onDrop={(event) => changeDirectory(event, selectedFiles, folder_3R, 3)}
                                                                            onDragOver={() => {
                                                                                setOndropFolder(folder_3R.id);
                                                                            }}
                                                                            onDragLeave={() => {
                                                                                setOndropFolder(4);
                                                                            }}
                                                                            draggable
                                                                            onDragStart={(e) => {
                                                                                handleDragStart(e, selectedFiles.length === 0 ? [folder_3R] : selectedFiles, 3);

                                                                                if (selectedFiles.length === 0) {
                                                                                    const checkbox = document.getElementById(`checkbox-${folder_3R.id}`);
                                                                                    checkbox.checked = true;
                                                                                    handleCheckboxChange(folder_3R, true);
                                                                                }
                                                                            }}
                                                                            onDragEnd={() => {
                                                                                setDraggingFromPC(true);
                                                                                setTransferFile(false);
                                                                            }}
                                                                            onClick={(e) => {
                                                                                if (e.target.type !== 'checkbox') {
                                                                                    const checkbox = document.getElementById(`checkbox-${folder_3R.id}`);
                                                                                    checkbox.checked = !checkbox.checked;
                                                                                    handleCheckboxChange(folder_3R, checkbox.checked);
                                                                                }
                                                                            }}
                                                                            onContextMenu={(e) => {
                                                                                if (e.target.type !== 'checkbox' && selectedFiles.length < 2) {
                                                                                    const checkbox = document.getElementById(`checkbox-${folder_3R.id}`);
                                                                                    checkbox.checked = !checkbox.checked;
                                                                                    handleCheckboxChange(folder_3R, checkbox.checked);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div className="row w-100">
                                                                                <div className="col-1">
                                                                                    <div className="form-check" style={{ top: "8px" }}>
                                                                                        <input
                                                                                            id={`checkbox-${folder_3R.id}`}
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            onChange={(e) => handleCheckboxChange(folder_3R, e.target.checked)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-1">
                                                                                    <div className="flex-shrink-0 me-3">
                                                                                        <div>
                                                                                            <div className="rounded fs-24">
                                                                                                <FileIcon fileName={folder_3R.folder_name} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-5" style={{ padding: "0", paddingBlock: "8px" }}>
                                                                                    <div className="flex-grow-1 ms-3">
                                                                                        <h6 className="mb-1">
                                                                                            <Link>
                                                                                                {folder_3R.folder_name}
                                                                                            </Link>
                                                                                        </h6>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-3" style={{ padding: "0", paddingBlock: "8px" }}>
                                                                                    <small className="text-muted">
                                                                                        <p className="mb-0 ctext-content">{folder_3R.created_at}</p>
                                                                                    </small>
                                                                                </div>
                                                                                <div className="col-2" style={{ paddingBlock: "6px" }}>
                                                                                    <small className="text-muted">{convertSize(folder_3R.size)}</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ContextMenuTrigger>

                                                                    <ContextMenu id={`contextmenu-${folder_3R.id}`} style={{ zIndex: "50" }}>
                                                                        <div className="context-menu-container">
                                                                            <div className="context-menu">
                                                                                <Link className="menu-item" onClick={() => {
                                                                                    showFilesInFolder(folder_3R.id, 3);
                                                                                }}>
                                                                                    <i className="mdi mdi-arrow-top-right-bold-box-outline"></i>
                                                                                    <span className="mail-list-link p-2">Open</span>
                                                                                </Link>

                                                                                <Link className="menu-item" onClick={() => {
                                                                                    setFolderOBJ(folder_3R);
                                                                                    setStageType(3);
                                                                                    setUpdattingFolder(true);
                                                                                }}>
                                                                                    <i className="ri-edit-2-fill me-2 align-middle fw-medium"></i>
                                                                                    <span className="mail-list-link">Rename Folder</span>
                                                                                </Link>

                                                                                {selectedFiles.length > 0 ? (
                                                                                    <a className="menu-item" href="#!" onClick={() => deleteFileAndFolder(selectedFiles, 3, showFiles, showFilesInFolder, user2)}>
                                                                                        <i className="ri-delete-bin-4-fill" style={{ paddingRight: "10px", color: "red" }}></i>
                                                                                        <span className="mail-list-link text-danger">Delete selected files</span>
                                                                                    </a>
                                                                                ) : null}

                                                                                <Link className="menu-item"
                                                                                    onClick={() => {
                                                                                        downloadFolder(folder_3R.id, 3, folder_3R.task_id);
                                                                                    }}>
                                                                                    <i className="ri-download-2-line" style={{ paddingRight: "10px" }}></i>
                                                                                    <span className="mail-list-link">Download</span>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </ContextMenu>
                                                                </React.Fragment>
                                                            ))}

                                                            {file_3.map((file_3R) => (
                                                                <React.Fragment key={file_3R.id}>
                                                                    <ContextMenuTrigger id={`contextmenu3-${file_3R.file_name}`} holdToDisplay={-1}>
                                                                        <div className="d-flex align-items-center border border-dashed p-2 rounded mt-2 file-item"
                                                                            draggable
                                                                            onDragStart={(e) => {
                                                                                handleDragStart(e, selectedFiles.length === 0 ? [file_3R] : selectedFiles, 3);

                                                                                if (selectedFiles.length === 0) {
                                                                                    const checkbox = document.getElementById(`checkbox-${file_3R.id}`);
                                                                                    checkbox.checked = true;
                                                                                    handleCheckboxChange(file_3R, true);
                                                                                }
                                                                            }}
                                                                            onDragEnd={() => {
                                                                                setDraggingFromPC(true);
                                                                                setTransferFile(false);
                                                                            }} style={{ height: "7vh" }}
                                                                            onDoubleClick={() => {
                                                                                const backendFilePath = `${server_backend}/${file_3R.file_path}`;
                                                                                window.open(backendFilePath, '_blank');
                                                                            }}
                                                                            onClick={(e) => {
                                                                                if (e.target.type !== 'checkbox') {
                                                                                    const checkbox = document.getElementById(`checkbox-${file_3R.id}`);
                                                                                    checkbox.checked = !checkbox.checked;
                                                                                    handleCheckboxChange(file_3R, checkbox.checked);
                                                                                }
                                                                            }}
                                                                            onContextMenu={(e) => {
                                                                                if (e.target.type !== 'checkbox' && selectedFiles.length < 2) {
                                                                                    const checkbox = document.getElementById(`checkbox-${file_3R.id}`);
                                                                                    checkbox.checked = !checkbox.checked;
                                                                                    handleCheckboxChange(file_3R, checkbox.checked);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div className="row w-100">
                                                                                <div className="col-1">
                                                                                    <div className="form-check" style={{ top: "12px" }}>
                                                                                        <input
                                                                                            id={`checkbox-${file_3R.id}`}
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            onChange={(e) => handleCheckboxChange(file_3R, e.target.checked)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-1">
                                                                                    <div className="flex-shrink-0 me-3">
                                                                                        <div className="avatar-sm" style={{ width: "2em", height: "2em" }}>
                                                                                            <div className="avatar-title bg-light text-secondary rounded fs-24">
                                                                                                <FileIcon fileName={file_3R.file_name} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-5" style={{ padding: "0" }}>
                                                                                    <div className="flex-grow-1 ms-3">
                                                                                        <p className="mb-1">
                                                                                            <Link
                                                                                                onClick={() => {
                                                                                                    const backendFilePath = `${server_backend}/${file_3R.file_path}`;
                                                                                                    const fileNameWithoutSpaces = file_3R.file_name.replace(/\s/g, '');
                                                                                                    saveAs(backendFilePath, fileNameWithoutSpaces);
                                                                                                }}
                                                                                                draggable="false"
                                                                                            >
                                                                                                {file_3R.file_name}
                                                                                            </Link>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-3" style={{ padding: "0", paddingBlock: "2px" }}>
                                                                                    <small className="text-muted">
                                                                                        <p className="mb-0 ctext-content">{file_3R.created_at}</p>
                                                                                    </small>
                                                                                </div>
                                                                                <div className="col-2">
                                                                                    <small className="text-muted">{convertSize(file_3R.size)}</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ContextMenuTrigger>

                                                                    <ContextMenu id={`contextmenu3-${file_3R.file_name}`} style={{ zIndex: "50" }}>
                                                                        <div className="context-menu-container">
                                                                            <div className="context-menu">
                                                                                <Link className="menu-item" onClick={() => {
                                                                                    window.open(file_3R.file_path, '_blank');
                                                                                }}>
                                                                                    <i className="mdi mdi-arrow-top-right-bold-box-outline"></i>
                                                                                    <span className="mail-list-link p-2">Open</span>
                                                                                </Link>

                                                                                <button className="menu-item" onClick={() => {
                                                                                    handleCopyAndPaste(selectedFiles, 3);
                                                                                    hideMenu();
                                                                                }}>
                                                                                    <i className="ri-edit-2-fill me-2 align-middle fw-medium"></i>
                                                                                    <span className="mail-list-link">Copy</span>
                                                                                </button>

                                                                                <Link className="menu-item" onClick={() => {
                                                                                    setUpdatting(true);
                                                                                    setFileObject(file_3R, closeModalFile);
                                                                                    setStageType(3);
                                                                                }}>
                                                                                    <i className="ri-edit-2-fill me-2 align-middle fw-medium"></i>
                                                                                    <span className="mail-list-link">Rename</span>
                                                                                </Link>

                                                                                <Link className="menu-item" onClick={() => {
                                                                                    const name = file_3R.file_name;
                                                                                    const name1 = name.replace(/\s/g, '');
                                                                                    saveAs(file_3R.file_path, name1);
                                                                                }}>
                                                                                    <i className="ri-download-2-line" style={{ paddingRight: "10px" }}></i>
                                                                                    <span className="mail-list-link">Download</span>
                                                                                </Link>

                                                                                {selectedFiles.length > 0 ? (
                                                                                    <a className="menu-item" href="#!" onClick={(event) => {
                                                                                        event.preventDefault();
                                                                                        deleteFileAndFolder(selectedFiles, 3, showFiles, showFilesInFolder, user2);
                                                                                    }}>
                                                                                        <i className="ri-delete-bin-4-fill" style={{ paddingRight: "10px", color: "red" }}></i>
                                                                                        <span className="mail-list-link text-danger">{selectedFiles.length > 1 ? "Delete selected files" : "Delete"}</span>
                                                                                    </a>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                    </ContextMenu>
                                                                </React.Fragment>
                                                            ))}

                                                        </ul>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={{ maxHeight: "279px", minHeight: "279px", borderRadius: "10px" }}>
                                                    <div className="cardbody">
                                                        <h5 className="card-title placeholder-glow p-2">
                                                            <span className="placeholder col-6"></span>
                                                        </h5>
                                                    </div>
                                                    <div className="p-2">
                                                        <p className="card-text placeholder-glow">
                                                            <span className="placeholder col-7"></span>
                                                            <span className="placeholder col-4"></span>
                                                            <span className="placeholder col-4"></span>
                                                            <span className="placeholder col-6"></span>
                                                        </p>
                                                    </div>
                                                </div>
                                            )}

                                        </div>
                                    ) : (
                                        <div className="card">
                                            <div className="card-body">
                                                <h5>Upload Files</h5>
                                                <div className="custom-scrollbar-wrapper" style={{ height: "85vh", borderRadius: "10px" }} onDragOver={handleDragOver3} onDrop={handleDrop}>
                                                    <div className="custom-file-input">
                                                        <input id="fileInput" type="file" multiple webkitdirectory="true" onChange={handleFileSelect} />
                                                        <h1 className=" fw-bold d-flex justify-content-center align-items-center">Drag the file here</h1>
                                                    </div>
                                                    <ul class="list-unstyled chat-list chat-user-list" id="userList">
                                                        {selectedFiles.map((file, index) => (
                                                            <li key={index}>
                                                                <Link>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                                                            <div className="avatar-xxs">
                                                                                <FileIcon fileName={file.name} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 overflow-hidden">
                                                                            <p className="text-truncate mb-0">{file.name}</p>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <center>
                                                    <button className="btn btn-danger" onClick={() => {
                                                        setIsDragging3(false)
                                                    }}>Close </button> |   <button className="btn btn-success w-50" onClick={(event) => {
                                                        TaskInsertFile(event, inStage, 3, parentfolder3.id);
                                                        setSelectedFiles([]);
                                                    }}>Upload files</button>
                                                </center>
                                            </div>
                                        </div>
                                    )}

                                </div>
                            </ContextMenuTrigger>
                            <ContextMenu id="face3" style={{ zIndex: "50" }}>
                                <div className="context-menu-container">
                                    <div className="context-menu">
                                        <button className="menu-item" onClick={() => {
                                            setshowFolderModal(true);
                                            setFolderFace(3)
                                        }}>
                                            <i className="mdi mdi-arrow-top-right-bold-box-outline"></i>
                                            <span className="mail-list-link p-2">Create Folder</span>
                                        </button>
                                        {transferFile && (
                                            <button className="menu-item" onClick={(event) => {
                                                receiveCopiedFile(event, currentFileTo, 3);
                                                hideMenu();
                                            }}>
                                                <i className="mdi mdi-arrow-top-right-bold-box-outline"></i>
                                                <span className="mail-list-link p-2">Paste</span>
                                            </button>
                                        )}

                                        <Link className="menu-item">
                                            <i className="ri-edit-2-fill me-2 align-middle fw-medium"></i>
                                            <span className="mail-list-link">Upload Files</span>
                                        </Link>
                                    </div>
                                </div>
                            </ContextMenu>
                        </div>
                        <div className="col-md-6" style={{ padding: "0" }}>
                            <ContextMenuTrigger id="face4" holdToDisplay={-1}>
                                <div
                                    onDragOver={handleDragOver4}
                                    onDragLeave={handleDragLeave4}
                                    onDrop={transferFile === true ? (event) => chageFileFace(event, currentFileTo, fileOnface) : undefined}>
                                    {!onDraggin4 ? (
                                        <div className="card" id="card-face4">
                                            {!loadingface ? (
                                                <div className="card-body">

                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <h5 className="fw-semibold">
                                                                {nextBeforeStg.nextStage ? nextBeforeStg.nextStage : "no stage selected"}
                                                            </h5>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <div className="d-flex gap-1 align-items-center">
                                                                <div className="dropdown">
                                                                    <button className="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                                        <i class="bx bx-dots-horizontal-rounded"></i>
                                                                    </button>

                                                                    <div className="dropdown-menu dropdown-menu-end">
                                                                        <Link className="dropdown-item"
                                                                            onClick={() => {
                                                                                setIsDragging4(true)
                                                                            }}><i className="ri-eye-fill align-bottom me-2 text-muted"></i> Upload Files</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-1">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="checkAll"
                                                                value="option"
                                                                onChange={(e) => {
                                                                    const isChecked = e.target.checked;

                                                                    file_4.map((item) => {
                                                                        const checkbox = document.getElementById(`checkbox-${item.id}`);
                                                                        if (checkbox) {
                                                                            checkbox.checked = isChecked;
                                                                            handleCheckboxChange(item, isChecked);
                                                                        }
                                                                    });

                                                                    folder_4.map((item) => {
                                                                        const checkbox = document.getElementById(`checkbox-${item.id}`);
                                                                        if (checkbox) {
                                                                            checkbox.checked = isChecked;
                                                                            handleCheckboxChange(item, isChecked);
                                                                        }
                                                                    });

                                                                    if (!isChecked) {
                                                                        e.target.checked = false;
                                                                        setSelectedFiles([]);
                                                                    }
                                                                }}
                                                                style={{ marginLeft: "9px" }} />
                                                        </div>
                                                        <div className="col-5 text-primary" style={{ padding: "0", paddingLeft: "11.6%" }} >
                                                            Name
                                                        </div>
                                                        <div className="col-2 text-primary" style={{ padding: "0", paddingLeft: "4.5%" }}>
                                                            Date
                                                        </div>
                                                        <div className="col-2 text-primary" style={{ padding: "0", paddingLeft: "12%" }}>
                                                            Size
                                                        </div>
                                                        <div className="col-1">
                                                        </div>
                                                    </div>

                                                    <div className="custom-scrollbar-wrapper" style={{ height: "85vh", borderRadius: "10px" }}>
                                                        <ul className="nav justify-content-center flex-column">


                                                            {parentfolder4 && parentfolder4.folder_name && (
                                                                <div>
                                                                    <div
                                                                        className="d-flex align-items-center border border-dashed p-2 rounded mt-2 file-item" style={{ height: "7vh" }}
                                                                        onDoubleClick={() => {
                                                                            showFilesInFolder(parentfolder4.parent_folder_id, 4, true);
                                                                        }}
                                                                        onDrop={(event) => changeDirectory(event, selectedFiles, { id: parentfolder4.parent_folder_id }, 4)}>
                                                                        <div className="flex-shrink-0 me-3">
                                                                            <div>
                                                                                <div className="rounded fs-24">
                                                                                    <FileIcon fileName={parentfolder4.folder_name} />
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="mb-1">
                                                                                <Link>
                                                                                    [..]
                                                                                </Link>
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {folder_4.map((folder_4R) => (
                                                                <React.Fragment key={folder_4R.id}>
                                                                    <ContextMenuTrigger
                                                                        id={`contextmenu-${folder_4R.id}`} holdToDisplay={-1}
                                                                    >
                                                                        <div
                                                                            className="d-flex align-items-center border border-dashed p-2 rounded mt-2 file-item" style={{ height: "7vh" }}
                                                                            onDoubleClick={() => {
                                                                                showFilesInFolder(folder_4R.id, 4);
                                                                            }}
                                                                            onDrop={(event) => changeDirectory(event, selectedFiles, folder_4R, 4)}
                                                                            onDragOver={() => {
                                                                                setOndropFolder(folder_4R.id);
                                                                            }}
                                                                            onDragLeave={() => {
                                                                                setOndropFolder(4);
                                                                            }}
                                                                            draggable
                                                                            onDragStart={(e) => {
                                                                                handleDragStart(e, selectedFiles.length === 0 ? [folder_4R] : selectedFiles, 4);

                                                                                if (selectedFiles.length === 0) {
                                                                                    const checkbox = document.getElementById(`checkbox-${folder_4R.id}`);
                                                                                    checkbox.checked = true;
                                                                                    handleCheckboxChange(folder_4R, true);
                                                                                }
                                                                            }}
                                                                            onDragEnd={() => {
                                                                                setDraggingFromPC(true);
                                                                                setTransferFile(false);
                                                                            }}
                                                                            onClick={(e) => {
                                                                                if (e.target.type !== 'checkbox') {
                                                                                    const checkbox = document.getElementById(`checkbox-${folder_4R.id}`);
                                                                                    checkbox.checked = !checkbox.checked;
                                                                                    handleCheckboxChange(folder_4R, checkbox.checked);
                                                                                }
                                                                            }}

                                                                            onContextMenu={(e) => {
                                                                                if (e.target.type !== 'checkbox' && selectedFiles.length < 2) {
                                                                                    const checkbox = document.getElementById(`checkbox-${folder_4R.id}`);
                                                                                    checkbox.checked = !checkbox.checked;
                                                                                    handleCheckboxChange(folder_4R, checkbox.checked);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div className="row w-100">
                                                                                <div className="col-1">
                                                                                    <div className="form-check" style={{ top: "8px" }}>
                                                                                        <input
                                                                                            id={`checkbox-${folder_4R.id}`}
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            onChange={(e) => handleCheckboxChange(folder_4R, e.target.checked)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-1">
                                                                                    <div className="flex-shrink-0 me-3">
                                                                                        <div>
                                                                                            <div className="rounded fs-24">
                                                                                                <FileIcon fileName={folder_4R.folder_name} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-5" style={{ padding: "0", paddingBlock: "8px" }}>
                                                                                    <div className="flex-grow-1 ms-3">
                                                                                        <h6 className="mb-1">
                                                                                            <Link>
                                                                                                {folder_4R.folder_name}
                                                                                            </Link>
                                                                                        </h6>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-3" style={{ padding: "0", paddingBlock: "8px" }}>
                                                                                    <small className="text-muted">
                                                                                        <p className="mb-0 ctext-content">{folder_4R.created_at}</p>
                                                                                    </small>
                                                                                </div>
                                                                                <div className="col-2" style={{ paddingBlock: "6px" }}>
                                                                                    <small className="text-muted">{convertSize(folder_4R.size)}</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ContextMenuTrigger>

                                                                    <ContextMenu id={`contextmenu-${folder_4R.id}`} style={{ zIndex: "50" }}>
                                                                        <div className="context-menu-container">
                                                                            <div className="context-menu">
                                                                                <Link className="menu-item" onClick={() => {
                                                                                    showFilesInFolder(folder_4R.id, 4);
                                                                                }}>
                                                                                    <i className="mdi mdi-arrow-top-right-bold-box-outline"></i>
                                                                                    <span className="mail-list-link p-2">Open</span>
                                                                                </Link>

                                                                                <Link className="menu-item" onClick={() => {
                                                                                    setFolderOBJ(folder_4R);
                                                                                    setStageType(4);
                                                                                    setUpdattingFolder(true);
                                                                                }}>
                                                                                    <i className="ri-edit-2-fill me-2 align-middle fw-medium"></i>
                                                                                    <span className="mail-list-link">Rename Folder</span>
                                                                                </Link>

                                                                                {selectedFiles.length > 0 ? (
                                                                                    <a className="menu-item" href="#!" onClick={() => deleteFileAndFolder(selectedFiles, 4, showFiles, showFilesInFolder, user2)}>
                                                                                        <i className="ri-delete-bin-4-fill" style={{ paddingRight: "10px", color: "red" }}></i>
                                                                                        <span className="mail-list-link text-danger">Delete selected files</span>
                                                                                    </a>
                                                                                ) : null}

                                                                                <Link className="menu-item"
                                                                                    onClick={() => {
                                                                                        downloadFolder(folder_4R.id, 4, folder_4R.task_id);
                                                                                    }}>
                                                                                    <i className="ri-download-2-line" style={{ paddingRight: "10px" }}></i>
                                                                                    <span className="mail-list-link">Download</span>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                    </ContextMenu>
                                                                </React.Fragment>
                                                            ))}


                                                            {file_4.map((file_4R) => (
                                                                <React.Fragment key={file_4R.id}>
                                                                    <ContextMenuTrigger id={`contextmenu4-${file_4R.file_name}`} holdToDisplay={-1}>
                                                                        <div className="d-flex align-items-center border border-dashed p-2 rounded mt-2 file-item"
                                                                            draggable
                                                                            onDragStart={(e) => {
                                                                                handleDragStart(e, selectedFiles.length === 0 ? [file_4R] : selectedFiles, 4);

                                                                                if (selectedFiles.length === 0) {
                                                                                    const checkbox = document.getElementById(`checkbox-${file_4R.id}`);
                                                                                    checkbox.checked = true;
                                                                                    handleCheckboxChange(file_4R, true);
                                                                                }
                                                                            }}
                                                                            onDragEnd={() => {
                                                                                setDraggingFromPC(true);
                                                                                setTransferFile(false);
                                                                            }}
                                                                            style={{ height: "7vh" }}
                                                                            onDoubleClick={() => {
                                                                                const backendFilePath = `${server_backend}/${file_4R.file_path}`;
                                                                                window.open(backendFilePath, '_blank');
                                                                            }}
                                                                            onClick={(e) => {
                                                                                if (e.target.type !== 'checkbox') {
                                                                                    const checkbox = document.getElementById(`checkbox-${file_4R.id}`);
                                                                                    checkbox.checked = !checkbox.checked;
                                                                                    handleCheckboxChange(file_4R, checkbox.checked);
                                                                                }
                                                                            }}

                                                                            onContextMenu={(e) => {
                                                                                if (e.target.type !== 'checkbox' && selectedFiles.length < 2) {
                                                                                    const checkbox = document.getElementById(`checkbox-${file_4R.id}`);
                                                                                    checkbox.checked = !checkbox.checked;
                                                                                    handleCheckboxChange(file_4R, checkbox.checked);
                                                                                }
                                                                            }}
                                                                        >
                                                                            <div className="row w-100">
                                                                                <div className="col-1">
                                                                                    <div className="form-check" style={{ top: "12px" }}>
                                                                                        <input
                                                                                            id={`checkbox-${file_4R.id}`}
                                                                                            className="form-check-input"
                                                                                            type="checkbox"
                                                                                            onChange={(e) => handleCheckboxChange(file_4R, e.target.checked)}
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-1">
                                                                                    <div className="flex-shrink-0 me-3">
                                                                                        <div className="avatar-sm" style={{ width: "2em", height: "2em" }}>
                                                                                            <div className="avatar-title bg-light text-secondary rounded fs-24">
                                                                                                <FileIcon fileName={file_4R.file_name} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-5" style={{ padding: "0" }}>
                                                                                    <div className="flex-grow-1 ms-3">
                                                                                        <p className="mb-1">
                                                                                            <Link
                                                                                                onClick={() => {
                                                                                                    const backendFilePath = `${server_backend}/${file_4R.file_path}`;
                                                                                                    const fileNameWithoutSpaces = file_4R.file_name.replace(/\s/g, '');
                                                                                                    saveAs(backendFilePath, fileNameWithoutSpaces);
                                                                                                }}
                                                                                                draggable="false"
                                                                                            >
                                                                                                {file_4R.file_name}
                                                                                            </Link>
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-3" style={{ padding: "0", paddingBlock: "2px" }}>
                                                                                    <small className="text-muted">
                                                                                        <p className="mb-0 ctext-content">{file_4R.created_at}</p>
                                                                                    </small>
                                                                                </div>
                                                                                <div className="col-2">
                                                                                    <small className="text-muted">{convertSize(file_4R.size)}</small>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ContextMenuTrigger>

                                                                    <ContextMenu id={`contextmenu4-${file_4R.file_name}`} style={{ zIndex: "50" }}>
                                                                        <div className="context-menu-container">
                                                                            <div className="context-menu">
                                                                                <Link className="menu-item" onClick={() => {
                                                                                    window.open(file_4R.file_path, '_blank');
                                                                                }}>
                                                                                    <i className="mdi mdi-arrow-top-right-bold-box-outline"></i>
                                                                                    <span className="mail-list-link p-2">Open</span>
                                                                                </Link>

                                                                                <button className="menu-item" onClick={() => {
                                                                                    handleCopyAndPaste(selectedFiles, 4);
                                                                                    hideMenu();
                                                                                }}>
                                                                                    <i className="ri-edit-2-fill me-2 align-middle fw-medium"></i>
                                                                                    <span className="mail-list-link">Copy</span>
                                                                                </button>

                                                                                <Link className="menu-item" onClick={() => {
                                                                                    setUpdatting(true);
                                                                                    setFileObject(file_4R, closeModalFile);
                                                                                    setStageType(4);
                                                                                }}>
                                                                                    <i className="ri-edit-2-fill me-2 align-middle fw-medium"></i>
                                                                                    <span className="mail-list-link">Rename</span>
                                                                                </Link>
                                                                                <Link className="menu-item" onClick={() => {
                                                                                    const name = file_4R.file_name;
                                                                                    const name1 = name.replace(/\s/g, '');
                                                                                    saveAs(file_4R.file_path, name1);
                                                                                }}>
                                                                                    <i className="ri-download-2-line" style={{ paddingRight: "10px" }}></i>
                                                                                    <span className="mail-list-link">Download</span>
                                                                                </Link>
                                                                                {selectedFiles.length > 0 ? (
                                                                                    <a className="menu-item" href="#!" onClick={(event) => {
                                                                                        event.preventDefault();
                                                                                        deleteFileAndFolder(selectedFiles, 4, showFiles, showFilesInFolder, user2);
                                                                                    }}>
                                                                                        <i className="ri-delete-bin-4-fill" style={{ paddingRight: "10px", color: "red" }}></i>
                                                                                        <span className="mail-list-link text-danger">{selectedFiles.length > 1 ? "Delete selected files" : "Delete"}</span>
                                                                                    </a>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                    </ContextMenu>
                                                                </React.Fragment>
                                                            ))}

                                                        </ul>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div style={{ maxHeight: "279px", minHeight: "279px", borderRadius: "10px" }}>
                                                    <div className="cardbody">
                                                        <h5 className="card-title placeholder-glow p-2">
                                                            <span className="placeholder col-6"></span>
                                                        </h5>
                                                    </div>
                                                    <div className="p-2">
                                                        <p className="card-text placeholder-glow">
                                                            <span className="placeholder col-7"></span>
                                                            <span className="placeholder col-4"></span>
                                                            <span className="placeholder col-4"></span>
                                                            <span className="placeholder col-6"></span>
                                                        </p>
                                                    </div>
                                                </div>
                                            )}

                                        </div>

                                    ) : (
                                        <div className="card">
                                            <div className="card-body">
                                                <h5>Upload Files</h5>
                                                <div className="custom-scrollbar-wrapper" style={{ height: "85vh", borderRadius: "10px" }} onDragOver={handleDragOver4} onDrop={handleDrop}>
                                                    <div className="custom-file-input">
                                                        <input id="fileInput" type="file" multiple webkitdirectory="true" onChange={handleFileSelect} />
                                                        <h1 className=" fw-bold d-flex justify-content-center align-items-center">Drag the file here</h1>
                                                    </div>
                                                    <ul class="list-unstyled chat-list chat-user-list" id="userList">
                                                        {selectedFiles.map((file, index) => (
                                                            <li key={index}>
                                                                <Link>
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                                                            <div className="avatar-xxs">
                                                                                <FileIcon fileName={file.name} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 overflow-hidden">
                                                                            <p className="text-truncate mb-0">{file.name}</p>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                                <center>
                                                    <button className="btn btn-danger" onClick={() => {
                                                        setIsDragging4(false)
                                                    }}>Close </button> |
                                                    <button className="btn btn-success w-50" onClick={(event) => {
                                                        TaskInsertFile(event, inStage, 4, parentfolder1.id);
                                                        setSelectedFiles([]);
                                                    }}>Upload files</button>
                                                </center>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </ContextMenuTrigger>
                            <ContextMenu id="face4" style={{ zIndex: "50" }}>
                                <div className="context-menu-container">
                                    <div className="context-menu">
                                        <button className="menu-item" onClick={() => {
                                            setshowFolderModal(true);
                                            setFolderFace(4)
                                        }}>
                                            <i className="mdi mdi-arrow-top-right-bold-box-outline"></i>
                                            <span className="mail-list-link p-2">Create Folder</span>
                                        </button>

                                        {transferFile && (
                                            <button className="menu-item" onClick={(event) => {
                                                receiveCopiedFile(event, currentFileTo, 4);
                                                hideMenu();
                                            }}>
                                                <i className="mdi mdi-arrow-top-right-bold-box-outline"></i>
                                                <span className="mail-list-link p-2">Paste</span>
                                            </button>
                                        )}

                                        <Link className="menu-item">
                                            <i className="ri-edit-2-fill me-2 align-middle fw-medium"></i>
                                            <span className="mail-list-link">Upload Files</span>
                                        </Link>
                                    </div>
                                </div>
                            </ContextMenu>
                        </div>
                        <div className="card col-12 text-center" style={{ height: "25%" }}>
                            <div className="card">
                                <div className="card-header" >
                                    <h4 className="text-primary">BENCH</h4>
                                </div>
                                <div className="fc-toolbar-chunk">
                                    {inStage == false ? (
                                        <p>You must select a stage to view the BENCH.</p>
                                    ) : (
                                        <div className="btn-group">
                                            <div className="p-3">
                                                <button
                                                    type="button"
                                                    aria-pressed="false"
                                                    className="fc-timeGridWeek-button btn btn-primary btn-lg Zbutton-stage"
                                                    onClick={() => getFileContainer(inStage.TaskID, 1, inStage.TaskName)}>
                                                    C 1
                                                </button>
                                            </div>
                                            <div className="p-3">
                                                <button
                                                    type="button"
                                                    aria-pressed="false"
                                                    className="fc-timeGridWeek-button btn btn-primary btn-lg Zbutton-stage"
                                                    onClick={() => getFileContainer(inStage.TaskID, 2, inStage.TaskName)}>
                                                    C 2
                                                </button>
                                            </div>
                                            <div className="p-3">
                                                <button
                                                    type="button"
                                                    aria-pressed="false"
                                                    className="fc-timeGridWeek-button btn btn-primary btn-lg Zbutton-stage"
                                                    onClick={() => getFileContainer(inStage.TaskID, 3, inStage.TaskName)}>
                                                    C 3
                                                </button>
                                            </div>
                                            <div className="p-3">
                                                <button
                                                    type="button"
                                                    aria-pressed="false"
                                                    className="fc-timeGridWeek-button btn btn-primary btn-lg Zbutton-stage"
                                                    onClick={() => getFileContainer(inStage.TaskID, 4, inStage.TaskName)}>
                                                    C 4
                                                </button>
                                            </div>
                                            <div className="p-3">
                                                <button
                                                    type="button"
                                                    aria-pressed="false"
                                                    className="fc-timeGridWeek-button btn btn-primary btn-lg Zbutton-stage"
                                                    onClick={() => getFileContainer(inStage.TaskID, 5, inStage.TaskName)}>
                                                    C 5
                                                </button>
                                            </div>
                                            <div className="p-3">
                                                <button
                                                    type="button"
                                                    aria-pressed="false"
                                                    className="fc-timeGridWeek-button btn btn-primary btn-lg Zbutton-stage"
                                                    onClick={() => getFileContainer(inStage.TaskID, 6, inStage.TaskName)}>
                                                    C 6
                                                </button>
                                            </div>
                                            <div className="p-3">
                                                <button
                                                    type="button"
                                                    aria-pressed="false"
                                                    className="fc-timeGridWeek-button btn btn-primary btn-lg Zbutton-stage"
                                                    onClick={() => getFileContainer(inStage.TaskID, 7, inStage.TaskName)}>
                                                    C 7
                                                </button>
                                            </div>
                                            <div className="p-3">
                                                <button
                                                    type="button"
                                                    aria-pressed="false"
                                                    className="fc-timeGridWeek-button btn btn-primary btn-lg Zbutton-stage"
                                                    onClick={() => getFileContainer(inStage.TaskID, 8, inStage.TaskName)}>
                                                    C 8
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                showModal && (
                    <div className="modal fade bs-example-modal-xl show azoomOutDown" tabIndex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel2" aria-modal="true" style={{ display: "block", backgroundColor: "#2e659385" }}>
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header bg-soft-info p-3">
                                    <h5 className="modal-title" id="exampleModalLabel"></h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModal} data-bs-dismiss="modal" aria-label="Close" id="close-modal"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="tab-pane fade show active" id="pills-bill-info" role="tabpanel" aria-labelledby="pills-bill-info-tab">
                                        <div className="col-12">
                                            <div className="row">
                                                <div className="col-5">
                                                    <div className="fc-header-toolbar fc-toolbar fc-toolbar-ltr">
                                                        <div className="fc-toolbar-chunk">
                                                            <div className="btn-group">
                                                                <button
                                                                    type="button"
                                                                    className="fc-prev-button btn btn-primary"
                                                                    onClick={() => {
                                                                        //alert(onBranch_M)
                                                                        if (onBranch_M > 1) {
                                                                            if (onBranch_M > 4) {
                                                                                const newBranch = onBranch_M - 1;
                                                                                setOnBranch_M(newBranch);
                                                                                showFilesMapping(lastFolderBranch_M.parentFolder, lastFolderBranch_M.table_code, lastFolderBranch_M.taskID, newBranch);
                                                                            } else if (onBranch_M <= 4) {
                                                                                const newBranch = onBranch_M - 1;
                                                                                setOnBranch_M(newBranch);
                                                                                Mapping_S(currentStage_M, newBranch, currentCategory);
                                                                            }
                                                                        }
                                                                    }
                                                                    }
                                                                >
                                                                </button>

                                                                <button type="button" title="Next month" aria-pressed="false" className="fc-next-button btn btn-primary">
                                                                    <span className="fa fa-chevron-right"></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <h2 className="card-title mb-0 flex-grow-1">Categories</h2>
                                                </div>
                                            </div>

                                            <div>
                                                {loadingMapping ? (
                                                    <div>
                                                        <div className="d-flex justify-content-center align-items-center" style={{ height: "57vh" }}>
                                                            <div className="spinner-border text-primary" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="custom-scrollbar-wrapper" style={{ maxHeight: "57vh", minHeight: "57vh", overflowX: "hidden", overflowY: "auto" }}>
                                                        {onBranch_M === 3 ? (
                                                            <div className="row row-cols-4 g-6">
                                                                {GFolderBranch3.map((foldersB3) => (
                                                                    <Link onDoubleClick={() => {
                                                                        const newBranch = onBranch_M + 1;
                                                                        setOnBranch_M(newBranch);
                                                                        showFilesMapping(4, foldersB3.table_code, foldersB3.task_id, newBranch);
                                                                    }}>
                                                                        <div className="card" style={{ width: "29vh" }}>
                                                                            <div className="card-body text-center p-2 folder-column">
                                                                                <div className="rounded fs-24">
                                                                                    <FileIcon fileName={foldersB3.folder_name} />
                                                                                </div>
                                                                                <p className="mb-1 mt-2 fs-12">
                                                                                    {foldersB3.folder_name}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                ))}
                                                            </div>
                                                        ) : onBranch_M === 4 || onBranch_M === 5 ? (
                                                            <div className="row row-cols-4 g-4">
                                                                {GFolderBranch4.map((foldersB4) => (
                                                                    <div>
                                                                        <ContextMenuTrigger id={`mapping-branch-${foldersB4.id}`} holdToDisplay={-1}>
                                                                            <Link className="col" onDoubleClick={() => {

                                                                                const newBranch = onBranch_M + 1;
                                                                                setOnBranch_M(newBranch);

                                                                                showFilesMapping(foldersB4.id, foldersB4.table_code, foldersB4.task_id, newBranch);
                                                                            }}>
                                                                                <div className="card ribbon-box right overflow-hidden">
                                                                                    <div className="card-body text-center p-2 folder-column">
                                                                                        <div className="rounded fs-24">
                                                                                            <FileIcon fileName={foldersB4.folder_name} />
                                                                                        </div>
                                                                                        <p className="mb-1 mt-2 fs-12">
                                                                                            {foldersB4.folder_name}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        </ContextMenuTrigger>
                                                                        <ContextMenu id={`mapping-branch-${foldersB4.id}`} style={{ zIndex: "50" }}>
                                                                            <div className="context-menu-container">
                                                                                <div className="context-menu">
                                                                                    <Link className="menu-item" onClick={() => {

                                                                                        const newBranch = onBranch_M + 1;
                                                                                        setOnBranch_M(newBranch);

                                                                                        showFilesMapping(foldersB4.id, foldersB4.table_code, foldersB4.task_id, newBranch);
                                                                                    }}>
                                                                                        <i className="mdi mdi-arrow-top-right-bold-box-outline"></i>
                                                                                        <span className="mail-list-link p-2">Open</span>
                                                                                    </Link>

                                                                                    <Link className="menu-item" onClick={() => {
                                                                                        setFolderOBJ(foldersB4);
                                                                                        foldersB4.table_code === 9 ? setStageType(1) :
                                                                                            (foldersB4.table_code === 10 ? setStageType(2) :
                                                                                                (foldersB4.table_code === 11 ? setStageType(3) :
                                                                                                    setStageType(4)))

                                                                                        setUpdattingFolder(true);
                                                                                    }}>
                                                                                        <i className="ri-edit-2-fill me-2 align-middle fw-medium"></i>
                                                                                        <span className="mail-list-link">Rename Folder</span>
                                                                                    </Link>
                                                                                    <Link className="menu-item">
                                                                                        <i className="ri-download-2-line" style={{ paddingRight: "10px" }}></i>
                                                                                        <span className="mail-list-link">Download</span>
                                                                                    </Link>
                                                                                    <button className="menu-item">
                                                                                        <i className="ri-delete-bin-4-fill" style={{ paddingRight: "10px", color: "red" }}></i>
                                                                                        <span className="mail-list-link text-danger">Delete</span>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </ContextMenu>
                                                                    </div>
                                                                ))}

                                                                {GFilesBranch4.map((filesB4) => (
                                                                    <div>
                                                                        <ContextMenuTrigger id={`mapping-branch4-files-${filesB4.id}`} holdToDisplay={-1}>
                                                                            <Link className="col" onDoubleClick={() => {
                                                                                const backendFilePath = `${server_backend}/${filesB4.file_path}`;
                                                                                window.open(backendFilePath, '_blank');
                                                                            }}>
                                                                                <div className="card ribbon-box right overflow-hidden">
                                                                                    <div className="card-body text-center p-2 folder-column">
                                                                                        <div className="rounded fs-24">
                                                                                            <FileIcon fileName={filesB4.file_name} />
                                                                                        </div>
                                                                                        <p className="mb-1 mt-2 fs-12">
                                                                                            {filesB4.file_name}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        </ContextMenuTrigger>
                                                                        <ContextMenu id={`mapping-branch4-files-${filesB4.id}`} style={{ zIndex: "50" }}>
                                                                            <div className="context-menu-container">
                                                                                <div className="context-menu">
                                                                                    <Link className="menu-item"
                                                                                        onClick={() => {
                                                                                            window.open(filesB4.file_path, '_blank');
                                                                                        }}>
                                                                                        <i className="mdi mdi-arrow-top-right-bold-box-outline"></i>
                                                                                        <span className="mail-list-link p-2">Open</span>
                                                                                    </Link>

                                                                                    <Link className="menu-item" onClick={() => {
                                                                                        setUpdatting(true);
                                                                                        setFileObject(filesB4, closeModalFile);

                                                                                        filesB4.table_code === 9 ? setStageType(1) :
                                                                                            (filesB4.table_code === 10 ? setStageType(2) :
                                                                                                (filesB4.table_code === 11 ? setStageType(3) :
                                                                                                    setStageType(4)))
                                                                                    }}>
                                                                                        <i className="ri-edit-2-fill me-2 align-middle fw-medium"></i>
                                                                                        <span className="mail-list-link">Rename file</span>
                                                                                    </Link>
                                                                                    <Link className="menu-item"
                                                                                        onClick={() => {
                                                                                            const name = filesB4.file_name;
                                                                                            const name1 = name.replace(/\s/g, '');
                                                                                            saveAs(filesB4.file_path, name1);
                                                                                        }}>
                                                                                        <i className="ri-download-2-line" style={{ paddingRight: "10px" }}></i>
                                                                                        <span className="mail-list-link">Download</span>
                                                                                    </Link>
                                                                                    <button className="menu-item">
                                                                                        <i className="ri-delete-bin-4-fill" style={{ paddingRight: "10px", color: "red" }}></i>
                                                                                        <span className="mail-list-link text-danger">Delete</span>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </ContextMenu>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        ) : onBranch_M === 2 ? (

                                                            <div className="row row-cols-4 g-6">
                                                                {GFolderBranch2.map((taskB2) => (
                                                                    <Link onDoubleClick={() => {
                                                                        const newBranch = onBranch_M + 1;
                                                                        setOnBranch_M(newBranch);
                                                                        Mapping_S(taskB2.id, newBranch, inStage.category_id);
                                                                    }}>
                                                                        <div className="card" style={{ width: "29vh" }}>
                                                                            <div className="card-body text-center p-2 folder-column">
                                                                                <div className="rounded fs-24">
                                                                                    <FileIcon fileName={taskB2.title} />
                                                                                </div>
                                                                                <p className="mb-1 mt-2 fs-12">
                                                                                    {taskB2.title}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                ))}
                                                            </div>

                                                        ) : onBranch_M === 1 || onBranch_M < 0 ? (
                                                            <div className="row row-cols-4 g-6">
                                                                {GFolderBranch1.map((taskB1) => (
                                                                    <Link onDoubleClick={() => {
                                                                        const newBranch = onBranch_M + 1;
                                                                        setOnBranch_M(newBranch);
                                                                        Mapping_S(taskB1.id, newBranch, taskB1.id);
                                                                        setCurrentCategory(taskB1.id);
                                                                    }}>
                                                                        <div className="card" style={{ width: "29vh" }}>
                                                                            <div className="card-body text-center p-2 folder-column">
                                                                                <div className="rounded fs-24">
                                                                                    <FileIcon fileName={taskB1.name} />
                                                                                </div>
                                                                                <p className="mb-1 mt-2 fs-12">
                                                                                    {taskB1.name}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </Link>
                                                                ))}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger fw-medium" onClick={handleCloseModal}><i className="ri-close-line me-1 align-middle"></i> Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                showLinkModal && (
                    <div className="modal fade bs-example-modal-xl show azoomOutDown" tabIndex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel2" aria-modal="true" style={{ display: "block", backgroundColor: "#2e659385" }}>
                        <div className="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header bg-soft-info p-3">
                                    <h5 className="modal-title" id="exampleModalLabel"></h5>
                                    <button type="button" className="btn-close" onClick={() => {
                                        CloseLinkModal()
                                        handleEditLink_Close()
                                    }} data-bs-dismiss="modal" aria-label="Close" id="close-modal"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="tab-pane fade show active" id="pills-bill-info" role="tabpanel" aria-labelledby="pills-bill-info-tab">
                                        <form>
                                            <div>
                                                <p className="text-muted mb-4">The links you add will be shown to standard users</p>
                                            </div>

                                            <div>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="text-center">
                                                            <div className="position-relative d-inline-block">
                                                                <div className="avatar-lg p-1">

                                                                    <i class="ri-link" style={{ color: "blue" }}></i>

                                                                </div>
                                                            </div>
                                                            <h5 className="fs-13 mt-3">Support Links</h5>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-8">
                                                                <div className="mb-3">
                                                                    <label htmlFor="username" className="form-label">
                                                                        Links
                                                                    </label>
                                                                    {isEditingLink ? (
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Empty"
                                                                            value={currentLink.link ? currentLink.link : ""}
                                                                            onChange={handleLinkChange}
                                                                        />
                                                                    ) : (
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Empty"
                                                                            value={currentLink.link ? currentLink.link : ""}
                                                                            disabled
                                                                        />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            {userRole === 1 && (
                                                                <div className="col-2">
                                                                    <button type="button" className="btn btn-light" style={{ top: "28px" }} onClick={handleEditLink_Open}>
                                                                        <i className="ri-pencil-fill"></i>
                                                                    </button>
                                                                </div>

                                                            )}
                                                            {userRole === 1 && (
                                                                <div className="col-2">
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                            const url = formatLink(currentLink.link);

                                                                            if (currentLink.link) {
                                                                                const width = 600;
                                                                                const height = 400;
                                                                                const left = (window.innerWidth / 2) - (width / 2);
                                                                                const top = (window.innerHeight / 2) - (height / 2);
                                                                                window.open(url, 'popup', `width=${width},height=${height},top=${top},left=${left}`);
                                                                            } else {
                                                                                notifyError("There is no link assigned in this button, you need to add a link first")
                                                                            }

                                                                        }}
                                                                        style={{ top: "28px" }}
                                                                        className="btn btn-primary"
                                                                    >
                                                                        <i className="ri-links-line"></i>
                                                                    </button>
                                                                </div>

                                                            )}

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger fw-medium" onClick={() => {
                                        CloseLinkModal()
                                        handleEditLink_Close()
                                    }}><i className="ri-close-line me-1 align-middle"></i> Close</button>
                                    {isEditingLink && (

                                        <button type="button" className="btn btn-primary" onClick={() => {
                                            insertLink(currentLink.linksupport_id)
                                        }} disabled={loading}>
                                            {loading ? 'Saving...' : 'Save changes'}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            {
                updatting && (
                    <EditFile FileObject={FileObject} closeModalFile={closeModalFile} stageType={stageType} showFilesInFolder={showFilesInFolder} setGFilesBranch4={setGFilesBranch4} />
                )
            }

            {
                updatingFolder && (
                    <EditFoldername folderOBJ={folderOBJ} closeModalFolder={closeModalFolder} stageType={stageType} showFilesInFolder={showFilesInFolder} setGFolderBranch4={setGFolderBranch4} />
                )
            }

            {showFolderModal && (

                <div className="modal fade bs-example-modal-xl show azoomOutDown" tabIndex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel5" aria-modal="true" style={{ display: "block", backgroundColor: "#2e659385" }}>
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header bg-soft-info p-3">
                                <h5 className="modal-title" id="exampleModalLabel"></h5>
                                <button type="button" className="btn-close" onClick={() => {
                                    CloseLinkModal()
                                    handleEditLink_Close()
                                }} data-bs-dismiss="modal" aria-label="Close" id="close-modal"></button>
                            </div>
                            <div className="modal-body">
                                <div className="tab-pane fade show active" id="pills-bill-info" role="tabpanel" aria-labelledby="pills-bill-info-tab">
                                    <form>
                                        <div>
                                            <p className="text-muted mb-4">Please enter the folder name</p>
                                        </div>

                                        <div>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="text-center">
                                                        <div className="position-relative d-inline-block">
                                                            <div className="avatar-lg p-1">

                                                                <i class="ri-link" style={{ color: "blue" }}></i>

                                                            </div>
                                                        </div>
                                                        <h5 className="fs-13 mt-3">Create Folder</h5>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="mb-3">
                                                                <label htmlFor="username" className="form-label">
                                                                    Folder
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Please write the folder name"
                                                                    value={folderName}
                                                                    onChange={(e) => setfolderName(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger fw-medium" onClick={() => {
                                    setshowFolderModal(false);
                                }}><i className="ri-close-line me-1 align-middle"></i> Close</button>


                                <button type="button" className="btn btn-primary" onClick={() => {
                                    createFolder()
                                }} disabled={loading}>
                                    {loading ? 'Saving...' : 'Save changes'}
                                </button>

                            </div>
                        </div>
                    </div>
                </div>

            )}

            {ModalGlobalFile && (

                <div className="offcanvas slideleft offcanvas-start show" tabIndex="-1" id="offcanvasLeft1" aria-labelledby="offcanvasLeftLabel" style={{ visibility: "visible", width: "38%" }} aria-modal="true" role="dialog" onc>
                    <div className="offcanvas-header border-bottom">
                        <h5 className="offcanvas-title" id="offcanvasLeftLabel">Search files</h5>
                        <button type="button" className="btn-close text-reset" onClick={CloseGlobalFileModal}></button>
                    </div>
                    <div className="search-box">
                        <input
                            type="text"
                            placeholder="Search here..."
                            className="form-control"
                            style={{ height: "50px" }}
                            onChange={(e) => showGlobalFiles(e.target.value)}
                            autoFocus />
                        <i className="ri-search-2-line search-icon" style={{ color: "white" }}></i>
                    </div>
                    <div className="offcanvas-body p-0 overflow-hidden">
                        <div data-simplebar="init" style={{ height: 'calc(-112px + 100vh)' }}>
                            <div className="simplebar-wrapper" style={{ margin: '0px' }}>
                                <div className="simplebar-height-auto-observer-wrapper">
                                    <div className="simplebar-height-auto-observer"></div>
                                </div>
                                <div className="simplebar-mask">
                                    <div className="simplebar-offset" style={{ right: '0px', bottom: '0px' }}>
                                        <div className="simplebar-content-wrapper" tabIndex="0" role="region" aria-label="scrollable content" style={{ height: '100%', overflow: 'hidden' }}>
                                            <div className="simplebar-content" style={{ padding: '0px' }}>
                                                <div className="acitivity-timeline p-4">
                                                    {loadingGlobF ? (
                                                        <div className="d-flex justify-content-center align-items-center" style={{ height: "57vh" }}>
                                                            <div className="spinner-border text-primary" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <table className="table table-nowrap">
                                                            <thead>
                                                                <tr className="col-2">
                                                                    <th scope="col">Filename</th>
                                                                    <th scope="col">Size</th>
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>

                                                                {GlobalFiles.map((Gfiles) => (
                                                                    <tr>
                                                                        <th scope="row col-2">
                                                                            <Link href="#" className="fw-semibold">{Gfiles.file_name}</Link>
                                                                        </th>
                                                                        <td>{convertSize(Gfiles.size)}</td>
                                                                        <td>
                                                                            <button className="btn btn-primary" onClick={() => {
                                                                                const name = Gfiles.file_name;
                                                                                const name1 = name.replace(/\s/g, '');
                                                                                saveAs(Gfiles.file_path, name1);
                                                                            }}>  <i className="ri-download-2-line"></i></button>
                                                                        </td>
                                                                    </tr>
                                                                ))}

                                                            </tbody>
                                                        </table>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="simplebar-placeholder" style={{ width: 'auto', height: '48px' }}></div>
                            </div>
                            <div className="simplebar-track simplebar-horizontal" style={{ visibility: 'hidden' }}>
                                <div className="simplebar-scrollbar" style={{ width: '0px', display: 'none' }}></div>
                            </div>
                            <div className="simplebar-track simplebar-vertical" style={{ visibility: 'hidden' }}>
                                <div className="simplebar-scrollbar" style={{ height: '0px', display: 'none' }}></div>
                            </div>
                        </div>
                    </div>
                    <div className="offcanvas-footer border-top p-3 text-center">
                        <a href="javascript:void(0);" className="link-success">View All Acitivity <i className="ri-arrow-right-s-line align-middle ms-1"></i></a>
                    </div>
                </div>
            )}

            {DownloadModalFile && (
                <div className={`upload-progress-container azoomOutDown ${isMinimized ? 'minimized' : ''}`} style={{ height: isMinimized ? '10%' : '60%' }}>
                    <div className="upload-progress-header" style={{ backgroundColor: "rgb(67, 85, 144)" }}>
                        <center>
                            <h5 className="text-white">Uploading Files</h5>
                        </center>
                        <div style={{ padding: 0 }}>
                            <button onClick={() => setIsMinimized(!isMinimized)} className="minimize-button" style={{ paddingLeft: "10px" }}>
                                {isMinimized ? (
                                    <button className="btn btn-outline-primary" style={{ borderRadius: "56%", }}><i class="ri-arrow-up-s-line" style={{ fontSize: "24px", color: "white" }}></i></button>
                                ) : (
                                    <button className="btn btn-outline-primary" style={{ borderRadius: "56%" }}><i class="ri-arrow-down-s-line" style={{ fontSize: "24px", color: "white" }}></i></button>
                                )}
                                <button className="btn btn-outline-primary" onClick={() => {
                                    setDownloadModalFile(false);
                                    setFiles([]);
                                    setIsMinimized(false);
                                }} style={{ borderRadius: "56%" }}><i className="ri-close-line" style={{ fontSize: "24px", color: "white" }}></i></button>
                            </button>

                        </div>
                    </div>
                    {!isMinimized && (
                        <div>
                            <div className="upload-progress-content">
                                {files.map((file, index) => (
                                    <div key={index} className="upload-progress-item">
                                        <span>
                                            <div className="rounded fs-16">
                                                <FileIcon fileName={file.name} />
                                            </div>
                                        </span>
                                        <span className="truncate">{file.name}</span>
                                        <div style={{ width: "13%" }} className="fw-bold">
                                            <Flat
                                                progress={file.progress}
                                                sx={{
                                                    strokeColor: `${file.progress === 100 ? 'green' : "#4281ff"}`,
                                                    barWidth: 10,
                                                    miniCircleColor: '#095677',
                                                    miniCircleSize: 11
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {loadingFilesInServer && (

                                <div className="p-2 text-primary fw-bold">
                                    <p>The server is processing the files, please wait<span className="dots"></span></p>
                                </div>

                            )}
                        </div>
                    )}

                </div>
            )}


        </div >
    )
}

export default MainPanel;