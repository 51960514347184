import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../../config";
import { headers } from "../../token";
import Swal from "sweetalert2";
import CategoryUpdate from "./CategoryUpdate";
import DeleteCategory from "./DeleteCategory";

const CategoryList = () => {
    const server_backend = config.API_URL;
    const [categories, setCategories] = useState([]);
    const [idcategory, setIdcategory] = useState(0)
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);


    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenModal2 = () => {
        setShowModal2(true);
    };

    const handleCloseModal2 = () => {
        setShowModal2(false);
    };

    useEffect(() => {

        const Categ_List = async () => {

            try {

                const response = await axios.post(`${server_backend}/show-category-list`, {}, headers);
                setCategories(response.data);

            } catch (error) {

                console.log(error);

            }

        }

        Categ_List()

    }, [0])

    const Categ_List2 = async () => {

        try {

            const response = await axios.post(`${server_backend}/show-category-list`, {}, headers);
            setCategories(response.data);

        } catch (error) {

            console.log(error);

        }

    }

    const addCategory = async (e) => {
        e.preventDefault();


        if (!name.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please enter a platform name',
                confirmButtonText: 'Accept'
            });
            return;
        }

        try {
            const response = await axios.post(`${server_backend}/add-category`, {
                name: name
            }, headers);
            console.log(response.data);

            if (response.data === '200') {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Platform with the same name already exists',
                    confirmButtonText: 'Accept'
                });
            } else {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Platform added successfully',
                    showConfirmButton: false, 
                    timer: 1000 
                }).then(() => {
                    setCategories(response.data);
                    handleCloseModal();
                    setName('');
                });
            }
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <div className="page-content" data-simplebar-track="secondary" data-simplebar="init" style={{ height: "85vh", overflow: "auto" }}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Platform</h4>
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="d-flex align-items-center flex-wrap gap-2">
                                    <div className="flex-grow-1">
                                        <button className="btn btn-info add-btn" onClick={handleOpenModal}>
                                            <i className="ri-add-fill me-1 align-bottom"></i> Add Platform
                                        </button>
                                    </div>
                                    <div className="flex-shrink-0">
                                        <div className="hstack text-nowrap gap-2">
                                            <button className="btn btn-soft-danger">
                                                <i className="ri-delete-bin-2-line"></i>
                                            </button>
                                            <button type="button" id="dropdownMenuLink1" data-bs-toggle="dropdown" aria-expanded="false" className="btn btn-soft-info">
                                                <i className="ri-more-2-fill"></i>
                                            </button>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                                                <li><a className="dropdown-item" href="#">All</a></li>
                                                <li><a className="dropdown-item" href="#">Last Week</a></li>
                                                <li><a className="dropdown-item" href="#">Last Month</a></li>
                                                <li><a className="dropdown-item" href="#">Last Year</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-9">
                        <div className="card" id="companyList">
                            <div className="card-header">
                                <div className="row g-2">
                                    <div className="col-md-3">
                                        <div className="search-box">
                                            <input type="text" className="form-control search" placeholder="Search for Platform..." />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div>
                                    <div className="table-responsive table-card mb-3">
                                        <table className="table table-responsive align-middle table-nowrap mb-0" id="customerTable">
                                            <thead className="table-light">
                                                <tr>
                                                    <th scope="col" style={{ width: '50px' }}>
                                                        <div className="form-check">
                                                            <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                                                        </div>
                                                    </th>
                                                    <th className="sort" data-sort="name" scope="col">Platform Name</th>
                                                    <th className="sort" data-sort="owner" scope="col">Created at</th>

                                                    <th className="sort" data-sort="owner" scope="col">Priority</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="list form-check-all">
                                                {categories.map((categoriesR) => (
                                                    <tr>
                                                        <th scope="row">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" name="chk_child" value="option1" />
                                                            </div>
                                                        </th>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-grow-1 ms-2 name">{categoriesR.name}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="owner">{categoriesR.create_at}</td>
                                                        <td className="owner">{categoriesR.shift}</td>
                                                        <td>
                                                            <ul className="list-inline hstack gap-2 mb-0">
                                                                <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
                                                                    <div className="edit">
                                                                        <a type="button" className="link-success fs-15" onClick={() => {
                                                                            handleOpenModal2()
                                                                            setIdcategory(categoriesR.id)
                                                                        }}>
                                                                            <i class="ri-edit-2-line"></i>
                                                                        </a>
                                                                    </div>
                                                                </li>
                                                                <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Delete">
                                                                    <a className="remove-item-btn"
                                                                        onClick={() => {

                                                                            DeleteCategory(categoriesR.id, Categ_List2)

                                                                        }}>
                                                                        <i className="ri-delete-bin-fill align-bottom text-muted"></i>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                        <div className="noresult" style={{ display: 'none' }}>
                                            <div className="text-center">
                                                <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#121331,secondary:#08a88a" style={{ width: '75px', height: '75px' }}></lord-icon>
                                                <h5 className="mt-2">Sorry! No Result Found</h5>
                                                <p className="text-muted mb-0">We've searched more than 150+ companies We did not find any companies for your search.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end mt-3">
                                        <div className="pagination-wrap hstack gap-2">
                                            <a className="page-item pagination-prev disabled" href="#">
                                                Previous
                                            </a>
                                            <ul className="pagination listjs-pagination mb-0"></ul>
                                            <a className="page-item pagination-next" href="#">
                                                Next
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                {showModal && (
                                    <div className="modal fade bs-example-modal-xl show azoomOutDown" tabIndex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-modal="true" style={{ display: "block", backgroundColor: "#2e659385" }}>
                                        <div className="modal-dialog modal-dialog-centered modal-lg">
                                            <div className="modal-content border-0">
                                                <div className="modal-header bg-soft-info p-3">
                                                    <h5 className="modal-title" id="exampleModalLabel"></h5>
                                                    <button type="button" className="btn-close" onClick={handleCloseModal}></button>
                                                </div>
                                                <form onSubmit={addCategory}>
                                                    <div className="modal-body">
                                                        <input type="hidden" id="id-field" />
                                                        <div className="row g-3">
                                                            <div className="col-lg-12">
                                                                <div className="text-center">
                                                                    <div className="position-relative d-inline-block">
                                                                        <div className="position-absolute bottom-0 end-0">
                                                                            <label htmlFor="company-logo-input" className="mb-0" data-bs-toggle="tooltip" data-bs-placement="right" title="Select Image">
                                                                                <div className="avatar-xs cursor-pointer">
                                                                                    <div className="avatar-title bg-light border rounded-circle text-muted">
                                                                                        <i className="ri-image-fill"></i>
                                                                                    </div>
                                                                                </div>
                                                                            </label>
                                                                            <input className="form-control d-none" value="" id="company-logo-input" type="file" accept="image/png, image/gif, image/jpeg" />
                                                                        </div>
                                                                        <div className="avatar-lg p-1">
                                                                            <div className="avatar-title bg-light rounded-circle">
                                                                                <img src="assets/images/users/multi-user.jpg" id="companylogo-img" className="avatar-md rounded-circle object-cover" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <h5 className="fs-13 mt-3">Add Platform</h5>
                                                                </div>
                                                                <div>
                                                                    <label htmlFor="companyname-field" className="form-label">Name</label>
                                                                    <input
                                                                        type="text"
                                                                        id="companyname-field"
                                                                        className="form-control"
                                                                        placeholder="Enter category name"
                                                                        required
                                                                        value={name}
                                                                        onChange={(e) => setName(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="modal-footer">
                                                        <div className="hstack gap-2 justify-content-end">
                                                            <button type="button" className="btn btn-light" onClick={handleCloseModal}>Close</button>
                                                            <button type="submit"
                                                                className="btn btn-success"
                                                                id="add-btn" data-bs-dismiss="modal">Add Platform</button>
                                                        </div>
                                                    </div>

                                                </form>
                                            </div>
                                        </div>
                                    </div>

                                )}

                                <div className="modal fade zoomIn" id="deleteRecordModal" tabIndex="-1" aria-labelledby="deleteRecordLabel" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered">
                                        <div className="modal-content">
                                            <div className="modal-header">
                                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"></button>
                                            </div>
                                            <div className="modal-body p-5 text-center">
                                                <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#405189,secondary:#f06548" style={{ width: '90px', height: '90px' }}></lord-icon>
                                                <div className="mt-4 text-center">
                                                    <h4 className="fs-semibold">You are about to delete a company?</h4>
                                                    <p className="text-muted fs-14 mb-4 pt-1">Deleting your company will remove all of your information from our database.</p>
                                                    <div className="hstack gap-2 justify-content-center remove">
                                                        <button className="btn btn-link link-success fw-medium text-decoration-none" data-bs-dismiss="modal">
                                                            <i className="ri-close-line me-1 align-middle"></i> Close
                                                        </button>
                                                        <button className="btn btn-danger" id="delete-record">Yes, Delete It!!</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showModal2 && (
                <CategoryUpdate idcategory={idcategory} setCategories={setCategories} onClose={handleCloseModal2} />
            )}
        </div>

    )

}


export default CategoryList;