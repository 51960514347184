import React, { useState, useContext } from 'react';
import axios from 'axios';
import { UserContext } from '../../UserContext';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import Swal from 'sweetalert2';
const server_backend = config.API_URL;

const Login = () => {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { setUser } = useContext(UserContext);
  const navigate = useNavigate();


  const loginUser = async (e) => {
    e.preventDefault();
    let userData;

    try {
      
      const response = await axios.post(`${server_backend}/public/validate`, {
        username,
        password
      });

      console.log(response.data.success)

      if (response.data.success) {

        Swal.fire({
          icon: 'success',
          title: 'Login Successful',
          showConfirmButton: false,
        });
        
        //const token = response.data.vData.Token;
        const userData = response.data.token //Store only the user's name and ID
        const username = response.data.username;
        setUser(userData);

        //localStorage.setItem('token', token);
        localStorage.setItem('userData', JSON.stringify(userData)); // Store user data in localStorage
        localStorage.setItem('yourName', JSON.stringify(username)); // Store user data in localStorage

        // Update user state here
        navigate('/main-panel')
        window.location.reload()


      } else {

        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Invalid Credentials',
          showConfirmButton: false,
          timer: 1000
        });

      }
    } catch (error) {
      // Request failed
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Invalid Credentials',
        showConfirmButton: false,
        timer: 1000
      });

    }

    setUser(userData);
  };



  return (

    <div className="auth-page-wrapper pt-5">
      <div className="auth-one-bg-position auth-one-bg" id="auth-particles" style={{backgroundImage: "none"}}>
        <div className="bg-overlay" style={{background: "linear-gradient(to right,#072582,#405189)"}}></div>

        <div className="shape">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
          </svg>
        </div>
      </div>

      <div className="auth-page-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center mt-sm-5 mb-4 text-white-50">
                <div>
                  <a href="index.html" className="d-inline-block auth-logo">
                     <img src="assets/images/increqimg.png" alt="" width="300" /> 
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-6 col-xl-5">
              <div className="card mt-4">
                <div className="card-body p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Welcome Back !</h5>
                    <p className="text-muted">Sign in to continue to Increq Mananger.</p>
                  </div>
                  <div className="p-2 mt-4">
                    <form onSubmit={loginUser}>
                      <div className="mb-3">
                        <label htmlFor="username" className="form-label">Username</label>

                        <input
                          type="text"
                          className="form-control"
                          name='user'
                          id='user'
                          placeholder="Enter username"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          required />

                      </div>
                      <div className="mb-3">
                        <div className="float-end">
                          <a href="auth-pass-reset-basic.html" className="text-muted">Forgot password?</a>
                        </div>
                        <label className="form-label" htmlFor="password-input">Password</label>
                        <div className="position-relative auth-pass-inputgroup mb-3">

                          <input
                            type="password"
                            className="form-control pe-5"
                            name='password'
                            id="password"
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)} 
                            required/>

                          <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none" type="button" id="password-addon">
                            <i className="ri-eye-fill align-middle"></i>
                          </button>
                        </div>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                        <label className="form-check-label" htmlFor="auth-remember-check">Remember me</label>
                      </div>
                      <div className="mt-4">
                        <button className="btn btn-success w-100" type="submit">Sign In</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="text-center">
                <p className="mb-0 text-muted">&copy;
                  <script>document.write(new Date().getFullYear())</script> INCREQ. Crafted with <i className="mdi mdi-heart text-danger"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>

  );
};

export default Login;