import React, { useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import 'filepond/dist/filepond.min.css'

const TaskDetails = () => {
    const [files, setFiles] = useState([]);

    return (

        <h1>Test End Point</h1>
    )

}

export default TaskDetails;