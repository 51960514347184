// folderRoutes.js
import { useState, useEffect } from 'react';

const defaultRoute = '/uploads/default/default/';

export const useFolderRoutes = (inStage) => {
    const [Face1_currentFolder, setFace1_currentFolder] = useState({
        currentFolder: {},
        currentRoute: defaultRoute
    });
    const [Face2_currentFolder, setFace2_currentFolder] = useState({
        currentFolder: {},
        currentRoute: defaultRoute
    });
    const [Face3_currentFolder, setFace3_currentFolder] = useState({
        currentFolder: {},
        currentRoute: defaultRoute
    });
    const [Face4_currentFolder, setFace4_currentFolder] = useState({
        currentFolder: {},
        currentRoute: defaultRoute
    });
    const [Face5_currentFolder, setFace5_currentFolder] = useState({
        currentFolder: {},
        currentRoute: defaultRoute
    });
    const [Face6_currentFolder, setFace6_currentFolder] = useState({
        currentFolder: {},
        currentRoute: defaultRoute
    });
    const [Face7_currentFolder, setFace7_currentFolder] = useState({
        currentFolder: {},
        currentRoute: defaultRoute
    });
    const [Face8_currentFolder, setFace8_currentFolder] = useState({
        currentFolder: {},
        currentRoute: defaultRoute
    });
    const [Face9_currentFolder, setFace9_currentFolder] = useState({
        currentFolder: {},
        currentRoute: defaultRoute
    });
    const [Face10_currentFolder, setFace10_currentFolder] = useState({
        currentFolder: {},
        currentRoute: defaultRoute
    });
    const [Face11_currentFolder, setFace11_currentFolder] = useState({
        currentFolder: {},
        currentRoute: defaultRoute
    });
    const [Face12_currentFolder, setFace12_currentFolder] = useState({
        currentFolder: {},
        currentRoute: defaultRoute
    });

    useEffect(() => {
        if (inStage && inStage.CategoryName && inStage.TaskName) {
            setFace1_currentFolder({
                currentFolder: {},
                currentRoute: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/completed_files/`
            });
            setFace2_currentFolder({
                currentFolder: {},
                currentRoute: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/work_in_progress/`
            });
            setFace3_currentFolder({
                currentFolder: {},
                currentRoute: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/docs/`
            });
            setFace4_currentFolder({
                currentFolder: {},
                currentRoute: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/completed_files/`
            });
            setFace5_currentFolder({
                currentFolder: {},
                currentRoute: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/1/`
            });
            setFace6_currentFolder({
                currentFolder: {},
                currentRoute: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/2/`
            });
            setFace7_currentFolder({
                currentFolder: {},
                currentRoute: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/3/`
            });
            setFace8_currentFolder({
                currentFolder: {},
                currentRoute: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/4/`
            });
            setFace9_currentFolder({
                currentFolder: {},
                currentRoute: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/5/`
            });
            setFace10_currentFolder({
                currentFolder: {},
                currentRoute: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/6/`
            });
            setFace11_currentFolder({
                currentFolder: {},
                currentRoute: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/7/`
            });
            setFace12_currentFolder({
                currentFolder: {},
                currentRoute: `/uploads/${inStage.CategoryName}/${inStage.TaskName}/8/`
            });
        }
    }, [inStage]);

    return {
        Face1_currentFolder,
        Face2_currentFolder,
        Face3_currentFolder,
        Face4_currentFolder,
        Face5_currentFolder,
        Face6_currentFolder,
        Face7_currentFolder,
        Face8_currentFolder,
        Face9_currentFolder,
        Face10_currentFolder,
        Face11_currentFolder,
        Face12_currentFolder,
        setFace1_currentFolder,
        setFace2_currentFolder,
        setFace3_currentFolder,
        setFace4_currentFolder,
        setFace5_currentFolder,
        setFace6_currentFolder,
        setFace7_currentFolder,
        setFace8_currentFolder,
        setFace9_currentFolder,
        setFace10_currentFolder,
        setFace11_currentFolder,
        setFace12_currentFolder
    };
};
