import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import config from '../../config';
import { headers } from '../../token';
import Swal from "sweetalert2";

const TrashFile = () => {
    const server_backend = config.API_URL;
    const [showTfiles, setShowTfiles] = useState([]);
    const [stageFilter, setStageFilter] = useState("");
    const [filenameFilter, setFilenameFilter] = useState("");
    const [categoryFilter, setCategoryFilter] = useState("");
    const [categories, setCategories] = useState([])
    const [selectedItems, setSelectedItems] = useState([]);


    const handleStageChange = (event) => {
        setStageFilter(event.target.value);
    };

    const handleFilenameChange = (event) => {
        setFilenameFilter(event.target.value);
    };

    const handleCategoryChange = (event) => {
        setCategoryFilter(event.target.value);
    };

    useEffect(() => {
        const showTrash = async () => {
            try {
                const response = await axios.post(`${server_backend}/trash-files`, {}, headers);
                setShowTfiles(response.data);
            } catch (error) {
                console.log(error)
            }
        };

        showTrash();
    }, [0]);

    useEffect(() => {
        const filterFiles = async () => {
            // Filter only if there is at least one full filter field
            if (stageFilter || filenameFilter || categoryFilter) {
                const filteredFiles = showTfiles.filter((file) => {
                    const stageMatch = !stageFilter || file.title.toLowerCase().includes(stageFilter.toLowerCase());
                    const filenameMatch = !filenameFilter || file.file_name.toLowerCase().includes(filenameFilter.toLowerCase());
                    const categoryMatch = !categoryFilter || file.username.toLowerCase() === categoryFilter.toLowerCase();

                    return stageMatch && filenameMatch && categoryMatch;
                });

                setShowTfiles(filteredFiles);
            } else {
                // If all filter fields are empty, show the entire list
                const response = await axios.post(`${server_backend}/trash-files`, {}, headers);
                setShowTfiles(response.data);
            }
        };

        // We call the filter function every time any of the filter variables change
        filterFiles();
    }, [stageFilter, filenameFilter, categoryFilter]);

    useEffect(() => {

        const Categ_List = async () => {
            try {
                const response = await axios.post(`${server_backend}/show-category-list`, {}, headers);
                setCategories(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        Categ_List()
    }, [0]);

    const EmptyTrash = async () => {

        Swal.fire({
            icon: 'warning',
            title: 'Alert',
            html: `Are you sure you want to empty the Files <span style="color: red;"> This action cannot be undone.</span>`,
            showCancelButton: true,
            confirmButtonText: 'Accept',
            cancelButtonText: 'Cancel'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await axios.post(`${server_backend}/empty-trash-file`, {
                        selectedItems: selectedItems
                    }, headers);

                    // Update file list after deleting
                    const updatedFiles = showTfiles.filter((file) => !selectedItems.includes(file.id));
                    setShowTfiles(updatedFiles);

                    setSelectedItems([]);

                    if (response.data === 100) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: "Success.",
                            confirmButtonText: 'Accept',
                        });

                    } else if (response.data.Status === 200) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: "hi",
                            confirmButtonText: 'Accept'
                        });
                    }

                } catch (error) {
                    console.error(error);
                }
            }
        });
    }
    const handleCheckboxChange = (fileID) => {
        const isSelected = selectedItems.includes(fileID);

        if (isSelected) {
            setSelectedItems(selectedItems.filter((item) => item !== fileID));
        } else {
            setSelectedItems([...selectedItems, fileID]);
        }
    };

    function convertSize(sizeInBytes) {
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];

        if (sizeInBytes === 0) return '0 Byte';

        const i = parseInt(Math.floor(Math.log(sizeInBytes) / Math.log(1024)));
        return Math.round(sizeInBytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }



    return (
        <div className="page-content" data-simplebar-track="secondary" data-simplebar="init" style={{ marginTop: "-11px", height: "100vh", overflow: "auto" }}>
            <div className="container-fluid">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-header border-0">
                                <div className="d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Deleted files</h5>
                                </div>
                                <div className="d-flex align-items-center top-20">
                                    <p className="text-muted d-flex align-items-center">If you empty the trash bin, the recovery of files will be irreversible. These steps have been implemented in case a user accidentally deletes a file, to ensure that appropriate measures are taken, if you have any questions, please do not hesitate to contact the system administrator for assistance.</p>
                                </div>
                            </div>

                        </div>
                        <div className="card-body border border-dashed border-end-0 border-start-0">
                            <form>
                                <div className="row g-3">
                                    <div className="col-xxl-5 col-sm-6">
                                        <div className="search-box">
                                            <input
                                                type="text"
                                                className="form-control search bg-light border-light"
                                                placeholder="Search for Filename..."
                                                value={filenameFilter}
                                                onChange={handleFilenameChange}
                                            />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                    <div className="col-xxl-3 col-sm-6">
                                        <input
                                            type="text"
                                            className="form-control bg-light border-light"
                                            id="demo-datepicker"
                                            data-provider="flatpickr"
                                            data-date-format="d M, Y"
                                            data-range-date="true"
                                            placeholder="Find for stages"
                                            value={stageFilter}
                                            onChange={handleStageChange}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="card-body">
                            <div id="customerList">
                                <div className="row g-4 mb-3">
                                    <div className="col-sm-auto">
                                        <div>
                                            <button
                                                className="btn btn-soft-danger"
                                                onClick={() => EmptyTrash()}
                                                disabled={selectedItems.length === 0}
                                            >
                                                <i className="ri-delete-bin-2-line"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-sm">
                                        <div className="d-flex justify-content-sm-end">
                                            <div className="search-box ms-2">
                                                <input type="text" className="form-control search" placeholder="Search..." />
                                                <i className="ri-search-line search-icon"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive table-card mt-3 mb-1">
                                    <table className="table align-middle table-nowrap" id="customerTable">
                                        <thead className="table-light">
                                            <tr>
                                                <th scope="col" style={{ width: "50px" }}>
                                                    <div className="form-check">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="checkAll"
                                                            value="option"
                                                            onChange={(e) => {
                                                                const allChecked = e.target.checked;
                                                                const allFileIDs = showTfiles.map((file) => file.id);

                                                                if (allChecked) {
                                                                    setSelectedItems(allFileIDs);
                                                                } else {
                                                                    setSelectedItems([]);
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </th>
                                                <th className="sort" data-sort="customer_name">Filename</th>
                                                <th className="sort" data-sort="email">Size</th>
                                                <th className="sort" data-sort="phone">Stage</th>
                                                <th className="sort" data-sort="date">Username</th>
                                                <th className="sort" data-sort="status">Deleted at</th>
                                                <th className="sort" data-sort="action">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                            {showTfiles.map((showTfiles_R) => (
                                                <tr key={showTfiles_R.id}>
                                                    <th scope="row">
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="chk_child"
                                                                value={showTfiles_R.id}
                                                                checked={selectedItems.includes(showTfiles_R.id)}
                                                                onChange={() => handleCheckboxChange(showTfiles_R.id)}
                                                            />
                                                        </div>
                                                    </th>
                                                    <td className="customer_name">{showTfiles_R.file_name}</td>
                                                    <td className="email">{convertSize(showTfiles_R.size)}</td>
                                                    <td className="phone">{showTfiles_R.title}</td>
                                                    <td className="status">
                                                        <span className="badge badge-soft-success text-uppercase">{showTfiles_R.username}</span>
                                                    </td>
                                                    <td className="date">{showTfiles_R.created_at}</td>
                                                    <td>
                                                        <div className="d-flex gap-2">
                                                            <div className="remove">
                                                                <a href="#!" onClick={(e) => {
                                                                    e.stopPropagation(); // Prevent the outer div's onClick from being triggered
                                                                    const backendFilePath = `${server_backend}/${showTfiles_R.file_path}`;
                                                                    const fileNameWithoutSpaces = showTfiles_R.file_name.replace(/\s/g, '');
                                                                    saveAs(backendFilePath, fileNameWithoutSpaces);
                                                                }}>
                                                                    <i className="ri-download-2-line fs-17 lh-1 align-middle"></i>
                                                                </a>

                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="noresult" style={{ display: showTfiles.length === 0 ? "block" : "none" }}>
                                        <div className="text-center">
                                            <lord-icon
                                                src="https://cdn.lordicon.com/msoeawqm.json"
                                                trigger="loop"
                                                colors="primary:#121331,secondary:#08a88a"
                                                style={{ width: "75px", height: "75px" }}
                                            ></lord-icon>
                                            <h5 className="mt-2">Sorry! No Result Found</h5>
                                            <p className="text-muted mb-0">
                                                We've searched more than 150+ Orders We did not find any orders for you search.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <div className="pagination-wrap hstack gap-2">
                                        <a className="page-item pagination-prev disabled" href="#">
                                            Previous
                                        </a>
                                        <ul className="pagination listjs-pagination mb-0"></ul>
                                        <a className="page-item pagination-next" href="#">
                                            Next
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default TrashFile;
