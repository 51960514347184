import React from "react";
import axios from "axios";
import config from '../../config';
import { headers } from "../../token";

const DeleteFile = async (e, fileData, faceSource, user2,  notify, notifyError) => {
    const server_backend = config.API_URL;
    if (e) e.preventDefault();


    const response = await axios.post(`${server_backend}/delete-file`, {
        fileID: fileData.id,
        stageID: fileData.task_id,
        faceSource: faceSource,
        username: user2
    }, headers);

    if (response.data.status === 500) {
        notifyError(`${response.data.message}`)
    } 


};

export default DeleteFile;