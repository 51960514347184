import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from '../../config';
import axios from "axios";
import { headers } from "../../token";
import Swal from "sweetalert2";
import EditStage from "./EditStage";


const TaskList = ({ username }) => {
    const server_backend = config.API_URL;
    const user2 = JSON.parse(username);
    const [users, setUsers] = useState([]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [assignedTo, setAssignedTo] = useState([]);
    const [category, setCategory] = useState(0);
    const [showModal, setShowModal] = useState(false)
    const [showCategory, setShowCategory] = useState([]);
    const [showStage, setShowStage] = useState([]);
    const [totalStage, setTotalStage] = useState(0);
    const [totalCategories, SetTotalCategory] = useState(0);
    const [isEditStage, setisEditStage] = useState(false);
    const [stageOBJ, setStageOBJ] = useState([]);

    useEffect(() => {

        const FetchData = async () => {

            try {

                const response = await axios.post(`${server_backend}/fetch-user-data`, {}, headers);
                setUsers(response.data);

            } catch (error) {
                console.log(error)
            }
        }
        FetchData()

    }, [0]);

    useEffect(() => {

        const showCategory = async () => {

            try {
                const response = await axios.post(`${server_backend}/show-category-to-stage`, {}, headers);
                setShowCategory(response.data)
            } catch (error) {
                console.log(error)
            }
        }
        showCategory()

    }, [0]);

    useEffect(() => {

        const TotalStages = async () => {
            try {

                const response = await axios.post(`${server_backend}/total-stages`, {}, headers);
                setTotalStage(response.data.TotalStages);
                SetTotalCategory(response.data.TotalCategories);

            } catch (error) {
                console.log(error)
            }
        }
        TotalStages()
    }, [0]);

    const stageByCategory = async (categoryID) => {

        try {

            const response = await axios.post(`${server_backend}/show-stage-for-category`, {
                categoryID: categoryID,

            }, headers);
            setShowStage(response.data);

        } catch (error) {
            console.log(error)
        }

    }
    const TaskAssignFile = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('category', category);
        formData.append('username', user2);

       // Convert assignedTo to a set to avoid duplicates
        const uniqueAssignedTo = new Set(assignedTo);

        // Iterate over the set and add each user ID to the FormData
        uniqueAssignedTo.forEach((user) => {
            formData.append('assignedTo', user);
        });

        try {
            const response = await axios.post(`${server_backend}/assign-task-file`, formData, headers);
            console.log(response.data);
            if (response.data.Status === 100) {

                stageByCategory(category);
                handleCloseModal()
                //setUsers([]);
                //setAssignedTo([]);
                setName("");
                setDescription("");
                setCategory("");
                Swal.fire({
                    icon: 'success',
                    title: 'success',
                    text: "The new stage has been added",
                    showConfirmButton: false,
                    timer: 1000
                });

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: "Stage with the same name already exists",
                    confirmButtonText: 'I understand'
                });
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: "An unexpected error has occurred. If it persists, please contact the system administrator.",
                showConfirmButton: false,
                timer: 2000
            });

        }
    };

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenEditStage = () => {
        setisEditStage(true);
    }

    const handleCloseEditStage = () => {
        setisEditStage(false);
    }

    const deleteStage = async (taskID, categoryID) => {

        Swal.fire({
            icon: 'warning',
            title: 'Alert',
            text: "Are you sure you want to delete the scenario? This action cannot be undone, all the files contained in this stage will go to the recycle bin",
            showCancelButton: true,
            confirmButtonText: 'Accept',
            cancelButtonText: "Cancel"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {

                    const response = await axios.post(`${server_backend}/delete-stage`, {
                        taskID: taskID,
                        categoryID: categoryID
                    }, headers);
                    console.log(response.data);
                    if (response.data === 100) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: "Success.",
                            showConfirmButton: false,
                            timer: 1000
                        }).then(async () => {
                            stageByCategory(categoryID)
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: "An unexpected error has occurred, if it persists, consult your administrator",
                            confirmButtonText: 'Accept'
                        });
                    }
                } catch (error) {
                    console.log(error);
                }
            }
        });
    }

    return (

        <div className="page-content" data-simplebar-track="secondary" data-simplebar="init" style={{ height: "85vh", overflow: "auto" }}>
            <div className="container-fluid">

                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Stage List</h4>

                            <div className="page-title-right">
                                <ol className="breadcrumb m-0">
                                    <li className="breadcrumb-item">
                                        <a href="javascript: void(0);">Stage</a>
                                    </li>
                                    <li className="breadcrumb-item active">Stage List</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xxl-3 col-sm-3">
                        <div className="card card-animate">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p className="fw-medium text-muted mb-0">Total Stages</p>
                                        <h2 className="mt-4 ff-secondary fw-semibold">
                                            <span className="counter-value" data-target="234">{totalStage}</span>
                                        </h2>
                                    </div>
                                    <div>
                                        <div className="avatar-sm flex-shrink-0">
                                            <span className="avatar-title bg-soft-info text-info rounded-circle fs-4">
                                                <i className="ri-ticket-2-line"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-3 col-sm-3">
                        <div className="card card-animate">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p className="fw-medium text-muted mb-0">Total categories</p>
                                        <h2 className="mt-4 ff-secondary fw-semibold">
                                            <span className="counter-value" data-target="64.5">{totalCategories}</span>
                                        </h2>
                                    </div>
                                    <div>
                                        <div className="avatar-sm flex-shrink-0">
                                            <span className="avatar-title bg-soft-warning text-warning rounded-circle fs-4">
                                                <i className="mdi mdi-timer-sand"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-3 col-sm-3">
                        <div className="card card-animate">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p className="fw-medium text-muted mb-0">Completed Stages</p>
                                        <h2 className="mt-4 ff-secondary fw-semibold">
                                            <span className="counter-value" data-target="116.21">0</span>
                                        </h2>
                                    </div>
                                    <div>
                                        <div className="avatar-sm flex-shrink-0">
                                            <span className="avatar-title bg-soft-success text-success rounded-circle fs-4">
                                                <i className="ri-checkbox-circle-line"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-3 col-sm-3">
                        <div className="card card-animate">
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p className="fw-medium text-muted mb-0">Deleted Stages</p>
                                        <h2 className="mt-4 ff-secondary fw-semibold">
                                            <span className="counter-value" data-target="14.84">0</span>
                                        </h2>
                                    </div>
                                    <div>
                                        <div className="avatar-sm flex-shrink-0">
                                            <span className="avatar-title bg-soft-danger text-danger rounded-circle fs-4">
                                                <i className="ri-delete-bin-line"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card" id="tasksList">
                            <div className="card-header border-0">
                                <div className="d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">All Stages</h5>
                                    <div className="flex-shrink-0">
                                        <button className="btn btn-danger add-btn" onClick={handleOpenModal}>
                                            <i className="ri-add-line align-bottom me-1"></i> Create Stage
                                        </button>
                                        <button className="btn btn-soft-danger">
                                            <i className="ri-delete-bin-2-line"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body border border-dashed border-end-0 border-start-0">
                                <form>
                                    <div className="row g-3">

                                        <div className="col-xxl-3 col-sm-4">
                                            <input type="text" className="form-control bg-light border-light" placeholder="Search for Stages or something..." required />
                                        </div>
                                        <div className="col-xxl-3 col-sm-4">
                                            <div className="input-light">
                                                <select
                                                    className="form-control"
                                                    data-choices
                                                    data-choices-search-false
                                                    onChange={(e) => stageByCategory(e.target.value)}
                                                    required>
                                                    <option value="">Select a category</option>
                                                    {showCategory.map((showCategory_R) => (
                                                        <option key={showCategory_R.id} value={showCategory_R.id}>
                                                            {showCategory_R.name}
                                                        </option>
                                                    ))}
                                                </select>

                                            </div>
                                        </div>
                                        <div className="col-xxl-1 col-sm-4">
                                            <button type="button" className="btn btn-primary w-100">
                                                <i className="ri-equalizer-fill me-1 align-bottom"></i>
                                                Filters
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="card-body">
                                <div className="table-responsive table-card mb-4" style={{ minHeight: "400px" }}>
                                    <table className="table align-middle table-nowrap mb-0" id="tasksTable">
                                        <thead className="table-light text-muted">
                                            <tr>
                                                <th className="sort p4" data-sort="project_name">STAGE</th>
                                                <th className="sort" data-sort="tasks_name">Assigned To</th>
                                                <th className="sort" data-sort="assignedto">Priority</th>
                                                <th className="sort">created_at</th>
                                                <th className="sort">ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                            {showStage.map((showStage_R) => (
                                                <tr>
                                                    <td className="project_name p4">
                                                        <Link className="fw-medium link-primary">
                                                            {showStage_R.title}
                                                        </Link>
                                                    </td>
                                                    <td className="client_name">{showStage_R.UsersAssigned}</td>
                                                    <td className="due_date">{showStage_R.shift}</td>
                                                    <td className="priority"><span className="badge bg-primary text-uppercase">{showStage_R.modified_at}</span></td>
                                                    <td className="due_date">
                                                        <Link className="p-2" onClick={() => {
                                                            setStageOBJ(showStage_R);
                                                            handleOpenEditStage()
                                                        }}>
                                                            <i className="ri-pencil-fill"></i>
                                                        </Link>
                                                        <Link className="p-2" onClick={() => {
                                                            deleteStage(showStage_R.id, showStage_R.category_id)
                                                        }}>
                                                            <i className="ri-delete-bin-fill"></i>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div className="noresult" style={{ display: 'none' }}>
                                        <div className="text-center">
                                            <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#121331,secondary:#08a88a" style={{ width: '75px', height: '75px' }}></lord-icon>
                                            <h5 className="mt-2">Sorry! No Result Found</h5>
                                            <p className="text-muted mb-0">We've searched more than 200k+ Stages We did not find any Stage for your search.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end mt-2">
                                    <div className="pagination-wrap hstack gap-2">
                                        <a className="page-item pagination-prev disabled" href="#">
                                            Previous
                                        </a>
                                        <ul className="pagination listjs-pagination mb-0"></ul>
                                        <a className="page-item pagination-next" href="#">
                                            Next
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade flip" id="deleteOrder" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-body p-5 text-center">
                                <lord-icon src="https://cdn.lordicon.com/gsqxdxog.json" trigger="loop" colors="primary:#405189,secondary:#f06548" style={{ width: '90px', height: '90px' }}></lord-icon>
                                <div className="mt-4 text-center">
                                    <h4>You are about to delete a Stages?</h4>
                                    <p className="text-muted fs-14 mb-4">Deleting your task will remove all of your information from our database.</p>
                                    <div className="hstack gap-2 justify-content-center remove">
                                        <button className="btn btn-link btn-ghost-success fw-medium text-decoration-none" data-bs-dismiss="modal">
                                            <i className="ri-close-line me-1 align-middle"></i> Close
                                        </button>
                                        <button className="btn btn-danger" id="delete-record">
                                            Yes, Delete It
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {showModal && (
                    <div className="modal fade bs-example-modal-sm show azoomOutDown" tabIndex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel3" aria-modal="true" style={{ display: "block", backgroundColor: "#2e659385" }}>
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content border-0">
                                <div className="modal-header p-3 bg-soft-info">
                                    <h5 className="modal-title" id="exampleModalLabel">
                                        Create Stage
                                    </h5>
                                    <button type="button" className="btn-close" onClick={handleCloseModal}></button>
                                </div>
                                <form>
                                    <div className="modal-body">
                                        <input type="hidden" id="tasksId" />
                                        <div className="row g-3">
                                            <div className="col-lg-12">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <label htmlFor="projectName-field" className="form-label">
                                                            Stage Name
                                                        </label>
                                                        <input
                                                            type="text"
                                                            id="projectName-field"
                                                            className="form-control"
                                                            placeholder="Stage name"
                                                            name="name"
                                                            value={name}
                                                            onChange={(e) => setName(e.target.value)}
                                                            required
                                                        />
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <label htmlFor="projectName-field" className="form-label">
                                                            Category
                                                        </label>
                                                        <select
                                                            className="form-control"
                                                            data-choices
                                                            data-choices-search-false
                                                            id="ticket-status"
                                                            value={category}
                                                            onChange={(e) => setCategory(e.target.value)}
                                                        >
                                                            <option>Select Category</option>

                                                            {showCategory.map((categoryData_R) => (
                                                                <option value={categoryData_R.id}>{categoryData_R.name}</option>
                                                            ))}

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <label htmlFor="tasksTitle-field" className="form-label">
                                                    Description
                                                </label>
                                                <input
                                                    type="text"
                                                    id="tasksTitle-field"
                                                    className="form-control"
                                                    placeholder="Description"
                                                    name="description"
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="col-lg-12">
                                                <label className="form-label">Assigned To</label>
                                                <div data-simplebar style={{ height: '95px' }}>
                                                    <ul className="list-unstyled vstack gap-2 mb-0">
                                                        {users.map((user) => (
                                                            <li key={user.id}>
                                                                <div className="form-check d-flex align-items-center">
                                                                    <input
                                                                        className="form-check-input me-3"
                                                                        type="checkbox"
                                                                        name="user-select"
                                                                        value={user.id}
                                                                        id={`user-select-${user.id}`}
                                                                        onChange={(e) => {
                                                                            const userId = e.target.value;
                                                                            setAssignedTo((prev) =>
                                                                                e.target.checked ? [...prev, userId] : prev.filter((id) => id !== userId)
                                                                            );
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label d-flex align-items-center" htmlFor={`user-select-${user.id}`}>
                                                                        <span className="flex-shrink-0">
                                                                            <img src="assets/images/users/avatar-1.jpg" alt="" className="avatar-xxs rounded-circle" />
                                                                        </span>
                                                                        <span className="flex-grow-1 ms-2">{user.username}</span>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <div className="hstack gap-2 justify-content-end">
                                            <button type="button" className="btn btn-light" onClick={handleCloseModal}>
                                                Close
                                            </button>
                                            <button type="submit" className="btn btn-success" onClick={TaskAssignFile} id="add-btn">
                                                Add Stage
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}


                {isEditStage && (
                    <EditStage stageOBJ={stageOBJ} showCategory={showCategory} handleCloseEditStage={handleCloseEditStage} stageByCategory={stageByCategory} />
                )}

            </div>
        </div >
    )
}

export default TaskList;