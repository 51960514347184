import React, { useState, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Login from './components/auth/Login';
import UserModule from './components/users/Usermodule';
import AddUser from './components/users/Adduser';
import CategoryList from './components/category/CategoryList';
import TaskList from './components/task/TaskList';
import TaskDetails from './components/task/TaskDetails';
import MainPanel from './components/main/MainPanel';
import Sidebar2 from './components/Sidebar2';
import TrashFile from './components/trash/TrashFile';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

//Dark Mode 
const darkModeEnabled = localStorage.getItem('DarkMode');
if (darkModeEnabled) {
  const htmlElement = document.querySelector('html');
  htmlElement.setAttribute('data-layout-mode', "light");
}

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const username = localStorage.getItem('yourName');

  useEffect(() => {
    const userData = localStorage.getItem('userData');
    if (userData) {
      setIsLoggedIn(true);
      const decodedToken = jwtDecode(userData);
      setUserRole(decodedToken.role_id);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const logout = () => {
    localStorage.removeItem('userData');
    localStorage.removeItem('yourName');
    setIsLoggedIn(false);
  };

  useEffect(() => {
    const axiosInterceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          logout();
          return Promise.reject(error);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(axiosInterceptor);
    };
  }, []);

  return (
    <div>
      {isLoggedIn ? (
        <div id='layout-wrapper'>
          <div className="vertical-overlay"></div>
          <div className="main-content">
            <Sidebar2 logout={logout} username={username} userRole={userRole} />
            <Routes>
              <Route path='/users' element={<UserModule />} />
              <Route path='/trash' element={<TrashFile />} />
              <Route path='/add-users' element={<AddUser />} />
              <Route path='/show-category' element={<CategoryList />} />
              <Route path='/task-list' element={<TaskList username={username} />} />
              <Route path='/task-details' element={<TaskDetails />} />
              <Route path='/main-panel' element={<MainPanel username={username} userRole={userRole} />} />
            </Routes>
          </div>
        </div>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default App;
