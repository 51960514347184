import axios from "axios";
import config from '../../config';
import { headers } from "../../token";
import Swal from "sweetalert2";

const DeleteCategory = (idcategory, Categ_List2) => {
    const server_backend = config.API_URL;

    Swal.fire({
        icon: 'warning',
        title: 'Alert',
        text: "Are you sure you want to delete the Category? This action cannot be undone.",
        showCancelButton: true,
        confirmButtonText: 'Accept',
        cancelButtonText: "Cancel"
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                const response = await axios.post(`${server_backend}/delete-category`, {
                    idcategory: idcategory
                }, headers);

                if (response.data.StatusCode === 100) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: "Success.",
                        showConfirmButton: false,
                        timer: 1000
                    }).then(async () => {
                        Categ_List2()
                    });
                } else if (response.data.StatusCode === 300) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.StatusMessage,
                        confirmButtonText: 'Accept'
                    });
                }

            } catch (error) {
                console.error(error);
            }
        }
    });

};
export default DeleteCategory;