
//Here we can have the token to autenticate with backend from node js

const headers = {
    headers: {
        Authorization: localStorage.getItem('userData')
    }
}
  
module.exports = {
    headers 
}