import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import { headers } from '../../token';
import Swal from 'sweetalert2';

const EditUser = ({ iduser, onClose, setUser, showUsers }) => {
    const server_backend = config.API_URL;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [role, setRole] = useState(1);
    const [loading, setLoading] = useState(false);
    const [userRole, setUserRole] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(`${server_backend}/edit-user`, {
                    iduser: iduser
                }, headers);

                setUsername(response.data.username || '');
                setName(response.data.name || '');
                setUserRole(response.data.role_id);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [iduser, server_backend]);

    const update_User = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(`${server_backend}/update-user`, {
                iduser: iduser,
                username: username,
                password: password,
                name: name,
                role: role,
            }, headers);

            if (response.data.StatusCode === 100) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: response.data.StatusMessage,
                    showConfirmButton: false,
                    timer: 1000
                }).then(() => {
                    onClose()
                    showUsers(iduser)
                });

            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.data.StatusMessage,
                    showConfirmButton: false,
                    timer: 2000
                });
            }

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="modal fade bs-example-modal-xl show azoomOutDown" tabIndex="-1" role="dialog" aria-labelledby="myExtraLargeModalLabel" aria-modal="true" style={{ display: "block", backgroundColor: "#2e659385" }}>
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="myExtraLargeModalLabel">Modify user</h5>
                        <button type="button" className="btn-close" onClick={onClose} aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="tab-pane fade show active" id="pills-bill-info" role="tabpanel" aria-labelledby="pills-bill-info-tab">
                            <form>
                                <div>
                                    <p className="text-muted mb-4">You can change the value of only one field if you leave it, if so the others will keep their value.</p>
                                </div>

                                <div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="username" className="form-label">
                                                    Username
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="username"
                                                    name="username"
                                                    placeholder="Enter username"
                                                    value={username}
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="password" className="form-label">
                                                    New Password
                                                </label>

                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    id="passwordInput"
                                                    name="password"
                                                    placeholder="Enter password"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="name" className="form-label">
                                                    Name<span className="text-muted">(Optional)</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="name"
                                                    name="name"
                                                    placeholder="Enter name"
                                                    value={name}
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label htmlFor="role" className="form-label">
                                                    Role
                                                </label>
                                                <select
                                                    className="form-control"
                                                    id="role"
                                                    name="role"
                                                    value={role}
                                                    onChange={(e) => setRole(e.target.value)}
                                                    required
                                                >
                                                    <option>Select role</option>
                                                    <option value={1}>Admin</option>
                                                    <option value={2}>Agent</option>
                                                </select>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="billinginfo-address" className="form-label">
                                            Address
                                        </label>
                                        <textarea className="form-control" id="billinginfo-address" placeholder="Enter address" rows="3"></textarea>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger fw-medium" onClick={onClose}><i className="ri-close-line me-1 align-middle"></i> Close</button>
                        <button type="button" className="btn btn-primary" onClick={update_User} disabled={loading}>
                            {loading ? 'Saving...' : 'Save changes'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditUser;
