import React, { useState } from 'react';
import axios from 'axios';
import config from '../../config';
import { headers } from '../../token';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

const AddUser = () => {
    const server_backend = config.API_URL;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [role, setRole] = useState(1);
    const [loading, setLoading] = useState(false);

    const add_User = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            const response = await axios.post(`${server_backend}/add-users`, {
                username: username,
                password: password,
                name: name,
                role: role,
            }, headers);
            
            if (response.data.StatusCode === 100) {
                Swal.fire({
                    icon: 'success',
                    title: 'Good',
                    text: response.data.StatusMessage,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else if (response.data.StatusCode === 200) {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.data.StatusMessage,
                    showConfirmButton: false,
                    timer: 1000
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.data.StatusMessage,
                    showConfirmButton: false,
                    timer: 1000
                });
            }

        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="page-content">
            <div className="container-fluid">
                <div className="tab-pane fade show active" id="pills-bill-info" role="tabpanel" aria-labelledby="pills-bill-info-tab">
                    <form onSubmit={add_User}>
                        <div className=' justify-content-center'>
                            <Link className='btn btn-warning' to="/users" >Go back</Link>
                        </div>
                        <center>
                            <div>
                                <h5 className="mb-1">Add users</h5>
                                <p className="text-muted mb-4">Please fill all information below</p>
                            </div>
                        </center>
                        <div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="mb-3">
                                        <label htmlFor="username" className="form-label">
                                            Username
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="username"
                                            name="username"
                                            placeholder="Enter username"
                                            value={username}
                                            onChange={(e) => setUsername(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">
                                            Password
                                        </label>

                                        <input
                                            type="password"
                                            className="form-control"
                                            id="passwordInput"
                                            name="password"
                                            placeholder="Enter password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="mb-3">
                                        <label htmlFor="name" className="form-label">
                                            Name<span className="text-muted">(Optional)</span>
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            name="name"
                                            placeholder="Enter name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="mb-3">
                                        <label htmlFor="role" className="form-label">
                                            Role
                                        </label>
                                        <select
                                            className="form-control"
                                            id="role"
                                            name="role"
                                            value={role}
                                            onChange={(e) => setRole(e.target.value)}
                                            required
                                        >
                                            <option value={2}>Agent</option>
                                            <option value={1}>Admin</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-3">
                                <label htmlFor="billinginfo-address" className="form-label">
                                    Address
                                </label>
                                <textarea className="form-control" id="billinginfo-address" placeholder="Enter address" rows="3"></textarea>
                            </div>

                            <div className="d-flex align-items-start gap-3 mt-3">
                                {loading ? (
                                    <button type="button" className="btn btn-success btn-load" disabled>

                                        <span className="spinner-border flex-shrink-0" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </span>
                                        <span className="flex-grow-1 ms-2">Loading...</span>

                                    </button>
                                ) : (
                                    <button type="submit" className="btn btn-success btn-label right ms-auto nexttab">
                                        <i className="ri-truck-line label-icon align-middle fs-16 ms-2"></i>add user
                                    </button>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddUser;
